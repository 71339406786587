import { base64, base64url } from "@scure/base";
//import { base64url } from "@scure/base";
import { sha256 } from "@noble/hashes/sha256";

export interface RegisteredCredential {
  public_key: string;
  variant: string;
  name: string;
  raw_id: string;
}
interface WebauthnUser {
  username: string;
  // std base64 encoded RawID's.
  // These are from 'webauth*' variant credentials registered to the particular user.
  credentials: RegisteredCredential[];
}

interface RegisterResponse {
  attestation: AuthenticatorRegisterResponse;
  // json serialization of the attestation
  encodedAttestion: string;
  // binary encoded attestation
  binaryAttestion: Uint8Array;
}
interface SignResponse {
  assertion: AuthenticatorSignResponse;
  // json encoded assertion
  encodedAssertion: string;
  // binary encoded assertion
  binaryAssertion: Uint8Array;
}

// From the authenticator
interface AuthenticatorRegisterResponse {
  id: string;
  // base64url encoded rawId
  rawId: string;
  response: AuthenticatorAttestation;
  type: string;
}
interface AuthenticatorAttestation {
  // base64url encoded
  clientDataJSON: string;
  // base64url encoded
  attestationObject: string;
}

interface AuthenticatorSignResponse {
  id: string;
  // base64url encoded
  rawId: string;
  response: AuthenticatorAssertion;
  type: string;
}
interface AuthenticatorAssertion {
  // base64url encoded
  authenticatorData: string;
  // base64url encoded
  clientDataJSON: string;
  // base64url encoded
  signature: string;
  userHandle: string;
}

interface WebauthnClientArgs {
  // An id for the treasury instance, typically this should be queried
  // from treasury. This will be used to prevent same-id user's from overwriting each other across
  // different instances.
  treasuryId?: string;
  // Typically this is the domain name of the site.
  RpID?: string;
  user?: WebauthnUser;

  onRegisterStart?: (options: PublicKeyCredentialCreationOptions) => void;
  onRegisterEnd?: (response: RegisterResponse, error?: Error) => void;

  onSignStart?: (options: PublicKeyCredentialRequestOptions) => void;
  onSignEnd?: (response: SignResponse, error?: Error) => void;
}

class WebauthnClient {
  args: WebauthnClientArgs;
  constructor(args: WebauthnClientArgs) {
    this.args = args;
  }
  setUser(user: WebauthnUser) {
    this.args.user = user;
  }
  setTreasuryId(treasuryId: string) {
    this.args.treasuryId = treasuryId;
  }
  setRpId(rpId: string) {
    this.args.RpID = rpId;
  }
  getUser(): WebauthnUser | undefined {
    return this.args.user;
  }
  // Generates a new credential on a webauthn authenticator for the user.
  // Returns the credential information.
  async Register(): Promise<RegisterResponse> {
    if (this.args.user == undefined) {
      // misuse, must set user
      throw new Error("WebauthnClient must have a user set in order to register a credential");
    }

    const rpID: string = this.args.RpID || (window as any).FIDO_RP_ID;

    const user = this.args.user;
    const username = user.username;
    if (this.args.treasuryId == undefined || this.args.treasuryId == "") {
      // users may get overwritten on the authenticator if using multiple treasury instances!
      console.warn("no treasuryId set on webauthn client");
    }
    const userId = `${this.args.treasuryId}_${username}`;
    // https://webauthn.guide/
    const publicKeyOptions: PublicKeyCredentialCreationOptions = {
      // TODO: mix (chain ID, account email, sequence) or similar to
      // get a replay-safe challenge.
      challenge: Uint8Array.from("untrusted-challenge", (c) => c.charCodeAt(0)),
      rp: {
        name: "Cordial Treasury",
        id: rpID,
      },
      user: {
        id: Uint8Array.from(userId, (c) => c.charCodeAt(0)),
        name: username,
        displayName: `${username} on ${this.args.treasuryId}`, // can't leave out
      },
      // Ed25519 or ES256
      pubKeyCredParams: [
        { type: "public-key", alg: -8 },
        { type: "public-key", alg: -7 },
      ],
      // excludeCredentials: user.rawIds.map(credentialDescriptorFromRawId),
      // one minute
      timeout: 60000,
      authenticatorSelection: {
        requireResidentKey: true,
        userVerification: "required",
        authenticatorAttachment: "cross-platform",
      },
      attestation: "direct",
    };

    if (this.args.onRegisterStart) {
      this.args.onRegisterStart(publicKeyOptions);
    }

    // call webauthn
    const credential: PublicKeyCredential = (await navigator.credentials.create({
      publicKey: publicKeyOptions,
    })) as PublicKeyCredential;
    const credResponse = credential.response as AuthenticatorAttestationResponse;

    // encode the response
    const attestation = {
      id: credential.id,
      rawId: base64url.encode(new Uint8Array(credential.rawId)),
      response: {
        clientDataJSON: base64url.encode(new Uint8Array(credResponse.clientDataJSON)),
        attestationObject: base64url.encode(new Uint8Array(credResponse.attestationObject)),
      },
      type: credential.type,
    };

    const encodedAttestation = JSON.stringify(attestation);
    const response: RegisterResponse = {
      encodedAttestion: encodedAttestation,
      binaryAttestion: binaryFromText(encodedAttestation),
      attestation: attestation,
    };
    if (this.args.onRegisterEnd) {
      this.args.onRegisterEnd(response);
    }
    return response;
  }

  // Request a signature from one of the credentials on a user's registered webauthn credentials.
  async Sign(data: Uint8Array): Promise<SignResponse> {
    if (this.args.user == undefined) {
      // misuse, must set user
      throw new Error("WebauthnClient must have a user set in order to sign with a credential");
    }
    const rpID: string = this.args.RpID || (window as any).FIDO_RP_ID;
    // console.log("rpID------", rpID);
    const user = this.args.user;

    const options: PublicKeyCredentialRequestOptions = {
      rpId: rpID,
      challenge: sha256(data),
      userVerification: "required",
      allowCredentials: user.credentials.map(credentialDescriptorFromRawId),
      timeout: 60000,
    };

    if (this.args.onSignStart) {
      this.args.onSignStart(options);
    }

    // call webauthn
    const credential = (await navigator.credentials.get({
      publicKey: options,
    })) as PublicKeyCredential;

    const credResponse = credential.response as AuthenticatorAssertionResponse;

    const credentialResponse = {
      authenticatorData: base64url.encode(new Uint8Array(credResponse.authenticatorData)),
      clientDataJSON: base64url.encode(new Uint8Array(credResponse.clientDataJSON)),
      signature: base64url.encode(new Uint8Array(credResponse.signature)),
      userHandle: "",
    };
    const assertion = {
      id: credential.id,
      rawId: base64url.encode(new Uint8Array(credential.rawId)),
      response: credentialResponse,
      type: credential.type,
    };
    const encodedAssertion = JSON.stringify(assertion);

    const response: SignResponse = {
      encodedAssertion: encodedAssertion,
      binaryAssertion: binaryFromText(encodedAssertion),
      assertion: assertion,
    };

    if (this.args.onSignEnd) {
      this.args.onSignEnd(response);
    }

    return response;
  }
}

// rawId should be base64
const credentialDescriptorFromRawId = (
  cred: RegisteredCredential,
): PublicKeyCredentialDescriptor => {
  let rawId = cred.raw_id;
  if (typeof rawId != "string") {
    // try to base64 encode
    // console.log("rawId base64", rawId)
    rawId = base64.encode(Uint8Array.from(rawId));
  }
  // console.log("rawId", rawId)
  return {
    id: base64.decode(rawId),
    type: "public-key",
  };
};

const binaryFromText = (text: string): Uint8Array => {
  const enc = new TextEncoder();
  return enc.encode(text);
};

export { WebauthnClient };
export type { WebauthnClientArgs, WebauthnUser, SignResponse, RegisterResponse };
