const _allowed_alpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
const _allowed_numeric = "0123456789";
const _allowed_other = "-_";
const _allowed_all =
  _allowed_alpha.toLowerCase() + _allowed_alpha + _allowed_numeric + _allowed_other;
const _allowed_map: Record<string, undefined> = {};
for (let i = 0; i < _allowed_all.length; i++) {
  _allowed_map[_allowed_all[i]] = undefined;
}
const normalize = (nameId: string): string => {
  nameId = nameId.trim();
  nameId = nameId.replaceAll(",", "-");
  nameId = nameId.replaceAll("!", "-");
  nameId = nameId.replaceAll("?", "-");

  nameId = nameId.replaceAll(".", "_");
  nameId = nameId.replaceAll(":", "_");
  nameId = nameId.replaceAll(" ", "_");
  nameId = nameId.replaceAll("\n", "_");
  nameId = nameId.replaceAll("\r", "_");
  let filteredId = "";
  for (let i = 0; i < nameId.length; i++) {
    const c = nameId[i];
    if (c in _allowed_map) {
      filteredId = filteredId + c;
    }
  }

  return filteredId;
};

export { normalize };
