import React from "react";

const JsonRenderer: React.FC<{ data: any }> = ({ data }) => {
  const renderJson = (data: any, keyPrefix = ""): JSX.Element => {
    if (typeof data === "string") {
      try {
        const parsedData = JSON.parse(data);
        return renderJson(parsedData, keyPrefix);
      } catch (error) {
        return <span>{` ${data}`}</span>;
      }
    }

    if (data === null || data === undefined) {
      return <span>{`${keyPrefix}: null`}</span>;
    }

    if (typeof data === "string" || typeof data === "number" || typeof data === "boolean") {
      return <span>{data.toString()}</span>;
    }

    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={`${keyPrefix}[${index}]`}>{renderJson(item, `${keyPrefix}[${index}]`)}</li>
          ))}
        </ul>
      );
    }

    if (typeof data === "object") {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={`${keyPrefix}.${key}`}>
              <strong>{key}:</strong> {renderJson(value, key)}
            </li>
          ))}
        </ul>
      );
    }

    return (
      <span>
        <strong>{keyPrefix}:</strong> {data}
      </span>
    );
  };

  return <div>{renderJson(data)}</div>;
};

export default JsonRenderer;
