import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@carbon/react";

export default function CButton({ children, ...rest }: any) {
  return rest.to && !rest.disabled ? (
    <Button as={Link} to={rest.to} size="lg" {...rest}>
      {children}
    </Button>
  ) : (
    <Button size="lg" {...rest}>
      {children}
    </Button>
  );
}
