import { useQuery } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { RolePage } from "../../../lib/api/types";

async function fetchRole(pageSize: number): Promise<RolePage> {
  return httpClientInstance.listRole({ page_size: pageSize });
}

interface UseRoleOptions {
  pageSize?: number;
}

export function useRole({ pageSize = 100 }: UseRoleOptions = {}) {
  const queryKey = ["roles"];

  const { data, error, isLoading } = useQuery<RolePage, Error>({
    queryKey,
    queryFn: () => fetchRole(pageSize),
    refetchInterval: 20000,
  });

  return { data, isLoading, error };
}
