import { useNotificationStore } from "../../../stores/notifications";
import { httpClientInstance } from "../../../clientInstance";
import { useMutation } from "@tanstack/react-query";

async function deleteTransferRule(name: string): Promise<void> {
  await httpClientInstance.deleteTransferRule(name);
}

export function useDeleteRule() {
  const { addNotification } = useNotificationStore();

  const mutation = useMutation<void, Error, string>({
    mutationFn: deleteTransferRule,
    onSuccess: () => {
      addNotification({
        type: "success",
        title: `Delete Submitted successfully`,
      });
    },
    onError: (error: Error) => {
      addNotification({
        type: "error",
        title: error.message,
      });
      console.error("Error deleting Transfer Rule:", error);
    },
  });

  return mutation;
}
