import React from "react";
import { ContentSwitcher, Switch } from "@carbon/react";

interface PermissionTypeSelectorProps {
  name: string;
  value: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

const PermissionTypeSelector: React.FC<PermissionTypeSelectorProps> = ({
  name,
  value,
  setFieldValue,
}) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <div style={{ paddingBottom: "10px" }}>
        Would you like to Create an Allow / Deny / Require rule?
      </div>
      <ContentSwitcher
        onChange={(e) => {
          const selectedPermission = e.name;
          setFieldValue(name, selectedPermission);
        }}
        selectedIndex={value === "allow" ? 0 : value === "deny" ? 1 : 2}
        size="sm"
      >
        <Switch name="allow" text="Allow" />
        <Switch name="deny" text="Deny" />
        <Switch name="require" text="Require" />
      </ContentSwitcher>
    </div>
  );
};

export default PermissionTypeSelector;
