import React, { useEffect } from "react";
import { ContentSwitcher, Switch, FilterableMultiSelect, NumberInput } from "@carbon/react";
import { Field, FieldProps } from "formik";

interface SelectItem {
  label: string;
  value: string;
}

interface UserGroupSelectorProps {
  viewMode: string;
  groupOptions: SelectItem[];
  userOptions: SelectItem[];
  setViewMode: (mode: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  setSelectedGroups: (groups: SelectItem[]) => void;
  setFilteredUsers: (users: SelectItem[]) => void;
  selectedRow?: any;
}

const UserGroupSelector: React.FC<UserGroupSelectorProps> = ({
  viewMode,
  groupOptions,
  userOptions,
  setViewMode,
  setFieldValue,
  setSelectedGroups,
  setFilteredUsers,
  selectedRow,
}) => {
  useEffect(() => {
    if (selectedRow) {
      if (selectedRow.initiate) {
        if (selectedRow.initiate.some((item: string) => item.startsWith("roles/"))) {
          setViewMode("group");
          setFieldValue("viewMode", "group");

          const initialGroups = selectedRow.initiate.map((role: string) => ({
            label: role.split("/").pop(),
            value: role,
          }));

          setSelectedGroups(initialGroups);
          //@ts-expect-error  exist
          setFieldValue("group", initialGroups.map((item) => item.value).join(","));
        } else if (selectedRow.initiate.some((item: string) => item.startsWith("users/"))) {
          setViewMode("user");
          setFieldValue("viewMode", "user");

          const initialUsers = selectedRow.initiate.map((user: string) => ({
            label: user.split("/").pop(),
            value: user,
          }));

          setFilteredUsers(initialUsers);
          //@ts-expect-error  exist
          setFieldValue("user", initialUsers.map((item) => item.value).join(","));
        } else {
          setViewMode("none");
          setFieldValue("viewMode", "none");
        }

        // Set initial value for number of approvers
        if (selectedRow.approvals !== undefined) {
          setFieldValue("numberOfApprovers", selectedRow.approvals);
        }
      }
    }
  }, [selectedRow, setFieldValue, setSelectedGroups, setFilteredUsers, setViewMode]);

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>Select Users, Groups, or None</div>
        <ContentSwitcher
          onChange={(e) => {
            const selectedViewMode = e.name;
            setViewMode(selectedViewMode as string);
            setFieldValue("viewMode", selectedViewMode);
            setFieldValue("group", "");
            setFieldValue("user", "");
            //  setFieldValue("numberOfApprovers", 0); // Reset numberOfApprovers to 0
            setSelectedGroups([]);
            setFilteredUsers([]);
          }}
          selectedIndex={viewMode === "user" ? 0 : viewMode === "group" ? 1 : 2}
          size="sm"
        >
          <Switch name="user" text="User" />
          <Switch name="group" text="Group" />
          <Switch name="none" text="None" />
        </ContentSwitcher>
      </div>

      {viewMode === "group" && (
        <div style={{ marginBottom: "20px" }}>
          <FilterableMultiSelect
            key="group"
            id="group"
            titleText="Select Group"
            placeholder="Choose a group"
            //@ts-expect-error  exist
            items={groupOptions}
            initialSelectedItems={
              selectedRow?.initiate
                ?.filter((item: string) => item.startsWith("roles/"))
                .map((role: string) => ({
                  label: role.split("/").pop(),
                  value: role,
                })) || []
            }
            //@ts-expect-error  exist
            itemToString={(item) => (item ? item.label : "")}
            onChange={(e) => {
              //@ts-expect-error  exist
              const selectedGroupsValues = e.selectedItems.map((item: SelectItem) => item);
              setSelectedGroups(selectedGroupsValues);
              setFieldValue("group", selectedGroupsValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}

      {viewMode === "user" && (
        <div style={{ marginTop: "20px" }}>
          <FilterableMultiSelect
            key="user"
            id="user"
            titleText="Select User"
            placeholder="Choose a user"
            //@ts-expect-error  exist
            items={userOptions}
            initialSelectedItems={
              selectedRow?.initiate
                ?.filter((item: string) => item.startsWith("users/"))
                .map((user: string) => ({
                  label: user.split("/").pop(),
                  value: user,
                })) || []
            }
            //@ts-expect-error  exist
            itemToString={(item) => (item ? item.label : "")}
            onChange={(e) => {
              //@ts-expect-error  exist
              const selectedUserValues = e.selectedItems.map((item: SelectItem) => item);
              setFilteredUsers(selectedUserValues);
              setFieldValue("user", selectedUserValues.map((item) => item.value).join(","));
            }}
          />
        </div>
      )}

      {(viewMode === "user" || viewMode === "group") && (
        <div style={{ marginTop: "20px" }}>
          <div style={{ paddingBottom: "10px" }}>Number of Approvers</div>
          <Field name="numberOfApprovers">
            {({ field, form }: FieldProps) => (
              <NumberInput
                style={{ width: "500px" }}
                id="numberOfApprovers"
                name="numberOfApprovers"
                min={0}
                value={field.value || 0}
                onChange={(event, { value }) => {
                  const numericValue = Number(value);
                  if (!isNaN(numericValue) && numericValue >= 0) {
                    form.setFieldValue(field.name, numericValue);
                  } else {
                    form.setFieldValue(field.name, 0);
                  }
                }}
                invalid={!!form.errors.numberOfApprovers}
              />
            )}
          </Field>
        </div>
      )}
    </div>
  );
};

export default UserGroupSelector;
