import { useUser } from "@clerk/clerk-react";
import { Organization } from "../types/organisationTypes";
import { useOrganizations } from "./getOrganization";
import { useTreasuries } from "./getTreasuries";
import { Treasury } from "../types/treasuryTypes";

interface UseDataHook<T> {
  data: T | undefined;
  isLoading: boolean;
  error: any | null;
  isError: boolean;
}

export function useOrganizationAndTreasuries(): UseDataHook<{
  userOrganisations: Organization[];
  userTreasuries: Treasury[];
}> {
  const {
    data: organizationsData,
    isLoading: orgLoading,
    error: orgError,
    isError: orgIsError,
  } = useOrganizations();
  const {
    data: treasuriesData,
    isLoading: treasLoading,
    error: treasError,
    isError: treasIsError,
  } = useTreasuries();
  const { user, isLoaded } = useUser();

  const isLoading = orgLoading || treasLoading || !isLoaded;
  const isError = orgIsError || treasIsError;
  const error = orgError || treasError;

  const userOrganisations: Organization[] = [];
  const userTreasuries: Treasury[] = [];

  const compareUserIds = (id1: string, id2: string): boolean => {
    const userId1 = id1.split("/").pop() || "";
    const userId2 = id2.split("_").pop() || "";
    return userId1 === userId2;
  };

  organizationsData?.organizations.forEach((org) => {
    Object.entries(org.users).forEach(([userId]) => {
      if (compareUserIds(userId, user?.id || "")) {
        userOrganisations.push(org);
      }
    });
  });

  treasuriesData?.treasuries.forEach((treasury) => {
    if (userOrganisations.some((org) => org.name === treasury.organization)) {
      userTreasuries.push(treasury);
    }
  });

  return {
    data: {
      userOrganisations,
      userTreasuries,
    },
    isLoading,
    isError,
    error,
  };
}
