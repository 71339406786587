import React from "react";
import CModal from "../../../components/CModal";

import AssociateClerkUsertoCordialForm from "./form/AssociateClerkUsertoCordialForm";

const UserLinkAccountModal: React.FC = () => {
  return (
    <CModal title="Link User Account" returnUrl="/users">
      <AssociateClerkUsertoCordialForm />
    </CModal>
  );
};

export default UserLinkAccountModal;
