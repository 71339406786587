import React, { useState } from "react";
import { ComboBox, Tab, TabList, TabPanel, TabPanels } from "@carbon/react";
import { Field, Formik } from "formik";
import { useSearchParams } from "react-router-dom";

import CTabs from "../../../components/CTabs";
import {
  Form,
  ModalSubmitButton,
  MultiColumnRow,
  NumberInput,
  TextInput,
} from "../../../components/form";

import { useModalSetters } from "../../../components/CModal";

import { ParseName } from "../../../lib/api/name";
import { AssetFormData, useAddAssetMutation } from "../api/createAsset";
import { useChainPage } from "../../../hooks/useChain";
import { useGetAssetPage } from "../api/getAssetPage";

const validate = (setSearchParams: any, tab: number) => (values: any) => {
  const errors: any = {};
  const searchParams: any = {};
  if (!values.asset) {
    errors.asset = "Required";
  } else {
    searchParams.asset = values.asset;
  }

  if (!values.contract) {
    errors.contract = "Required";
  } else {
    searchParams.contract = values.contract;
  }

  if (!values.symbol) {
    errors.symbol = "Required";
  } else {
    searchParams.symbol = values.symbol;
  }

  if (values.decimals < 0) {
    errors.decimals = "Required (non-negative integer)";
  } else {
    values.decimals = Math.floor(values.decimals);
  }

  if (values.decimals > 0) {
    searchParams.decimals = values.decimals;
  }
  if (values.alias) {
    searchParams.alias = values.alias;
  }
  if (values.name) {
    searchParams.name = values.name;
  }

  if (tab) {
    searchParams.tab = tab;
  }

  if (Object.keys(searchParams).length > 0) {
    setSearchParams(searchParams);
  }
  return errors;
};

export default function AssetCreateForm() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [tab, setTab] = useState(Number(searchParams.get("tab")) || 0);

  const modalSetters = useModalSetters();

  const { data: chainPage } = useChainPage();
  const { data: assetPage } = useGetAssetPage({ pageSize: 1000 });
  const addAssetMutation = useAddAssetMutation();

  console.log("chainPage", chainPage);
  console.log("asset", assetPage);

  const initialValues: AssetFormData = {
    asset: searchParams.get("asset") || "",
    contract: searchParams.get("contract") || "",
    name: searchParams.get("name") || "",
    decimals: Number(searchParams.get("decimals")) || 0,
    symbol: searchParams.get("symbol") || "",
    labels: {},
    notes: {
      description: searchParams.get("description") || "",
    },
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validate(setSearchParams, tab)}
      validateOnMount={true}
      onSubmit={async (values) => {
        const { setLoading, setNotif } = modalSetters;

        setLoading(true);
        const notif = { success: `Asset created ${values.name} successfully.` };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isErrored = false;
        let errMsg = "";

        addAssetMutation.mutate(values, {
          onError: (err) => {
            errMsg = err.message;
            isErrored = true;
            setLoading(false);
            setNotif({ success: "", error: `Error ${errMsg}` });
          },
          onSuccess: () => {
            setLoading(false);
            setNotif({ success: notif.success, error: "" });
          },
        });
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ minHeight: 400 }}>
          <CTabs tab={tab} setTab={setTab}>
            <TabList aria-label="List of tabs">
              <Tab>Add token</Tab>
              <Tab disabled>Add custom asset</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {tab === 0 ? (
                  <>
                    <MultiColumnRow>
                      <Field
                        id="asset"
                        name="asset"
                        component={ComboBox}
                        titleText="Chain"
                        items={
                          assetPage?.assets?.map((asset) => ({
                            id: asset.name,
                            label: ParseName([asset.name!], "Asset").parentId,
                          })) || []
                        }
                        onChange={(event: any) => {
                          console.log("event", values);
                          const newChainValue = event.selectedItem.id;
                          setFieldValue("asset", newChainValue);
                        }}
                      />
                    </MultiColumnRow>
                    <MultiColumnRow>
                      <Field
                        labelText="Contract"
                        name="contract"
                        as={TextInput}
                        value={values.contract || ""}
                      />
                    </MultiColumnRow>
                    <MultiColumnRow>
                      <Field
                        labelText="Symbol"
                        name="symbol"
                        as={TextInput}
                        value={values.symbol || ""}
                      />
                      <Field
                        labelText="Decimals"
                        name="decimals"
                        as={NumberInput}
                        value={values.decimals || 0}
                      />
                    </MultiColumnRow>
                    <MultiColumnRow>
                      <TextInput
                        id="description"
                        name="notes.description"
                        labelText="Description"
                        value={values.notes?.description || ""}
                        onChange={(e: { target: { value: any } }) =>
                          setFieldValue("notes.description", e.target.value)
                        }
                      />
                    </MultiColumnRow>
                  </>
                ) : null}
              </TabPanel>
              <TabPanel>
                {tab === 1 ? (
                  <>
                    <MultiColumnRow>
                      <TextInput name="custom_chain" type="text" labelText="Custom chain" />
                    </MultiColumnRow>
                    <br />
                    or
                    <br />
                    <br />
                    <br />
                    <MultiColumnRow>
                      <TextInput name="custom_token" type="text" labelText="Custom token" />
                    </MultiColumnRow>
                  </>
                ) : null}
              </TabPanel>
            </TabPanels>
          </CTabs>
          <ModalSubmitButton />
        </Form>
      )}
    </Formik>
  );
}
