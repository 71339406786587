import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Asset } from "../../../lib/api/types";

export interface UpdateAssetFormData {
  name: string;
  addressData: Asset;
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateAssetFormData): Promise<void> {
  console.log("form data", formData);
  await httpClientInstance.updateAsset(formData.name, {
    ...formData.addressData,
  });
}

export function useModifyAssetMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateAssetFormData>,
): UseMutationResult<void, SubmitError, UpdateAssetFormData> {
  return useMutation<void, SubmitError, UpdateAssetFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateAssetFormData, context: unknown) => {
      console.log("Update asset Form modified successfully");

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateAssetFormData, context: unknown) => {
      console.error("Error submitting asset modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
