import { useMemo } from "react";
import { useAddressesPage } from "../api/getAddresses";
import { useAccountPage } from "../api/getAccounts";
import { AddressListItem } from "../components/AddressTable";

export const useCombinedAccountAndAddress = () => {
  const {
    data: addresses,
    isLoading: isLoadingAddresses,
    error: errorAddresses,
  } = useAddressesPage();

  const { data: accounts, isLoading: isLoadingAccounts, error: errorAccounts } = useAccountPage();

  const combinedList = useMemo(() => {
    if (!isLoadingAccounts && !isLoadingAddresses && accounts && addresses) {
      const tempList: AddressListItem[] = [];

      accounts.accounts?.forEach((account) => {
        account.addresses?.forEach((accountAddress) => {
          const matchingAddress = addresses.addresses?.find((addr) => addr.name === accountAddress);
          if (matchingAddress && !tempList.some((addr) => addr.name === matchingAddress.name)) {
            tempList.push({
              ...matchingAddress,
              account: account.name,
              notes:
                typeof matchingAddress.notes === "object"
                  ? JSON.stringify(matchingAddress.notes)
                  : matchingAddress.notes,
            });
          }
        });
      });

      addresses.addresses?.forEach((addr) => {
        if (!tempList.some((existingAddr) => existingAddr.name === addr.name)) {
          tempList.push({
            ...addr,
            account: "",
            notes: typeof addr.notes === "object" ? JSON.stringify(addr.notes) : addr.notes,
          });
        }
      });

      return tempList;
    }
    return [];
  }, [isLoadingAccounts, isLoadingAddresses, accounts, addresses]);

  return {
    addressList: combinedList,
    isLoading: isLoadingAccounts || isLoadingAddresses,
    error: errorAccounts || errorAddresses,
  };
};
