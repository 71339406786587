import React from "react";
import { Outlet } from "react-router-dom";

import ApprovalsIndexTable from "./components/ApprovalsTable";
import CPage from "../../components/CPage";

export default function ApprovalsPage() {
  return (
    <CPage title="Approvals">
      <ApprovalsIndexTable />
      <Outlet />
    </CPage>
  );
}
