import { components } from "./types/schema";

export type Account = components["schemas"]["Account"];
export type AccountData = components["schemas"]["AccountData"];
export type AccountPage = components["schemas"]["AccountPage"];

export type User = components["schemas"]["User"];
export type UserData = components["schemas"]["UserData"];
export type UserPage = components["schemas"]["UserPage"];

export type Role = components["schemas"]["Role"];
export type RoleData = components["schemas"]["RoleData"];
export type RolePage = components["schemas"]["RolePage"];

export type Credential = components["schemas"]["Credential"];
export type CredentialData = components["schemas"]["CredentialData"];
export type CredentialPage = components["schemas"]["CredentialPage"];

export type Operation = components["schemas"]["Operation"];
export type OperationData = components["schemas"]["OperationData"];
export type OperationPage = components["schemas"]["OperationPage"];

export type Transaction = components["schemas"]["Transaction"];
export type TransactionData = components["schemas"]["TransactionData"];
export type TransactionPage = components["schemas"]["TransactionPage"];

export type Transfer = components["schemas"]["Transfer"];
export type TransferPage = components["schemas"]["TransferPage"];

export type Address = components["schemas"]["Address"];
export type AddressPage = components["schemas"]["AddressPage"];

export type Chain = components["schemas"]["Chain"];
export type ChainPage = components["schemas"]["ChainPage"];
export type Asset = components["schemas"]["Asset"];
export type AssetPage = components["schemas"]["AssetPage"];

export type Treasury = components["schemas"]["Treasury"];
export type TreasuryPage = components["schemas"]["TreasuryPage"];

export type AccessRule = components["schemas"]["AccessRule"];
export type AccessRulePage = components["schemas"]["Metadata"]; // TODO add to stoplight

export type Feature = components["schemas"]["Feature"];
export type FeaturePage = components["schemas"]["FeaturePage"];

export type Signer = components["schemas"]["Signer"];
export type SignerPage = components["schemas"]["SignerPage"];

export type Signature = components["schemas"]["Signature"];
export type SignatureResponse = components["schemas"]["SignatureResponse"];
export type SignatureResponsePage = components["schemas"]["SignatureResponsePage"];
export type SignaturePage = components["schemas"]["SignaturePage"];

export type SoftwareUpdate = components["schemas"]["SoftwareUpdate"];
export type SoftwareUpdatePage = components["schemas"]["SoftwareUpdatePage"];

// 'Symbol' conflicts with something in global namespace
export type SymbolResource = components["schemas"]["Symbol"];
export type SymbolPage = components["schemas"]["SymbolPage"];

export type TransferRule = components["schemas"]["TransferRule"];
export type TransferRulePage = components["schemas"]["TransferRulePage"];

export type Key = components["schemas"]["Key"];
export type KeyResponse = components["schemas"]["KeyResponse"];
export type KeyResponsePage = components["schemas"]["KeyResponsePage"];
export type KeyPage = components["schemas"]["KeyPage"];

// states/enums
export type OperationState = components["schemas"]["OperationState"];
export type CredentialVariant = components["schemas"]["CredentialVariant"];
export type Action = components["schemas"]["Action"];
export type Method = "GET" | "POST" | "PUT" | "PATCH" | "DELETE";

// Add resources to this list
// export type ResourceType = "User" | "Account" | "Credential" | "Role" | "Operation" | "Transfer";
export type ResourceVariant = components["schemas"]["ResourceType"];

export type AddressVariant = components["schemas"]["AddressVariant"];
export type BasicState = components["schemas"]["BasicState"];

// apiClient.create("User", ...)

export interface ResourceTypes {
  AccessRule: {
    Resource: AccessRule;
    Page: AccessRulePage;
  };
  Account: {
    Resource: Account;
    Page: AccountPage;
  };
  Address: {
    Resource: Address;
    Page: AddressPage;
  };
  Asset: {
    Resource: Asset;
    Page: AssetPage;
  };
  Chain: {
    Resource: Chain;
    Page: ChainPage;
  };
  Credential: {
    Resource: Credential;
    Page: CredentialPage;
  };
  Feature: {
    Resource: Feature;
    Page: FeaturePage;
  };
  Key: {
    Resource: Key;
    Page: KeyPage;
  };
  KeyResponse: {
    Resource: KeyResponse;
    Page: KeyResponsePage;
  };

  Operation: {
    Resource: Operation;
    Page: OperationPage;
  };
  Role: {
    Resource: Role;
    Page: RolePage;
  };
  Signer: {
    Resource: Signer;
    Page: SignerPage;
  };
  Signature: {
    Resource: Signature;
    Page: SignaturePage;
  };
  SignatureResponse: {
    Resource: SignatureResponse;
    Page: SignatureResponsePage;
  };
  Symbol: {
    Resource: SymbolResource;
    Page: SymbolPage;
  };
  Transaction: {
    Resource: Transaction;
    Page: TransactionPage;
  };
  Transfer: {
    Resource: Transfer;
    Page: TransferPage;
  };
  Treasury: {
    Resource: Treasury;
    Page: TreasuryPage;
  };
  TransferRule: {
    Resource: TransferRule;
    Page: TransferRulePage;
  };
  User: {
    Resource: User;
    Page: UserPage;
  };
  SoftwareUpdate: {
    Resource: SoftwareUpdate;
    Page: SoftwareUpdatePage;
  };
}

// Ensure all types have resource, data, page
export type _ResourceType = ResourceTypes[ResourceVariant]["Resource"];
// export type _DataType = ResourceTypes[ResourceVariant]["Data"];
export type _PageType = ResourceTypes[ResourceVariant]["Page"];

export interface TypeMeta {
  resourceField: string;
  parentField?: string;
}

export const ResourceTable: Record<ResourceVariant, TypeMeta> = {
  Account: {
    parentField: undefined,
    resourceField: "accounts",
  },
  Address: {
    parentField: "chains",
    resourceField: "addresses",
  },
  AccessRule: {
    parentField: undefined,
    resourceField: "access-rules",
  },
  Asset: {
    parentField: "chains",
    resourceField: "assets",
  },
  Chain: {
    parentField: undefined,
    resourceField: "chains",
  },
  Credential: {
    parentField: "users",
    resourceField: "credentials",
  },
  Feature: {
    parentField: undefined,
    resourceField: "features",
  },
  Key: {
    parentField: undefined,
    resourceField: "keys",
  },
  KeyResponse: {
    parentField: undefined,
    resourceField: "key-response",
  },
  Operation: {
    parentField: undefined,
    resourceField: "operations",
  },

  Role: {
    parentField: undefined,
    resourceField: "roles",
  },
  Signer: {
    parentField: undefined,
    resourceField: "signers",
  },
  Signature: {
    parentField: undefined,
    resourceField: "signatures",
  },
  SignatureResponse: {
    parentField: undefined,
    resourceField: "signature-response",
  },
  Symbol: {
    parentField: "chains",
    resourceField: "symbols",
  },
  Transfer: {
    parentField: undefined,
    resourceField: "transfers",
  },
  TransferRule: {
    parentField: undefined,
    resourceField: "transfer-rules",
  },
  Transaction: {
    parentField: "transfers",
    resourceField: "transactions",
  },
  Treasury: {
    parentField: undefined,
    resourceField: "treasuries",
  },
  User: {
    parentField: undefined,
    resourceField: "users",
  },
  SoftwareUpdate: {
    parentField: undefined,
    resourceField: "software-update",
  },
};

export const GetTypeMeta = (t: ResourceVariant): TypeMeta => {
  return ResourceTable[t];
};

export interface ErrorResponse {
  code: number;
  message: string;
  status: string;
}
