import React, { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";
import {
  useApproveOperation,
  UseApproveOperationFormData,
  useRejectOperation,
} from "../api/createApproveRejectApprovals";
import { formatElapsedTime } from "../../../utils/formater";
import CButton from "../../../components/CButton";
import { Checkmark, SubtractAlt } from "@carbon/react/icons";

import { useApprovals } from "../api/getApprovals";
import { ParseName } from "../../../lib/api/name";
import JsonRenderer from "./JsonRenderer";

type Variant = "internal" | "external";
type State = "active" | "deleted";

export interface AddressListItem {
  account?: string;
  name?: string;
  variant?: Variant;
  state?: State;
  create_time?: string;
}

const ApprovalsIndexTable: React.FC = () => {
  const { data: operationsPage } = useApprovals();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { mutate: approveMutate } = useApproveOperation();
  const { mutate: rejectMutate } = useRejectOperation();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const navigate = useNavigate();

  const operations = operationsPage?.operations ?? [];

  console.log("operations", operations);

  const headers = [
    { key: "create_time", header: "Time Since" },
    // { key: "initiator", header: "Initiator" },

    { key: "action", header: "Action Details" },

    { key: "name", header: "Operation" },
    { key: "approvers", header: "Approvers" },
    // { key: "body", header: "Body" },

    { key: "button", header: "" },
  ];

  const filteredRows = operations
    .filter((row) => row.state === "authorizing")
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatElapsedTime(item.create_time ?? "") || item.create_time,
      approvers: item.approve || "",
      body: item.request?.body || "",
      name: ParseName(item.name ?? "", "Operation").resourceId || "NA",
      action: (
        <span>
          <strong>{ParseName(item.initiator, "User").resourceId}</strong> performed the action{" "}
          <strong>{item.request?.action}</strong> on resource
          {item.request?.resource ? (
            <>
              {" "}
              of type <strong>{item.request.resource}</strong>
            </>
          ) : (
            ""
          )}{" "}
          with ID <strong>{item.request?.id}</strong>.
        </span>
      ),
      button: item,
    }))
    .filter((row) => {
      const searchableText = `${row.state} ${row.create_time} ${row.request?.action} ${row.request?.resource} ${row.request}`;
      return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
    });

  const handleApprove = (values: any, event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: values,
    };
    approveMutate(formData);
  };

  const handleReject = (values: any, event: React.MouseEvent) => {
    event.stopPropagation();
    const formData: UseApproveOperationFormData = {
      operation: values,
    };
    rejectMutate(formData);
  };

  const handleRowClick = (row: any) => {
    const name = row.cells.find((cell: any) => cell.info.header === "name")?.value;
    if (name) {
      navigate(`/approvals/${name}`);
    }
  };

  const renderCellContent = (cell: any): any => {
    switch (cell.info.header) {
      case "body":
        return <JsonRenderer data={cell.value.toString()} />;
      case "button":
        return (
          <div>
            <CButton
              size="sm"
              className="min-width-button"
              renderIcon={Checkmark}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleApprove(cell.value, event)
              }
            >
              Approve
            </CButton>
            <CButton
              size="sm"
              className="cds--btn--danger min-width-button"
              renderIcon={SubtractAlt}
              onClick={(event: React.MouseEvent<Element, MouseEvent>) =>
                handleReject(cell.value, event)
              }
            >
              Reject
            </CButton>
          </div>
        );
      default:
        return cell.value;
    }
  };

  const handlePageChange = (event: { page: number; pageSize: number }) => {
    setPage(event.page);
    setPageSize(event.pageSize);
  };

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  if (!operationsPage) {
    return <div>Loading data, please wait...</div>;
  }

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            //@ts-expect-error error
            onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchQuery(event.target.value)}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>
      <DataTable
        size="md"
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  //@ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  //@ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 50, 100]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={page}
      />
    </>
  );
};

export default ApprovalsIndexTable;
