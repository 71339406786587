import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { InviteCode } from "../../../lib/api/invite";
import { httpClientInstance } from "../../../clientInstance";

interface FormData {
  userName: string;
  code: InviteCode;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: FormData): Promise<void> {
  await httpClientInstance.create("Credential", [formData.userName], {
    variant: "invite",
    public_key: formData.code.publicKey,
  });
}

function useGenerateInviteCode(): UseMutationResult<void, SubmitError, FormData> {
  return useMutation<void, SubmitError, FormData>({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting form:", error.message);
    },
  });
}

export default useGenerateInviteCode;
