const checkUrl = async (url: string): Promise<boolean> => {
  console.log(`Checking ${url}`);
  try {
    const urlToCheck = `${url}/v1/treasuries`;
    const response = await fetch(urlToCheck, { method: "HEAD" });
    if (response.ok) {
      console.log(`${url} is working. Status: ${response.status}`);
      return true;
    } else {
      console.error(`${url} may not be working as expected. Status: ${response.status}`);
      return false;
    }
  } catch (error) {
    console.error(`Error checking ${url}:`, error);
    return false;
  }
};

export { checkUrl };
