import React from "react";
import CModal from "../../../components/CModal";
import AccountCreateForm from "./AccountCreateForm";

const CreateModal: React.FC = () => {
  return (
    <CModal title="Create account" primaryButtonText="Create" returnUrl="/accounts">
      <AccountCreateForm />
    </CModal>
  );
};

export default CreateModal;
