import React from "react";

import { TransferRule } from "@/lib/api/types";
import PolicyEditForm from "./PolicyEditForm";
import CModal from "../../../components/CModal";

interface PolicyEditModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  selectedRow: TransferRule | null;
}

const PolicyEditModal: React.FC<PolicyEditModalProps> = ({
  // isOpen,
  onClose,
  onSubmit,
  selectedRow,
}) => {
  return (
    <CModal
      title="Edit Transfer Rule"
      onClose={onClose}
      primaryButtonText="Save"
      returnUrl="/policy"
    >
      <PolicyEditForm selectedRow={selectedRow} onSubmit={onSubmit} />
    </CModal>
  );
};

export default PolicyEditModal;
