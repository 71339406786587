import React from "react";
import { useFormikContext } from "formik";
import { Tabs } from "@carbon/react";

export default function CTabs({
  tab,
  setTab,
  children,
}: {
  tab: number;
  setTab: React.Dispatch<React.SetStateAction<number>>;
  children: React.ReactNode;
}) {
  const { resetForm } = useFormikContext();
  return (
    <Tabs
      selectedIndex={tab}
      onChange={({ selectedIndex }) => {
        resetForm({ values: {} });
        setTab(selectedIndex || 0);
      }}
    >
      {children}
    </Tabs>
  );
}
