import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { AddressPage } from "../../../lib/api/types";

function fetchAddressPage(pageSize: number, filter: string = ""): Promise<AddressPage> {
  return httpClientInstance.listAddresses({ page_size: pageSize, filter: filter });
}

interface UseAddressesPageOptions {
  pageSize?: number;
  filter?: string;
}

export function useAddressesPage({ pageSize = 100, filter }: UseAddressesPageOptions = {}) {
  const queryKey = useMemo(() => ["AddressPage", pageSize], [pageSize]);

  console.log("filter", filter);

  const { data, error, isLoading, isError } = useQuery<AddressPage, Error>({
    queryKey,
    queryFn: () => fetchAddressPage(pageSize, filter),
    refetchInterval: 10000,
  });

  return {
    data: data,
    isLoading,
    error,
    isError,
  };
}
