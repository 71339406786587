import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { Operation } from "@/lib/api/types";

function fetchOperation(operationId: string, pageSize: number): Promise<Operation> {
  return httpClientInstance.getOperation(operationId, {
    page_size: pageSize,
  });
}

interface UseOperationOptions {
  operationId: string;
  pageSize?: number;
}

export function useOperation({ operationId, pageSize = 100 }: UseOperationOptions) {
  const queryKey = useMemo(() => ["approvals", operationId, pageSize], [operationId, pageSize]);

  const { data, error, isLoading, isError } = useQuery<Operation, Error>({
    queryKey,
    queryFn: () => fetchOperation(operationId, pageSize),
    refetchInterval: 10000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
