import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { Asset } from "@/lib/api/types";

function fetchAsset(addressId: string, pageSize: number): Promise<Asset> {
  console.log("fetchAsset", addressId, pageSize);
  return httpClientInstance.getAsset(addressId, {
    page_size: pageSize,
  });
}

interface UseAssetOptions {
  assetId: string;
  pageSize?: number;
}

export function useAsset({ assetId, pageSize = 100 }: UseAssetOptions) {
  const queryKey = useMemo(() => ["Asset", assetId, pageSize], [assetId, pageSize]);

  const { data, error, isLoading, isError } = useQuery<Asset, Error>({
    queryKey,
    queryFn: () => fetchAsset(assetId, pageSize),
    refetchInterval: 10000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
