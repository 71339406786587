import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Account } from "@/lib/api/types";

export interface UpdateAccountFormData {
  name: string;
  accountData: Account;
  availableAddresses: string[];
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateAccountFormData): Promise<void> {
  await httpClientInstance.updateAccount(formData.name, {
    ...formData.accountData,
    addresses: [...formData.availableAddresses],
  });
}

export function useModifyAccountMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateAccountFormData>,
): UseMutationResult<void, SubmitError, UpdateAccountFormData> {
  return useMutation<void, SubmitError, UpdateAccountFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateAccountFormData, context: unknown) => {
      console.log("Update Account Form modified successfully");

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateAccountFormData, context: unknown) => {
      console.error("Error submitting modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
