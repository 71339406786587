import { useQuery } from "@tanstack/react-query";
import { httpClientInstance } from "../clientInstance";
import { ChainPage } from "../lib/api/types";

async function fetchChainPage(pageSize: number): Promise<ChainPage> {
  return httpClientInstance.listChains({ page_size: pageSize });
}

interface UseChainPageOptions {
  pageSize?: number;
}

export function useChainPage({ pageSize = 100 }: UseChainPageOptions = {}) {
  const queryKey = ["chainPage", pageSize];

  const { data, error, isLoading } = useQuery<ChainPage, Error>({
    queryKey,
    queryFn: () => fetchChainPage(pageSize),
    //@ts-expect-error error
    onError: (error) => console.error("Error fetching chains:", error),
  });

  return { data, isLoading, error };
}
