import React from "react";
import { ComboBox, Tab, TabList, TabPanel, TabPanels } from "@carbon/react";
import { Field, Formik } from "formik";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";

import CTabs from "../../../components/CTabs";
import {
  Form,
  ModalSubmitButton,
  MultiColumnRow,
  TextArea,
  TextInput,
} from "../../../components/form";

import { useModalSetters } from "../../../components/CModal";

import { ParseName } from "../../../lib/api/name";

import { AddressFormData, useGenerateAddressMutation } from "../api/createAddress";
import { useChainPage } from "../../../hooks/useChain";

export default function AccountAddAddressForm() {
  const [searchParams] = useSearchParams();
  const [tab, setTab] = useState(Number(searchParams.get("tab")) || 0);
  const modalSetters = useModalSetters();

  const { data: chainPage } = useChainPage();

  const { mutate } = useGenerateAddressMutation();

  const initialValues: AddressFormData = {
    chain: searchParams.get("chain") || "",
    address: searchParams.get("address") || "",
    description: "",
    variant: tab === 0 ? "internal" : "external",
  };

  return (
    <Formik
      initialValues={initialValues}
      // validate={validate(setSearchParams, tab)}
      validateOnMount={true}
      onSubmit={async (values) => {
        const { setLoading, setNotif } = modalSetters;

        setLoading(true);
        const notif = { success: `Address ${values.address}  successfully created!` };
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let isErrored = false;
        let errMsg = "";

        if (tab === 0 || tab === 1) {
          values.variant = tab === 0 ? "internal" : "external";
          mutate(values, {
            onError: (err) => {
              errMsg = err.message;
              isErrored = true;
              setLoading(false);
              setNotif({ success: "", error: `Error ${errMsg}` });
            },
            onSuccess: () => {
              setLoading(false);
              setNotif({ success: notif.success, error: "" });
            },
          });
        }

        if (tab === 2) {
          console.log("Link address to account");
        }
      }}
    >
      {({ values, setFieldValue }) => (
        <Form style={{ minHeight: 392 }}>
          <CTabs tab={tab} setTab={setTab}>
            <TabList aria-label="List of tabs">
              <Tab>Generate new address </Tab>
              <Tab>Add external address</Tab>
              <Tab disabled>Import private key</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                {tab === 0 ? (
                  <>
                    <MultiColumnRow>
                      <Field
                        className="cds--form-item"
                        id="chain"
                        name="chain"
                        component={ComboBox}
                        titleText="Chain"
                        items={
                          // @ts-expect-error eslint-disable-next-line
                          chainPage?.chains?.map((chain) => ({
                            id: chain.name,
                            label: ParseName([chain.name!], "Chain").resourceId,
                          })) || []
                        }
                        onChange={(event: any) => {
                          const newChainValue = event.selectedItem.id;
                          setFieldValue("chain", newChainValue);
                        }}
                      />
                    </MultiColumnRow>

                    <Field
                      className="cds--form-item"
                      labelText="Note (optional)"
                      name="description"
                      as={TextInput}
                    />
                  </>
                ) : null}
              </TabPanel>
              <TabPanel>
                {tab === 1 ? (
                  <>
                    <MultiColumnRow>
                      <Field
                        className="cds--form-item"
                        id="chain"
                        name="chain"
                        component={ComboBox}
                        titleText="Chain"
                        items={
                          // @ts-expect-error eslint-disable-next-line
                          chainPage?.chains?.map((chain) => ({
                            id: chain.name,
                            label: ParseName([chain.name!], "Chain").resourceId,
                          })) || []
                        }
                        onChange={(event: any) => {
                          console.log("event", values);
                          const newChainValue = event.selectedItem.id;
                          setFieldValue("chain", newChainValue);
                        }}
                      />
                      {/* <AccountDropdown external /> */}
                    </MultiColumnRow>

                    <MultiColumnRow>
                      <Field
                        className="cds--form-item"
                        labelText="Address"
                        name="address"
                        as={TextInput}
                      />

                      {/* <TextInput name="memo" type="text" labelText="Memo (optional)" /> */}
                    </MultiColumnRow>

                    <Field
                      className="cds--form-item"
                      labelText="Note (optional)"
                      name="label"
                      as={TextInput}
                    />
                  </>
                ) : null}
              </TabPanel>

              <TabPanel>
                {tab === 2 ? (
                  <>
                    <MultiColumnRow>
                      tbd
                      {/* <ChainDropdown data-modal-primary-focus /> */}
                    </MultiColumnRow>
                    <TextArea name="key" labelText="Private key or mnemonic" />
                    <br />
                    <TextInput name="label" type="text" labelText="Label (optional)" />
                  </>
                ) : null}
              </TabPanel>
            </TabPanels>
          </CTabs>

          <ModalSubmitButton />
        </Form>
      )}
    </Formik>
  );
}
