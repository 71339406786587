import { httpClientInstance } from "../../../clientInstance";
import { ParseName } from "../../../lib/api/name";
import { InviteSigner } from "../../../lib/api/signers";
import { User } from "../../../lib/api/types";
import { normalize } from "../../../lib/api/util";

export async function registerInviteCode(currentUser: User, inviteCode: string): Promise<string> {
  try {
    const usrnm: string = ParseName([currentUser.name!], "User").resourceId;

    const page = await httpClientInstance.listCredential();

    // hacky way to allow "well-known" invite codes
    // namely... user "bob" will have code 626f62,
    // "alice" will have code 616c696365, etc.
    const hexRegex = /^[a-f0-9]+$/i;
    if (!hexRegex.test(inviteCode)) {
      inviteCode = inviteCode
        .split("")
        .map((char) => char.charCodeAt(0).toString(16).padStart(2, "0"))
        .join("");
    }

    let inviteName: string = "";

    for (const cred of page.credentials!) {
      if (cred.variant === "invite" && cred) {
        const name = ParseName([cred.name!], "Credential");
        console.log(name);
        if (name.parentId === normalize(usrnm)) {
          inviteName = cred.name!;
          console.log("found invite: ", inviteName);
        }
      }
    }
    if (inviteName === "") {
      throw new Error("No invite found for your user ");
      console.log("no invites found on user: ", usrnm);
    }

    const registration = await httpClientInstance.webauthnClient.Register();

    const inviteSigner = new InviteSigner(inviteCode);

    await httpClientInstance.createCredential(
      [currentUser.name!],
      {
        variant: "web-authn",
        attestation: registration.encodedAttestion,
        // required but unused for webauthn creds
        public_key: "1234",
      },
      {
        // We need to sign this with our invite code.
        signer: inviteSigner,
      },
    );

    try {
      const webauthnUser = await httpClientInstance.getUserWebauthnCreds(usrnm);
      console.log("webauthn user: ", webauthnUser);
      httpClientInstance.webauthnClient.setUser(webauthnUser);
    } catch (innerError) {
      console.error("Error in retrieving or setting user WebAuthn credentials: ", innerError);
    }
  } catch (error) {
    console.error("Error in postCredential function: ", error);
    throw error; // Re-throwing the error for upstream handling
  }

  return "ok";
}
