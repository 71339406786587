import React from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { TextInput } from "@carbon/react";

import { useNotificationStore } from "../../../stores/notifications";

import { MultiColumnRow } from "../../../components/form";
import { ParseName } from "../../../lib/api/name";
import { useAddress } from "../api/getAddress";

import { UpdateAddressFormData, useModifyAddressMutation } from "../api/updateAddress";

interface AddressEditFormProps {
  addressName: string | undefined;
  onClose: () => void;
}

const AddressEditForm: React.FC<AddressEditFormProps> = ({ addressName, onClose }) => {
  const { mutate } = useModifyAddressMutation();
  const { addNotification } = useNotificationStore();

  const {
    data: address,
    isLoading: isLoadingAddresses,
    error: errorAddresses,
  } = useAddress({
    addressId: addressName || "", //`chains/${chainId}/addresses/${addressId}`,
  });

  if (isLoadingAddresses) {
    return <div>Loading...</div>;
  }

  if (errorAddresses) {
    return <div>Error loading...</div>;
  }

  console.log("address", address);

  const initialValues: UpdateAddressFormData = {
    name: address?.name || "",
    addressData: address || {},
  };

  const onSubmit = async (
    values: UpdateAddressFormData,
    { setSubmitting }: FormikHelpers<UpdateAddressFormData>,
  ) => {
    try {
      await mutate(values, {
        onError: (err: any) => {
          addNotification({ type: "error", title: "Error", message: `Error: ${err.message}` });
        },
        onSuccess: () => {
          addNotification({
            type: "success",
            title: "Success",
            message: "Account updated successfully",
          });
          onClose();
        },
      });
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, setFieldValue }) => (
        <Form id="address-edit-form">
          <MultiColumnRow>
            <h5 style={{ paddingBottom: "15px" }}>
              {ParseName([values.name] ?? "", "Address").resourceId || "NA"}
            </h5>
          </MultiColumnRow>

          <MultiColumnRow>
            <TextInput
              id="description"
              name="addressData.notes.description"
              labelText="Description"
              value={values.addressData.notes?.description || ""}
              onChange={(e) => setFieldValue("addressData.notes.description", e.target.value)}
            />
          </MultiColumnRow>
        </Form>
      )}
    </Formik>
  );
};

export default AddressEditForm;
