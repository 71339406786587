import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { AccountPage } from "../../../lib/api/types";

async function fetchAccountPage(
  pageSize: number,
  pageToken?: string,
  filter?: string,
): Promise<AccountPage> {
  return httpClientInstance.listAccounts({ page_size: pageSize, page_token: pageToken, filter });
}

interface UseAccountPageOptions {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export function useAccountPage({ pageSize = 100, pageToken, filter }: UseAccountPageOptions = {}) {
  const queryKey = useMemo(
    () => ["accountPage", pageSize, pageToken, filter],
    [pageSize, pageToken, filter],
  );

  const { data, error, isLoading } = useQuery<AccountPage, Error>({
    queryKey,
    queryFn: () => fetchAccountPage(pageSize, pageToken, filter),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}
