import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  StructuredListWrapper,
  StructuredListHead,
  StructuredListBody,
  StructuredListRow,
  StructuredListCell,
  Button,
  Modal,
} from "@carbon/react";
import CPage from "../../components/CPage";
import { useTransferRule } from "./api/getTransferRule";
import { ParseName } from "../../lib/api/name";
import { useDeleteRule } from "./api/deleteTransferRule";
import PolicyEditModal from "./components/PolicyEditModal";

const PolicyDetails: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  // Fetch policy details
  const {
    data: policy,
    isLoading,
    error,
    isError,
  } = useTransferRule({
    transferId: `transfer-rules/${id!}`,
    pageSize: 1,
  });

  const { mutate: deleteRule } = useDeleteRule();

  const handleEditSubmit = () => {
    setIsEditModalOpen(false);
  };

  const handleDelete = () => {
    deleteRule(policy!.name!);
    setIsDeleteModalOpen(false);
    navigate("/policy");
  };

  if (isLoading) {
    return <div>Loading policy details...</div>;
  }

  // Handle error state
  if (isError) {
    return <div>Error fetching policy details: {error?.message}</div>;
  }

  // Handle case when no policy is found
  if (!policy) {
    return <div>No policy details found.</div>;
  }

  return (
    <CPage title="Policy Details">
      <div style={headerStyle}>
        <Button size="sm" onClick={() => navigate("/policy")}>
          Back to Policies
        </Button>
        <div style={buttonGroupStyle}>
          <Button
            size="sm"
            onClick={() => navigate("/policy/creator", { state: { selectedRow: policy } })}
          >
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsDeleteModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>
      <StructuredListWrapper>
        <StructuredListHead />
        <StructuredListBody>
          {renderStructuredListRow("Created", policy.create_time || "")}
          {renderStructuredListRow(
            "Asset",
            Array.isArray(policy.asset) ? policy.asset.join(", ") : policy.asset || "",
          )}
          {renderStructuredListRow("Name", ParseName(policy.name || "", "TransferRule").resourceId)}
          {renderStructuredListRow("Description", policy.notes?.description || "")}
          {renderStructuredListRow("Variant", policy.variant ?? "")}
          {renderStructuredListRow(
            "From",
            Array.isArray(policy.from) ? policy.from.join(", ") : policy.from || "",
          )}
          {renderStructuredListRow(
            "To",
            Array.isArray(policy.to) ? policy.to.join(", ") : policy.to || "",
          )}
        </StructuredListBody>
      </StructuredListWrapper>

      <Modal
        open={isDeleteModalOpen}
        modalHeading="Confirm Delete"
        primaryButtonText={"Delete"}
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsDeleteModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
      >
        <p>
          Are you sure you want to delete: {ParseName(policy.name!, "TransferRule").resourceId}?
        </p>
      </Modal>

      {isEditModalOpen && (
        <PolicyEditModal
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
          onSubmit={handleEditSubmit}
          selectedRow={policy}
        />
      )}
    </CPage>
  );
};

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: "1rem",
};

const buttonGroupStyle = {
  display: "flex",
  gap: "1rem",
};

const renderStructuredListRow = (header: string, content: string) => (
  <StructuredListRow>
    <StructuredListCell head>{header}</StructuredListCell>
    <StructuredListCell>{content}</StructuredListCell>
  </StructuredListRow>
);

export default PolicyDetails;
