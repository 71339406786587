import React from "react";
import { InlineNotification, Loading } from "@carbon/react";

import { useModalCtx } from "../CModal";

export default function ModalSubmitButton() {
  const ctx = useModalCtx() as any;
  return (
    <>
      {/* empty div such that dropdown don't get cut out */}
      <div
        style={{
          height: 120,
          display: "flex",
          justifyContent: "flex-end",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {ctx.notif?.success ? (
          <InlineNotification
            aria-label="closes notification"
            subtitle={ctx.notif?.success}
            title=""
            kind="success"
          />
        ) : null}
        {ctx.notif?.error ? (
          <InlineNotification
            aria-label="closes notification"
            subtitle={ctx.notif?.error}
            title=""
          />
        ) : null}
      </div>

      <button ref={ctx.ref} type="submit" style={{ display: "none" }}>
        Submit
      </button>

      {ctx.loading ? <Loading withOverlay /> : null}
    </>
  );
}
