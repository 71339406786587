import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface AccountFormData {
  name: string;
  description: string;
  variant: "internal" | "external";
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: AccountFormData): Promise<void> {
  await httpClientInstance.createAccount([formData.name], {
    variant: formData.variant,
    notes: { description: formData.description },
  });
}

export function useGenerateAccountMutation(): UseMutationResult<any, SubmitError, AccountFormData> {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Address Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting address form:", error.message);
    },
  });
}
