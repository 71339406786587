import React from "react";

import CModal from "../../../components/CModal";
import PolicyCreateForm from "./PolicyCreateForm";

export default function PolicyCreateModal() {
  return (
    <CModal title="Create Rule" primaryButtonText="Create" returnUrl="/policy">
      <PolicyCreateForm />
    </CModal>
  );
}
