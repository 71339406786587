import { ed25519 } from "@noble/curves/ed25519";
import { WebauthnClient } from "./webauthn";
import { hex } from "@scure/base";

export type Algorithm =
  | "ecdsa-p256-sha256"
  | "ed25519"
  | "ecdsa-k256-sha256"
  | "web-authn"
  | "web-authn-uv";

export interface CredentialObject {
  public_key: string;
}

export interface Signer {
  sign: (data: Uint8Array) => Promise<Uint8Array>;
  algorithm: () => Algorithm;
  keyid: () => string;
}

export interface SignResponse {
  signature: Uint8Array;
  // hex encoded
  key_id: string;
}

export class InviteSigner implements Signer {
  credential: Uint8Array;
  public_key: string;
  // invite code should be encoded as hexidecimal / base16
  constructor(inviteCode: string) {
    // this.public_key = cred.public_key;
    inviteCode = inviteCode.padStart(64, "0");
    this.credential = hex.decode(inviteCode);
    if (this.credential.length != 32) {
      throw new TypeError("invite code should not be longer than 64 hex characters");
    }
    const pubkey = ed25519.getPublicKey(this.credential);
    this.public_key = hex.encode(pubkey);
  }
  async sign(data: Uint8Array): Promise<Uint8Array> {
    const rawsig = await ed25519.sign(data, this.credential);
    return rawsig;
  }
  algorithm(): Algorithm {
    return "ed25519";
  }
  keyid(): string {
    return this.public_key;
  }
}

export class SessionSigner implements Signer {
  sessionCredential: Uint8Array;
  public_key: string;
  constructor(session: Uint8Array, cred: CredentialObject) {
    this.sessionCredential = session;
    this.public_key = cred.public_key;
  }
  async sign(data: Uint8Array): Promise<Uint8Array> {
    const rawsig = await ed25519.sign(data, this.sessionCredential);
    return rawsig;
  }
  algorithm(): Algorithm {
    return "ed25519";
  }
  keyid(): string {
    return this.public_key;
  }
}

export class WebauthnSigner implements Signer {
  client: WebauthnClient;
  uv: boolean;
  constructor(client: WebauthnClient) {
    this.client = client;
    this.uv = false;
  }
  async sign(data: Uint8Array): Promise<Uint8Array> {
    const response = await this.client.Sign(data);
    return response.binaryAssertion;
  }
  algorithm(): Algorithm {
    if (this.uv) {
      return "web-authn-uv";
    } else {
      return "web-authn";
    }
  }
  keyid(): string {
    // webauthn we return the username since we cannot determine
    // which credential will be used before signing, as this is something
    // that the authenticator will choose when signing.
    return this.client.args.user!.username;
  }
}
