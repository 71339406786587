import { GetChainInfo, substitute } from "./ExplorerData";

export const getTxUrl = (native_asset: string, tx_hash: string): string => {
  const info = GetChainInfo(native_asset);
  if (info == undefined || info.explorer_urls == undefined) {
    return "";
  }
  if (info.explorer_urls.tx == undefined) {
    return "";
  }

  return substitute(info.explorer_urls.tx, tx_hash);
};
export const getAddressUrl = (native_asset: string, address: string): string => {
  const info = GetChainInfo(native_asset);
  if (info == undefined || info.explorer_urls == undefined) {
    return "";
  }
  if (info.explorer_urls.address == undefined) {
    return "";
  }

  return substitute(info.explorer_urls.address, address);
};
export const getContractUrl = (native_asset: string, contract: string): string => {
  const info = GetChainInfo(native_asset);
  if (info == undefined || info.explorer_urls == undefined) {
    return "";
  }
  if (info.explorer_urls.token == undefined) {
    return "";
  }

  return substitute(info.explorer_urls.token, contract);
};
