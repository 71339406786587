import { useQuery } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { CredentialPage } from "../../../lib/api/types";

async function fetchCredentials(pageSize: number): Promise<CredentialPage> {
  return httpClientInstance.listCredential({ page_size: pageSize });
}

interface UseCredentialsPageOptions {
  pageSize?: number;
}

export function useCredentialsPage({ pageSize = 100 }: UseCredentialsPageOptions = {}) {
  const queryKey = ["credentials", pageSize];

  const { data, error, isLoading } = useQuery<CredentialPage, Error>({
    queryKey,
    queryFn: () => fetchCredentials(pageSize),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}
