import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
} from "react";
import { Checkbox, Button, TextInput, Tag } from "@carbon/react";
import { ChevronDown, ChevronRight } from "@carbon/react/icons";
import "./TreeSelect.css";
import { ParseName } from "../../lib/api/name";

export interface TreeNode {
  id: string;
  label: string;
  children?: TreeNode[];
  expanded?: boolean;
}

interface TreeSelectProps {
  initialNodes: TreeNode[];
  selectedIds: string[];
  setSelectedIds: (ids: string[]) => void;
  label?: string;
}

const TreeSelect: React.FC<TreeSelectProps> = ({
  initialNodes,
  selectedIds,
  setSelectedIds,
  label,
}) => {
  const [nodes, setNodes] = useState<TreeNode[]>(initialNodes);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expandedNodes, setExpandedNodes] = useState<Set<string>>(new Set());

  useEffect(() => {
    setNodes(initialNodes);
  }, [initialNodes]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const debouncedSearchTerm = useDebounce(searchTerm, 300);

  const toggleNodeExpansion = useCallback((nodeId: string) => {
    setExpandedNodes((prevExpandedNodes) => {
      const newExpandedNodes = new Set(prevExpandedNodes);
      if (newExpandedNodes.has(nodeId)) {
        newExpandedNodes.delete(nodeId);
      } else {
        newExpandedNodes.add(nodeId);
      }
      return newExpandedNodes;
    });
  }, []);

  const updateSelectionRecursive = useCallback(
    (node: TreeNode, isChecked: boolean, currentIds: Set<string>) => {
      if (isChecked) {
        currentIds.add(node.id);
      } else {
        currentIds.delete(node.id);
      }
      node.children?.forEach((child) => updateSelectionRecursive(child, isChecked, currentIds));
    },
    [],
  );

  const findNode = useCallback((nodes: TreeNode[], id: string): TreeNode | null => {
    for (const node of nodes) {
      if (node.id === id) {
        return node;
      } else if (node.children) {
        const result = findNode(node.children, id);
        if (result) return result;
      }
    }
    return null;
  }, []);

  const handleCheckboxChange = useCallback(
    (id: string, isChecked: boolean) => {
      const node = findNode(nodes, id);
      if (node) {
        const newSelectedIds = new Set(selectedIds);
        updateSelectionRecursive(node, isChecked, newSelectedIds);
        setSelectedIds(Array.from(newSelectedIds));
      }
    },
    [findNode, nodes, selectedIds, updateSelectionRecursive, setSelectedIds],
  );

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  };

  function formatLabel(label: string): string {
    if (label.startsWith("accounts")) {
      return ParseName(label, "Account").resourceId;
    }
    if (label.startsWith("chain")) {
      if (label.includes("addresses")) {
        return ParseName(label, "Address").resourceId;
      }
      if (label.includes("assets")) {
        return ParseName(label, "Asset").resourceId;
      } else {
        return label.split("/")[1];
      }
    }
    if (label.startsWith("roles")) {
      return ParseName(label, "Role").resourceId;
    }
    if (label.startsWith("users")) {
      return ParseName(label, "User").resourceId;
    }
    return label;
  }

  const isCheckboxDisabled = (label: string) => {
    if (label.startsWith("chain")) {
      if (!label.includes("assets") && !label.includes("addresses")) {
        return true;
      }
    }
    return false;
  };

  const renderTree = useMemo(() => {
    const filterTree = (nodes: TreeNode[], level: number): JSX.Element[] => {
      return nodes
        .filter((node) => {
          // Always include root level nodes
          if (level === 0) {
            return true;
          }
          // Otherwise, filter based on the search term
          return (
            node.label.toLowerCase().includes(debouncedSearchTerm.toLowerCase()) ||
            (node.children &&
              node.children.some((child) => filterTree([child], level + 1).length > 0))
          );
        })
        .map((node) => (
          <div key={node.id} style={{ marginLeft: `${level * 20}px` }} className="tree-node">
            <div className="node-label">
              {node.children && node.children.length > 0 && (
                <Button
                  kind="ghost"
                  size="sm"
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleNodeExpansion(node.id);
                  }}
                  renderIcon={expandedNodes.has(node.id) ? ChevronDown : ChevronRight}
                  iconDescription="Expand"
                  type="button"
                />
              )}
              {level === 0 ? (
                <TextInput
                  style={{ minWidth: "600px", marginTop: "10px" }}
                  size="lg"
                  labelText=""
                  id="tree-select-root-input"
                  placeholder="Type to search..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={handleKeyDown}
                  type="text"
                />
              ) : (
                <Checkbox
                  id={node.id}
                  labelText={formatLabel(node.label)}
                  disabled={isCheckboxDisabled(node.label)}
                  checked={selectedIds.includes(node.id)}
                  onChange={(e) => handleCheckboxChange(node.id, e.target.checked)}
                />
              )}
            </div>
            {expandedNodes.has(node.id) && node.children && (
              <div>{filterTree(node.children, level + 1)}</div>
            )}
          </div>
        ));
    };

    return filterTree(nodes, 0);
  }, [
    nodes,
    debouncedSearchTerm,
    expandedNodes,
    searchTerm,
    selectedIds,
    toggleNodeExpansion,
    handleCheckboxChange,
  ]);

  return (
    <div style={{ width: "100%", height: "90%" }}>
      <span style={{ color: "#525252", fontSize: "0.750rem" }}>{label}</span>
      <div style={{ width: "95%" }}>
        {selectedIds.map((id) => (
          <Tag key={id} type="gray" filter onClose={() => handleCheckboxChange(id, false)}>
            {formatLabel(findNode(nodes, id)?.label || "")}
          </Tag>
        ))}
      </div>
      <div style={{ width: "95%" }}>{renderTree}</div>
    </div>
  );
};

function useDebounce(value: string, delay: number): string {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default React.memo(TreeSelect);
