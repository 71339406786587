import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface AddressFormData {
  chain: string;
  address: string;
  description: string;
  variant: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: AddressFormData): Promise<void> {
  console.log("formData", formData);
  if (formData.address === "") {
    await httpClientInstance.createAddress([formData.chain], {
      variant: "internal",
      notes: { description: formData.description },
    });
  } else {
    await httpClientInstance.createAddress([formData.chain, formData.address], {
      variant: "external",
      address: formData.address,
      notes: { description: formData.description },
    });
  }
}

export function useGenerateAddressMutation(
  options?: UseMutationOptions<void, SubmitError, AddressFormData>,
): UseMutationResult<void, SubmitError, AddressFormData> {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Address Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting address form:", error.message);
    },
    ...options,
  });
}
