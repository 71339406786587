export class RequestError extends Error {
  status: string;
  code: number;
  constructor(msg: string, status: string, code: number) {
    super(msg);
    this.status = status;
    this.code = code;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RequestError.prototype);
  }
}

export class TimeoutError extends Error {
  timeout_milliseconds: number;
  constructor(msg: string, timeout_milliseconds: number) {
    super(msg);
    this.timeout_milliseconds = timeout_milliseconds;
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, TimeoutError.prototype);
  }
}

// name parsing issue
export class NameError extends Error {
  constructor(msg: string) {
    super(msg);
    // Set the prototype explicitly.
    Object.setPrototypeOf(this, NameError.prototype);
  }
}
