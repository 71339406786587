import React from "react";

import CModal from "../../../components/CModal";

export default function LinkAddressModal() {
  return (
    <CModal title="Link Addresses to Account" returnUrl="/accounts">
      <div>Link Address Form</div>
    </CModal>
  );
}
