import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { NormalizeName, ParseName } from "lib/api/name";
export interface AssetFormData {
  asset: string;
  name: string;
  contract: string;
  symbol: string;
  decimals: number;
  labels: any;
  notes: any;
}
interface SubmitError {
  message: string;
}

const extractChain = (input: string): string => {
  const parts = input.split("/");
  const firstTwoParts = parts.slice(0, 2);
  return firstTwoParts.join("/");
};

async function submitFormData(formData: AssetFormData): Promise<void> {
  console.log("form data", formData);
  const normalizedContract = NormalizeName(formData.contract);
  // const assetName: Name = [extractChain(formData.asset), normalizedContract];
  const assetName = ParseName([extractChain(formData.asset), normalizedContract], "Asset");
  await httpClientInstance.createAsset([assetName.parentId!, assetName.resourceId], {
    variant: "token",
    decimals: formData.decimals,
    contract: formData.contract,
    notes: { description: formData.notes.description },
  });
  await httpClientInstance.createSymbol(
    [`${extractChain(formData.asset)}/symbols/${formData.symbol}`],
    {
      name: `${extractChain(formData.asset)}/symbols/${formData.symbol}`,
      asset: `chains/${assetName.parentId!}/assets/${assetName.resourceId}`,
      notes: { description: formData.notes.description },
    },
  );
}

export function useAddAssetMutation(): UseMutationResult<any, SubmitError, AssetFormData> {
  console.log("use add mutation  called ");

  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Asset Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting Asset form:", error.message);
    },
  });
}
