import React from "react";

import CModal from "../../../components/CModal";
import TransferCreateForm from "./TransferCreateForm";

export default function TransferCreateModal() {
  return (
    <CModal title="Create transfer" primaryButtonText="Create" returnUrl="/transfers">
      <TransferCreateForm />
    </CModal>
  );
}
