import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.scss";
import { ClerkProvider } from "@clerk/clerk-react";
import { GetClerkPublishableKey } from "./lib/constants";
// import {dark, shadesOfPurple} from "@clerk/themes";
declare global {
  // See ./public/index.html to see variables configured into the website.
  interface Window {
    API_URL: string;
    FIDO_RP_ID: string;
    USER_EMAIL: string;
    CLERK_PUBLISHABLE_KEY: string;
    CLERK_PUBLISHABLE_TEST_KEY: string;
    CLERK_DEV: boolean;
    DEMO_FUNCTIONS: boolean;
  }
}

const PUBLISHABLE_KEY: string = GetClerkPublishableKey();

console.log("PUBLISHABLE_KEY", PUBLISHABLE_KEY);

if (!PUBLISHABLE_KEY) {
  throw new Error("Missing Publishable Key");
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ClerkProvider
      publishableKey={PUBLISHABLE_KEY}
      // appearance={shadesOfPurple}
      //  isSatellite={false} // Add this line
      //  proxyUrl="frontend-api.clerk.services" // Add this line
    >
      <App />
    </ClerkProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
