import React from "react";

import OrgTreasuryEndPointSelector from "./OrgTreasuryEndPointSelector";

export default function OrgTreasuryEndPointSelectorModal() {
  return (
    <OrgTreasuryEndPointSelector
      handleToggleChange={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
}
