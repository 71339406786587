//this formis not used as it has been combined with policy edit form this is kept for references and uses the old component
import React, { useState, useEffect } from "react";
import { Field, Formik, FieldProps } from "formik";
import { useSearchParams } from "react-router-dom";
import TreeSelect, { TreeNode } from "../../../components/DropDownTree/DropDownTree";
import { Form, ModalSubmitButton, MultiColumnRow } from "../../../components/form";
import { useModalSetters } from "../../../components/CModal";
import { useCombinedAccountAndAddress } from "../../../features/accountsManagement/hooks/useCombinedAccountAndAddress";
import { TextInput, RadioButton, RadioButtonGroup, NumberInput } from "@carbon/react";
import { useUsers } from "../../../features/usersManagement/api/useUsers";
import { useGetAssetPage } from "../../../features/assetManagement/api/getAssetPage";
import { Asset, TransferRule, User } from "@/lib/api/types";
import { UpdateTransferRuleFormData, useUpdateTransferRule } from "../api/updateTransferRule";

interface PolicyEditFormProps {
  selectedRow: TransferRule | null;
  onSubmit: (values: any) => void;
}

interface AddressListItem {
  name: string;
  account: string;
}

interface GroupedAssets {
  chain: string;
  assets: Asset[];
}

const PolicyEditForm: React.FC<PolicyEditFormProps> = ({ selectedRow }) => {
  const [searchParams] = useSearchParams();
  const { addressList, isLoading, error } = useCombinedAccountAndAddress();
  const createUpdateTransferMutation = useUpdateTransferRule();
  //this may break pagination
  const users = useUsers(1, 500, "");
  const assets = useGetAssetPage({ pageSize: 1000 });
  const modalSetters = useModalSetters();

  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedFromIds, setSelectedFromIds] = useState<string[]>([]);
  const [selectedToIds, setSelectedToIds] = useState<string[]>([]);

  const addFromSuffix = (arr: any[]) => arr.map((item) => `${item}from`);
  const addToSuffix = (arr: any[]) => arr.map((item) => `${item}to`);

  useEffect(() => {
    if (selectedRow?.to) {
      if (selectedRow?.to === "any/address") {
        setSelectedToIds(["allAccountsAndAddressesTo"]);
      } else {
        const toIds = Array.isArray(selectedRow.to) ? selectedRow.to : [selectedRow.to];
        setSelectedToIds(addToSuffix(toIds));
      }
    }
    if (selectedRow?.from) {
      if (selectedRow?.from === "any/address") {
        setSelectedFromIds(["allAccountsAndAddressesFrom"]);
      } else {
        const fromIds = Array.isArray(selectedRow.from) ? selectedRow.from : [selectedRow.from];
        setSelectedFromIds(addFromSuffix(fromIds));
      }
    }
    if (selectedRow?.initiate) {
      if (selectedRow?.initiate === "any/user") {
        setSelectedUserIds(["allRoles"]);
      } else {
        setSelectedUserIds(
          Array.isArray(selectedRow.initiate) ? selectedRow.initiate : [selectedRow.initiate],
        );
      }
    }
    if (selectedRow?.asset) {
      if (selectedRow?.asset === "any/asset") {
        setSelectedAssetIds(["allAssets"]);
      } else {
        setSelectedAssetIds(
          Array.isArray(selectedRow.asset) ? selectedRow.asset : [selectedRow.asset],
        );
      }
    }
  }, [selectedRow]);

  const initialValues: UpdateTransferRuleFormData = {
    asset: searchParams.get("asset") || "",
    amountMin: searchParams.get("amountMin") || "",
    amountMax: searchParams.get("amountMax") || "",
    from: searchParams.get("from") || "",
    to: searchParams.get("to") || "",
    selectedIds: [],
    policyName: selectedRow?.name || "",
    policyDescription: selectedRow?.notes?.description || "",
    permissionType: selectedRow?.variant || "deny",
    selectedToIds: [],
    selectedFromIds: [],
    selectedAssetIds: [],
    selectedUserIds: [],
    initialObject: selectedRow || {},
    approvals: selectedRow?.approvals || 0,
  };

  const convertToAccountAndAddressTreeNodes = (
    addressList: AddressListItem[],
    uniqueKey: string,
  ): TreeNode[] => {
    const groupedByAccount: { [key: string]: AddressListItem[] } = addressList.reduce(
      (acc, address) => {
        const accountKey = address.account || "No Account";
        if (!acc[accountKey]) {
          acc[accountKey] = [];
        }
        acc[accountKey].push(address);
        return acc;
      },
      {} as { [key: string]: AddressListItem[] },
    );

    const treeNodes: TreeNode[] = Object.keys(groupedByAccount).map((account) => ({
      id: account + uniqueKey,
      label: account,
      children: groupedByAccount[account].map((address) => ({
        id: address.name + uniqueKey,
        label: address.name,
        children: [],
        expanded: false,
      })),
      expanded: false,
    }));

    return treeNodes;
  };

  const groupAssetsByChain = (assets: Asset[]): GroupedAssets[] => {
    const grouped = assets.reduce((acc: { [chain: string]: Asset[] }, asset: Asset) => {
      const chain = asset.name!.split("/")[0] + "/" + asset.name!.split("/")[1];
      if (!acc[chain]) {
        acc[chain] = [];
      }
      acc[chain].push(asset);
      return acc;
    }, {});

    return Object.keys(grouped).map((chain) => ({
      chain,
      assets: grouped[chain],
    }));
  };

  const convertAssetsToTreeNodes = (groupedAssets: GroupedAssets[]): TreeNode[] => {
    return groupedAssets.map((group) => ({
      id: group.chain,
      label: group.chain,
      children: group.assets.map((asset) => ({
        id: asset.name ?? "",
        label: asset.name ?? "",
        children: [],
        expanded: false,
      })),
      expanded: false,
    }));
  };

  const groupUsersByRoles = (users: User[]): { [role: string]: User[] } => {
    if (!users) {
      return {};
    }
    return users.reduce(
      (acc, user) => {
        if (user.roles) {
          user.roles.forEach((role) => {
            if (!acc[role]) {
              acc[role] = [];
            }
            acc[role].push(user);
          });
        }
        return acc;
      },
      {} as { [role: string]: User[] },
    );
  };

  const rolesUser = groupUsersByRoles(users?.users ?? []);

  const convertRolesToTreeNodes = (groupedUsers: { [role: string]: User[] }): TreeNode[] => {
    return Object.keys(groupedUsers).map((role) => ({
      id: role ?? "",
      label: role ?? "",
      children: groupedUsers[role].map((user) => ({
        id: user.name ?? "",
        label: user.name ?? "",
        children: [],
        expanded: false,
      })),
      expanded: false,
    }));
  };

  const initalAssetNodes: TreeNode[] = [
    {
      id: "root",
      label: "Accounts",
      children: [
        {
          id: "allAssets",
          label: "All",
          children: [],
          expanded: false,
        },
        ...convertAssetsToTreeNodes(groupAssetsByChain(assets?.data?.assets ?? [])),
      ],
      expanded: true,
    },
  ];

  const initalUsersNodes: TreeNode[] = [
    {
      id: "root",
      label: "Accounts",
      children: [...convertRolesToTreeNodes(rolesUser)],
      expanded: true,
    },
  ];

  const initialAccountAndAddressFromNodes: TreeNode[] = [
    {
      id: "root",
      label: "Accounts",
      children: [
        {
          id: "allAccountsAndAddressesFrom",
          label: "All",
          children: [],
          expanded: false,
        },
        ...convertToAccountAndAddressTreeNodes(addressList as AddressListItem[], "from"),
      ],
      expanded: true,
    },
  ];

  const initialAccountAndAddressToNodes: TreeNode[] = [
    {
      id: "root",
      label: "Accounts",
      children: [
        {
          id: "allAccountsAndAddressesTo",
          label: "All",
          children: [],
          expanded: false,
        },
        ...convertToAccountAndAddressTreeNodes(addressList as AddressListItem[], "to"),
      ],
      expanded: true,
    },
  ];

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        const { setLoading, setNotif } = modalSetters;
        const removeFromSuffix = (arr: any[]) => arr.map((item) => item.replace(/from$/, ""));
        const removeToSuffix = (arr: any[]) => arr.map((item) => item.replace(/to$/, ""));

        setLoading(true);

        const extendedValues = {
          ...values,
          initialObject: selectedRow,
          selectedAssetIds,
          selectedFromIds: removeFromSuffix(selectedFromIds),
          selectedToIds: removeToSuffix(selectedToIds),
          selectedUserIds,
        };

        console.log("Extended Values:", extendedValues);

        createUpdateTransferMutation.mutate(extendedValues, {
          onError: (err) => {
            setLoading(false);
            setNotif({ success: "", error: `Error: ${err.message}` });
          },
          onSuccess: () => {
            setLoading(false);
            setNotif({ success: `Rule for ${values.policyName} updated successfully!`, error: "" });
          },
        });
      }}
    >
      {({ values, handleChange, setFieldValue }) => (
        <Form style={{ minHeight: 500 }}>
          <MultiColumnRow>
            <Field
              style={{ width: "96%" }}
              id="policyDescription"
              className="cds--form-item"
              labelText="Rule Description"
              name="policyDescription"
              as={TextInput}
              onChange={handleChange}
              value={values.policyDescription}
            />
            <Field name="permissionType">
              {({ field }: FieldProps) => (
                <RadioButtonGroup
                  legendText="Rule Permission"
                  name="permissionType"
                  valueSelected={field.value}
                  onChange={(value) => field.onChange({ target: { name: field.name, value } })}
                >
                  <RadioButton value="allow" id="allow" labelText="Allow" />
                  <RadioButton value="deny" id="deny" labelText="Deny" />
                  <RadioButton value="require" id="require" labelText="Require" />
                </RadioButtonGroup>
              )}
            </Field>
          </MultiColumnRow>
          <MultiColumnRow>
            <div style={{ width: "550px" }}>
              <TreeSelect
                label="Asset"
                initialNodes={initalAssetNodes}
                selectedIds={selectedAssetIds}
                setSelectedIds={setSelectedAssetIds}
              />
            </div>
          </MultiColumnRow>
          <MultiColumnRow>
            <TreeSelect
              label="From"
              initialNodes={initialAccountAndAddressFromNodes}
              selectedIds={selectedFromIds}
              setSelectedIds={setSelectedFromIds}
            />
            <TreeSelect
              label="To"
              initialNodes={initialAccountAndAddressToNodes}
              selectedIds={selectedToIds}
              setSelectedIds={setSelectedToIds}
            />
          </MultiColumnRow>
          <MultiColumnRow>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "start",
                width: "100%",
              }}
            >
              <TreeSelect
                label="Users"
                initialNodes={initalUsersNodes}
                selectedIds={selectedUserIds}
                setSelectedIds={setSelectedUserIds}
              />

              <div style={{ paddingTop: "22px", paddingLeft: "70px", fontSize: "10px" }}>
                <span>Number of approvers</span>
                <Field name="approvals">
                  {({ field }: FieldProps) => (
                    <NumberInput
                      style={{ width: "500px" }}
                      id="approvals"
                      name="approvals"
                      value={field.value}
                      onChange={(event, { value }) => {
                        console.log("valuer", value);
                        setFieldValue(field.name, value);
                      }}
                    />
                  )}
                </Field>
              </div>
            </div>
          </MultiColumnRow>
          <ModalSubmitButton />
        </Form>
      )}
    </Formik>
  );
};

export default PolicyEditForm;
