import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { Address } from "../../../lib/api/types";

function fetchAddress(addressId: string, pageSize: number): Promise<Address> {
  return httpClientInstance.getAddress(addressId, {
    page_size: pageSize,
  });
}

interface UseAddressOptions {
  addressId: string;
  pageSize?: number;
}

export function useAddress({ addressId, pageSize = 100 }: UseAddressOptions) {
  const queryKey = useMemo(() => ["Address", addressId, pageSize], [addressId, pageSize]);

  const { data, error, isLoading, isError } = useQuery<Address, Error>({
    queryKey,
    queryFn: () => fetchAddress(addressId, pageSize),
    refetchInterval: 10000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
