import React from "react";

import CPage from "../../../components/CPage";
import PolicyCreateForm from "./PolicyCreateForm";

export default function PolicyCreator() {
  return (
    <CPage title={"Policy"}>
      <PolicyCreateForm />
    </CPage>
  );
}
