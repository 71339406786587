import React from "react";
import { useMutation } from "@tanstack/react-query";
import { Link, Outlet } from "react-router-dom";
import CPage from "../../components/CPage";

import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@carbon/react";
import { Collaborate, FaceAdd } from "@carbon/react/icons";
import CButton from "../../components/CButton";

import { UserPage } from "../../lib/api/types";
import CredentialTable from "./components/CredentialTable";
import RoleTable from "./components/RoleTable";
import UsersTable from "./components/UsersTable";
import { useCredentialsPage } from "./api/getCredentialPage";
import { useRole } from "./api/getRoles";
import { useUser, useUsers } from "./api/useUsers";
import RegisterCredentialForm from "./components/form/RegisterCredentialForm";
import { registerInviteCode } from "./services/registerInviteCode";
import { useNotificationStore } from "../../stores/notifications";

export default function UsersPage() {
  const currentUser = useUser();

  const initialUserPage = useUsers(1, 30, "") as UserPage;
  const { data: credentials } = useCredentialsPage();
  const { data: role } = useRole();

  const { addNotification } = useNotificationStore();

  const postCrendentialMutation = useMutation({
    mutationFn: async (invitationCode: string) => {
      return registerInviteCode(currentUser!, invitationCode);
    },
    onSuccess: () => {
      console.log("success you registered a credential");
      addNotification({
        type: "success",
        title: `Credential Registered successfully`,
      });
    },
    onError: (error) => {
      console.log("failure you did not register a credential", error);
      addNotification({
        type: "error",
        title: `${error}`,
      });
    },
  });

  const registerCredentialPanel = (
    <TabPanel>
      {currentUser && currentUser.name ? (
        <div style={{ paddingTop: "15px" }}>
          <RegisterCredentialForm registerCredential={postCrendentialMutation} />
          <span style={{ fontSize: "12px" }}> Note: Please input your invitation code</span>
        </div>
      ) : (
        <p>No Credentials</p>
      )}
    </TabPanel>
  );

  return (
    <CPage title="Users Management">
      <Tabs>
        <TabList aria-label="List of tabs" contained>
          <Tab>Register credentials</Tab>
          <Tab>Users</Tab>
          <Tab>Credential</Tab>
          <Tab>Roles</Tab>
        </TabList>
        <TabPanels>
          {registerCredentialPanel}
          <TabPanel>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <h3>Manage your users</h3>
              <div>
                <CButton renderIcon={FaceAdd} as={Link} to="./create">
                  Add a user
                </CButton>
                <CButton renderIcon={Collaborate} as={Link} to="./invite">
                  Generate invite code
                </CButton>
                <CButton renderIcon={Collaborate} as={Link} to="./link-user">
                  Link User to Cordial Account
                </CButton>
              </div>
            </div>
            {initialUserPage && initialUserPage.users ? (
              <UsersTable initialUserPage={initialUserPage} />
            ) : (
              <p>No users available</p>
            )}
          </TabPanel>
          <TabPanel>
            <h3>Credential</h3>
            {credentials && credentials.credentials ? (
              <CredentialTable credentialPage={credentials} />
            ) : (
              <p>No Credentials</p>
            )}
          </TabPanel>
          <TabPanel>
            <h3>Roles</h3>
            {role && role.roles ? <RoleTable rolePage={role} /> : <p>No Roles</p>}
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Outlet />
    </CPage>
  );
}
