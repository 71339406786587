import { useEffect, useState, useRef } from "react";
import { useOrganizationAndTreasuries } from "../../../features/organizationManagement/api/getOrganizationsAndTreasuries";
import { useTreasuryUsers } from "../../../features/organizationManagement/api/getTreasuryUsers";
import useCachedState from "../../../hooks/useCachedState";
import { TreasuryUser } from "../../../features/organizationManagement/types/treasuryUserType";
import { KEY_API } from "lib/constants";

const useAvailableUsers = () => {
  const { data: organisationData } = useOrganizationAndTreasuries();
  const { data: treasuryUsersData } = useTreasuryUsers();
  const [availableUsers, setAvailableUsers] = useState<TreasuryUser[]>([]);
  const [cachedApiURL] = useCachedState<string>(KEY_API, "");

  const hasUpdated = useRef(false);

  useEffect(() => {
    if (!hasUpdated.current && cachedApiURL && organisationData && treasuryUsersData) {
      let orgName: string | undefined;

      organisationData.userTreasuries.forEach((treasury) => {
        if (treasury.api === cachedApiURL) {
          orgName = treasury.organization;
        }
      });

      if (orgName) {
        const clerkUsers: TreasuryUser[] = [];
        organisationData.userOrganisations.forEach((org) => {
          if (org.name === orgName) {
            Object.keys(org.users).forEach((userId) => {
              const treasuryUser = treasuryUsersData.users.find((user) => user.name === userId);

              if (treasuryUser) {
                clerkUsers.push(treasuryUser);
              }
            });
          }
        });

        setAvailableUsers(clerkUsers);
        hasUpdated.current = true;
      }
    }
  }, [cachedApiURL, organisationData, treasuryUsersData]);

  return availableUsers;
};

export default useAvailableUsers;
