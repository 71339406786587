import React, { useEffect, useState } from "react";
import { ContentSwitcher, Switch, FilterableMultiSelect } from "@carbon/react";
import { ParseName } from "../../../../lib/api/name";

interface SelectItem {
  label: string;
  value: string;
}

interface AccountAddressToSelectorProps {
  accountAndAddressModeTo: string;
  accountOptions: SelectItem[];
  addressList: SelectItem[];
  setAccountAndAddressModeTo: (mode: string) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  resetMultiSelectTo: () => void;
  setSelectedAccountsTo: (accounts: SelectItem[]) => void;
  setSelectedAddressesTo: (addresses: SelectItem[]) => void;
  accountKeyTo: number;
  addressKeyTo: number;
  selectedRow: any;
}

const AccountAddressToSelector: React.FC<AccountAddressToSelectorProps> = ({
  accountAndAddressModeTo,
  accountOptions,
  addressList,
  setAccountAndAddressModeTo,
  setFieldValue,
  resetMultiSelectTo,
  setSelectedAccountsTo,
  setSelectedAddressesTo,
  accountKeyTo,
  addressKeyTo,
  selectedRow,
}) => {
  const [initialAccounts, setInitialAccounts] = useState<SelectItem[]>([]);
  const [initialAddresses, setInitialAddresses] = useState<SelectItem[]>([]);

  useEffect(() => {
    if (selectedRow && selectedRow.to) {
      if (Array.isArray(selectedRow.to)) {
        const isAccount = selectedRow.to.some((item: string) => item.startsWith("accounts/"));
        const isAddress = selectedRow.to.some((item: string) => item.startsWith("chains/"));

        if (isAccount) {
          setAccountAndAddressModeTo("account");
          const selectedAccounts = selectedRow.to.map((account: string) => {
            const matchedAccount = accountOptions.find((option) => option.value === account);
            return matchedAccount || { label: account, value: account };
          });
          setInitialAccounts(selectedAccounts);
          setSelectedAccountsTo(selectedAccounts);
          //@ts-expect-error err
          setFieldValue("accountTo", selectedAccounts.map((item) => item.value).join(","));
        } else if (isAddress) {
          setAccountAndAddressModeTo("address");
          const selectedAddresses = selectedRow.to.map((address: string) => {
            //@ts-expect-error err
            const matchedAddress = addressList.find((option) => option.name === address);
            return (
              {
                //@ts-expect-error err
                label: matchedAddress.name,
                value: matchedAddress,
              } || { label: address, value: address }
            );
          });
          setInitialAddresses(selectedAddresses);
          setSelectedAddressesTo(selectedAddresses);
          //@ts-expect-error err
          setFieldValue("addressTo", selectedAddresses.map((item) => item.value).join(","));
        }
      } else if (selectedRow.to === "any/address") {
        setAccountAndAddressModeTo("all");
        resetMultiSelectTo();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRow]);

  const extractDescription = (item: SelectItem): string => {
    //@ts-expect-error err
    const notes = item?.value.notes;
    if (!notes) return "";

    if (typeof notes === "object" && notes.description) {
      return `${notes.description}`;
    }

    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        return data.description || "";
      } catch (error) {
        return notes;
      }
    }

    return "";
  };

  // The item renderer function
  const ItemRendererComponent: React.FC<{ item: SelectItem }> = ({ item }) => {
    const parsedName = ParseName(item.label || "", "Address");
    const resourceId = parsedName.resourceId;
    const parentId = parsedName.parentId;
    const description = extractDescription(item);

    return (
      <div>
        <div>{resourceId}</div>
        <div style={{ display: "flex", gap: "100px" }}>
          <div>
            <strong>Chain:</strong> {parentId}
          </div>
          <div>
            <strong>Description:</strong> {description}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <div style={{ paddingBottom: "10px" }}>
          What Accounts / Addresses To would you like this Rule to apply to?
        </div>
        <ContentSwitcher
          onChange={(e) => {
            const selectedMode = e.name;
            setAccountAndAddressModeTo(selectedMode as string);
            setFieldValue("accountAndAddressModeTo", selectedMode);
            // setFieldValue("accountTo", "");
            // setFieldValue("addressTo", "");
            // resetMultiSelectTo();
            // setInitialAddresses([]);
          }}
          selectedIndex={
            accountAndAddressModeTo === "address"
              ? 0
              : accountAndAddressModeTo === "account"
                ? 1
                : 2
          }
          size="sm"
        >
          <Switch name="address" text="Address" />
          <Switch name="account" text="Account" />
          <Switch name="all" text="All" />
        </ContentSwitcher>
      </div>

      {accountAndAddressModeTo !== "all" && (
        <div style={{ marginBottom: "20px" }}>
          {accountAndAddressModeTo === "account" && (
            <FilterableMultiSelect
              key={accountKeyTo}
              id="accountTo"
              titleText="Select Account To"
              placeholder="Choose an account"
              //@ts-expect-error err
              items={accountOptions}
              //@ts-expect-error err
              initialSelectedItems={initialAccounts}
              //@ts-expect-error err
              itemToString={(item: SelectItem) => (item ? item.label : "")}
              onChange={(e) => {
                //@ts-expect-error err
                const selectedAccountsValues = e.selectedItems.map((item: SelectItem) => item);
                setSelectedAccountsTo(selectedAccountsValues);
                setFieldValue(
                  "accountTo",
                  selectedAccountsValues.map((item) => item.value).join(","),
                );
              }}
            />
          )}

          {accountAndAddressModeTo === "address" && (
            <div style={{ marginTop: "20px" }}>
              <FilterableMultiSelect
                key={addressKeyTo}
                id="addressTo"
                titleText="Select Address To"
                placeholder="Choose an address"
                //@ts-expect-error err
                items={addressList.map((address) => ({
                  //@ts-expect-error err
                  label: address.name,
                  value: address,
                }))}
                //@ts-expect-error err
                initialSelectedItems={initialAddresses}
                //@ts-expect-error err
                itemToString={(item) => (item ? item.label : "")}
                itemToElement={(item) => <ItemRendererComponent item={item as SelectItem} />}
                onChange={(e) => {
                  //@ts-expect-error err
                  const selectedAddressValues = e.selectedItems.map((item: SelectItem) => item);
                  setSelectedAddressesTo(selectedAddressValues);
                  setFieldValue(
                    "addressTo",
                    selectedAddressValues.map((item) => item.value).join(","),
                  );
                }}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default AccountAddressToSelector;
