import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { Credential } from "../../../lib/api/types";
import { Name } from "@/lib/api/name";

async function fetchCredential(name: Name): Promise<Credential> {
  if (name) {
    return httpClientInstance.getCredential(name);
  }
  throw new Error("WebAuthn credential not set, Name is required to fetch credential.");
}

export function useCredential(name: string): Credential | null {
  const queryKey = useMemo(() => ["credential", name], [name]);

  console.log("useCredential called with name:", name);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchCredential(name),
    refetchInterval: 1000,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching credential:", error);
      sessionStorage.removeItem("session");
    }
  }, [error]);

  return error || !data ? null : data;
}
