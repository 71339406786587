import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";

import { formatDate } from "../../../utils/formater";
import { useTransferTransactions } from "../../../features/transactionManagment/api/getTransferTransactions";
import { ParseName } from "../../../lib/api/name";

const TransferDetailsTable: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchQuery, setSearchQuery] = useState("");
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const [prevPageTokens, setPrevPageTokens] = useState<string[]>([]);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(50);

  const {
    data: transactionPage,
    isLoading: isLoadingTransactions,
    error: errorTransactions,
  } = useTransferTransactions({ name: `transfers/${id}`, pageSize, pageToken });

  console.log("transactionPagec", transactionPage);

  useEffect(() => {
    if (pageToken && !prevPageTokens.includes(pageToken)) {
      setPrevPageTokens((tokens) => [...tokens, pageToken]);
    }
  }, [pageToken, prevPageTokens]);

  useEffect(() => {
    if (transactionPage && transactionPage.total_size) {
      setTotalItems(transactionPage.total_size);
    }
  }, [transactionPage]);

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "update_time", header: "Updated" },
    { key: "state", header: "State" },
    { key: "chain", header: "Chain" },
    { key: "hash", header: "Hash" },
    { key: "fees", header: "Fees" },
    { key: "confirmations", header: "Confirmations" },
  ];

  const filteredRows = (transactionPage?.transactions || [])
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time ?? "") || "",
      update_time: formatDate(item.update_time ?? "") || "",
      chain: ParseName(item.chain, "Chain").resourceId,
      fees: item.fees?.map((fee) => `${fee.amount} `)?.join(", ") || "",
    }))
    .filter((row) => {
      return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
      );
    });

  const handlePageChange = ({
    page,
    pageSize: newPageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setPageToken(undefined);
      setPrevPageTokens([]);
    } else if (page > prevPageTokens.length + 1 && transactionPage?.next_page_token) {
      setPageToken(transactionPage.next_page_token);
    } else if (page <= prevPageTokens.length) {
      const newPrevPageTokens = [...prevPageTokens];
      newPrevPageTokens.pop();
      const prevToken = newPrevPageTokens[newPrevPageTokens.length - 1];
      setPageToken(prevToken);
      setPrevPageTokens(newPrevPageTokens);
    }
  };

  if (isLoadingTransactions) {
    return <div>Loading...</div>;
  }

  if (errorTransactions) {
    return <div>Error: {errorTransactions.message}</div>;
  }

  return (
    <div>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // @ts-expect-error error
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{cell.value}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <Pagination
        totalItems={totalItems}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 50, 100]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={prevPageTokens.length + 1}
      />
    </div>
  );
};

export default TransferDetailsTable;
