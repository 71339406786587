interface IconCellRendererProps {
  value: string;
}

export const IconCellRenderer: React.FC<IconCellRendererProps> = ({ value }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
      <span style={{ width: "20%", textAlign: "left" }}>{value}</span>
      <i
        className={`cc ${"value"}`}
        title={value}
        style={{ fontSize: "22px", width: "80%", textAlign: "center" }}
      ></i>
    </div>
  );
};
