// import { useQuery } from "@tanstack/react-query";
// import { useEffect, useMemo } from "react";
// import { httpClientInstance } from "../../../clientInstance";

// import { TransferPage } from "../../../lib/api/types";

// export const fetchTransfersPage = (pageSize: number = 100): Promise<TransferPage> => {
//   return httpClientInstance.listTransfers({ page_size: pageSize });
// };

// type UseTransfersPageOptions = {
//   pageSize: number;
// };

// export const useTransfersPage = ({ pageSize }: UseTransfersPageOptions) => {
//   const queryKey = useMemo(() => ["transfersPage", pageSize], [pageSize]);

//   const { data, error, isError } = useQuery({
//     queryKey,
//     queryFn: () => fetchTransfersPage(100),
//     refetchInterval: 10000,
//   });

//   useEffect(() => {
//     if (error) {
//       console.error("Error fetching transferPage:", error);
//     }
//   }, [error]);
//   return { data: data || null, error: isError ? error : null };
// };

import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { TransferPage } from "../../../lib/api/types";

async function fetchTransfersPage(
  pageSize: number,
  pageToken?: string,
  filter?: string,
): Promise<TransferPage> {
  return httpClientInstance.listTransfers({ page_size: pageSize, page_token: pageToken, filter });
}

interface UseTransfersPageOptions {
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export function useTransfersPage({
  pageSize = 100,
  pageToken,
  filter,
}: UseTransfersPageOptions = {}) {
  const queryKey = useMemo(
    () => ["transfersPage", pageSize, pageToken, filter],
    [pageSize, pageToken, filter],
  );

  const { data, error, isLoading } = useQuery<TransferPage, Error>({
    queryKey,
    queryFn: () => fetchTransfersPage(pageSize, pageToken, filter),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}
