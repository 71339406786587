// import React, { useState } from "react";
// import { CredentialPage } from "@/lib/api/types";
// import {
//   DataTable,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
//   TableToolbar,
//   TableToolbarSearch,
// } from "@carbon/react";
// import { ParseName } from "../../../lib/api/name";

// interface CredentialTableProps {
//   credentialPage: CredentialPage;
// }

// const CredentialTable: React.FC<CredentialTableProps> = ({ credentialPage }) => {
//   const [searchQuery, setSearchQuery] = useState("");
//   console.log("credentialPage", credentialPage);

//   const headers = [
//     {
//       key: "id",
//       header: "Id",
//     },
//     {
//       key: "name",
//       header: "Username",
//     },
//     {
//       key: "variant",
//       header: "Variant",
//     },
//     // {
//     //   key: "state",
//     //   header: "State",
//     // },
//   ];

//   const filteredRows = credentialPage.credentials
//     ? credentialPage.credentials
//         .map((credential, index) => ({
//           id: index.toString(),
//           name: credential.name,
//           variant: credential.variant,
//           state: credential.state,
//         }))
//         .filter((row) => {
//           const searchableText = `${row.name} ${row.variant} ${row.state}`;
//           return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
//         })
//     : [];

//   const renderCellContent = (cell: any) => {
//     switch (cell.info.header) {
//       case "id":
//         return ParseName([cell.value], "Credential").parentId || "NA";
//       case "name":
//         return ParseName([cell.value], "Credential").parentId || "NA";
//       default:
//         return cell.value;
//     }
//   };

//   return (
//     <>
//       <TableToolbar>
//         <TableToolbarSearch
//           value={searchQuery}
//           // @ts-expect-error error
//           onChange={(e) => setSearchQuery(e.target.value)}
//           expanded
//           placeholder="Search"
//         />
//       </TableToolbar>
//       <DataTable
//         rows={filteredRows}
//         headers={headers}
//         render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
//           <Table {...getTableProps()}>
//             <TableHead>
//               <TableRow>
//                 {headers.map((header) => (
//                   // @ts-expect-error error
//                   <TableHeader key={header.key} {...getHeaderProps({ header })}>
//                     {header.header}
//                   </TableHeader>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows.map((row) => (
//                 // @ts-expect-error error
//                 <TableRow key={row.name} {...getRowProps({ row })}>
//                   {row.cells.map((cell) => {
//                     // console.log("row", row);
//                     if (cell.info.header === "id") {
//                       return (
//                         <TableCell key={cell.id}>
//                           {ParseName([row.cells[1].value], "Credential").resourceId || "NA"}
//                         </TableCell>
//                       );
//                     } else {
//                       return <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>;
//                     }
//                   })}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         )}
//       />
//     </>
//   );
// };

// export default CredentialTable;

import React, { useState } from "react";
import { CredentialPage } from "@/lib/api/types";
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";

interface CredentialTableProps {
  credentialPage: CredentialPage;
}

const CredentialTable: React.FC<CredentialTableProps> = ({ credentialPage }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const headers = [
    {
      key: "id",
      header: "Id",
    },
    {
      key: "name",
      header: "Username",
    },
    {
      key: "variant",
      header: "Variant",
    },
  ];

  const filteredRows = credentialPage.credentials
    ? credentialPage.credentials
        .map((credential, index) => ({
          id: index.toString(),
          name: credential.name,
          variant: credential.variant,
          state: credential.state,
        }))
        .filter((row) => {
          const searchableText = `${row.name} ${row.variant} ${row.state}`;
          return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
        })
    : [];

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: any) => {
    switch (cell.info.header) {
      case "id":
        return ParseName([cell.value], "Credential").parentId || "NA";
      case "name":
        return ParseName([cell.value], "Credential").parentId || "NA";
      default:
        return cell.value;
    }
  };

  const handlePageChange = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <TableToolbar>
        <TableToolbarSearch
          value={searchQuery}
          // @ts-expect-error error
          onChange={(e) => setSearchQuery(e.target.value)}
          expanded
          placeholder="Search"
        />
      </TableToolbar>
      <DataTable
        rows={paginatedRows}
        headers={headers}
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                // @ts-expect-error error
                <TableRow key={row.name} {...getRowProps({ row })}>
                  {row.cells.map((cell) => {
                    if (cell.info.header === "id") {
                      return (
                        <TableCell key={cell.id}>
                          {ParseName([row.cells[1].value], "Credential").resourceId || "NA"}
                        </TableCell>
                      );
                    } else {
                      return <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>;
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[50]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={page}
      />
    </>
  );
};

export default CredentialTable;
