import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface TransferRuleFormData {
  policyName: string;
  policyDescription: string;
  asset: string;
  amountMin: string;
  amountMax: string;
  from: string;
  to: string;
  selectedIds?: string[];
  permissionType: string;
  selectedAssetIds: string[];
  selectedFromIds: string[];
  selectedUserIds: string[];
  selectedToIds: string[];
  approvals: number;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: TransferRuleFormData): Promise<void> {
  console.log("formData", formData);

  const isAnySelected = (ids: string[], allIdentifier: string): boolean => {
    return ids.includes(allIdentifier);
  };

  const isAssetAny = isAnySelected(formData.selectedAssetIds, "allAssets");
  const isFromAny = isAnySelected(formData.selectedFromIds, "allAccountsAndAddressesFrom");
  const isToAny = isAnySelected(formData.selectedToIds, "allAccountsAndAddressesTo");
  const isUserAny = isAnySelected(formData.selectedUserIds, "allRoles");

  console.log("isAssetAny", isAssetAny);
  console.log("isFromAny", isFromAny);
  console.log("isToAny", isToAny);
  console.log("isUserAny", isUserAny);

  const extractNames = (data: any[]): string[] => {
    if (Array.isArray(data) && typeof data[0] === "string") {
      if (data[0].startsWith("accounts/")) {
        return data;
      }
    }
    return data.map((item: { name: string }) => item.name);
  };

  const payload: any = {
    from: isFromAny ? "any/address" : extractNames(formData.selectedFromIds),
    to: isToAny ? "any/address" : extractNames(formData.selectedToIds),
    variant: formData.permissionType as "allow" | "require" | "deny" | undefined,
    asset: isAssetAny ? "any/asset" : formData.selectedAssetIds || [],
    notes: { description: formData.policyDescription },
    // initiate: "any/user", // !formData.selectedUserIds ? "any/user" : formData.selectedUserIds,
  };

  if (formData.approvals !== 0) {
    payload.approvals = formData.approvals;
  }

  //this is probably wrong as setting it to any/user doeant work
  if (formData.selectedUserIds && formData.selectedUserIds.length != 0) {
    console.log("formData.selectedUserIds", formData.selectedUserIds);
    payload.initiate = formData.selectedUserIds;
  }

  await httpClientInstance.createTransferRule("", payload);
}

export function useCreateTransferRule(): UseMutationResult<
  void,
  SubmitError,
  TransferRuleFormData
> {
  return useMutation({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Transfer Rule Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting Transfer Rule form:", error.message);
    },
  } as UseMutationOptions<void, SubmitError, TransferRuleFormData>);
}
