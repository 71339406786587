import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { OrganizationsData } from "../types/organisationTypes";

async function fetchOrganizations(): Promise<OrganizationsData> {
  const response = await fetch("https://admin.cordialapis.com/v1/organizations", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export function useOrganizations() {
  const queryKey = useMemo(() => ["organizations"], []);

  const { data, error, isLoading, isError } = useQuery<OrganizationsData, Error>({
    queryKey,
    queryFn: fetchOrganizations,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
