import React from "react";
import { useField, Field as FField, Form as FForm } from "formik";
import {
  NumberInput as CNumberInput,
  TextArea as CTextArea,
  TextInput as CTextInput,
  Toggle as CToggle,
  // Stack,
} from "@carbon/react";

interface CommonFieldProps {
  name: string;
  [key: string]: any;
}

interface TextInputProps extends CommonFieldProps {}

interface NumberInputProps extends CommonFieldProps {
  labelText?: string;
}

interface TextAreaProps extends CommonFieldProps {}

interface ToggleProps extends CommonFieldProps {}

interface FormProps {
  children: React.ReactNode;
  [key: string]: any;
}

interface MultiColumnRowProps {
  children: React.ReactNode;
}

function TextInput({ name, ...props }: TextInputProps) {
  const [inputProps, meta] = useField(name);
  const alwaysTouched = name.indexOf("[") > 0;
  return (
    <FField
      as={CTextInput}
      id={name}
      {...props}
      {...inputProps}
      invalid={(meta.touched || alwaysTouched) && !!meta.error}
      invalidText={meta.error}
    />
  );
}

function NumberInput({ name, labelText, ...props }: NumberInputProps) {
  const [inputProps, meta, helpers] = useField(name);
  return (
    <FField
      min={0}
      as={CNumberInput}
      id={name}
      {...props}
      {...inputProps}
      invalid={meta.touched && !!meta.error}
      invalidText={meta.error}
      label={labelText}
      value={meta.value}
      onChange={(_event: any, { value }: any) => helpers.setValue(value)}
    />
  );
}

function TextArea({ name, ...props }: TextAreaProps) {
  const [inputProps, meta] = useField(name);
  return (
    <FField
      as={CTextArea}
      id={name}
      {...props}
      {...inputProps}
      invalid={meta.touched && !!meta.error}
      invalidText={meta.error}
    />
  );
}

function Toggle({ name, ...props }: ToggleProps) {
  const [inputProps, meta, helper] = useField(name);
  return (
    <FField
      as={CToggle}
      id={name}
      {...props}
      {...inputProps}
      toggled={meta.value ? true : false}
      onToggle={(value: any) => helper.setValue(value)}
    />
  );
}

function Form({ children, ...rest }: FormProps) {
  return (
    <FForm className="cds--form" {...rest}>
      {/* <Stack gap={2}>{children}</Stack> */}

      {children}
    </FForm>
  );
}

function MultiColumnRowEmpty() {
  return <div className="cds--form-item" />;
}

function MultiColumnRow({ children }: MultiColumnRowProps) {
  return (
    <div className="form-2cols">
      {React.Children.count(children) % 2 === 0 ? (
        children
      ) : (
        <>
          {children}
          <MultiColumnRowEmpty />
        </>
      )}
    </div>
  );
}

export { Form, NumberInput, TextArea, TextInput, Toggle, MultiColumnRow, MultiColumnRowEmpty };
