import { Outlet } from "react-router-dom";
import CPage from "../../components/CPage";
import { useWebAuthnUser } from "../usersManagement/api/useUsers";
import { useCombinedAccountAndAddress } from "./hooks/useCombinedAccountAndAddress";

import AddressTable from "./components/AddressTable";

export default function AddressPage() {
  const webauthnUser = useWebAuthnUser();
  console.log("webauthnUser", webauthnUser);

  const { addressList, isLoading, error } = useCombinedAccountAndAddress();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading data.</div>;
  }

  return (
    <CPage title="Addresses" primaryAction={{ title: "Add address", url: "./create" }}>
      {addressList && <AddressTable addresses={addressList} />}

      <Outlet />
    </CPage>
  );
}
