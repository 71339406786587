// import React, { ChangeEvent, useState } from "react";
// import {
//   DataTable,
//   Table,
//   TableHead,
//   TableRow,
//   TableBody,
//   TableCell,
//   TableHeader,
//   TableContainer,
//   TableToolbar,
//   TableToolbarSearch,
//   Button,
//   Modal,
// } from "@carbon/react";
// import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
// import { TransferRule, TransferRulePage } from "@/lib/api/types";
// import { formatDate } from "../../../utils/formater";
// import { ParseName } from "../../../lib/api/name";
// import { useDeleteRule } from "../api/deleteTransferRule";
// import { useNavigate } from "react-router-dom";

// interface PolicyTableProps {
//   transferRulePage: TransferRulePage | null;
// }

// const PolicyTable: React.FC<PolicyTableProps> = ({ transferRulePage }) => {
//   const [searchQuery, setSearchQuery] = useState<string>("");
//   const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
//   const [selectedRow, setSelectedRow] = useState<TransferRule | null>(null);
//   const deleteRule = useDeleteRule();
//   const navigate = useNavigate();

//   const transferRules: TransferRule[] = transferRulePage?.["transfer-rules"] ?? [];

//   const headers = [
//     { key: "create_time", header: "Created" },
//     { key: "asset", header: "Asset" },
//     { key: "name", header: "Name" },
//     { key: "notes", header: "Description" },
//     { key: "variant", header: "Variant" },
//     { key: "from", header: "From" },
//     { key: "to", header: "To" },
//     { key: "actions", header: "Actions" },
//   ];

//   const extractDescription = (notes: any): string => {
//     if (!notes) return "";
//     if (typeof notes === "object" && notes.description) {
//       return notes.description;
//     }
//     if (typeof notes === "string") {
//       try {
//         const data = JSON.parse(notes);
//         return data.description || "";
//       } catch (error) {
//         console.error("Invalid JSON string provided:", error);
//         return notes;
//       }
//     }
//     return "";
//   };

//   const filteredRows = transferRules
//     .map((item, index) => ({
//       id: `row-${index}`,
//       ...item,
//       name: ParseName(item.name ? [item.name] : [], "TransferRule").resourceId || "NA",
//       create_time: formatDate(item.create_time ?? "") || "",
//       notes: extractDescription(item.notes),
//       actions: item,
//       approvals: item.approvals,
//     }))
//     .filter((row) => {
//       const searchableText = `${row.create_time} ${row.asset} ${row.name} ${row.from} ${row.to}`;
//       return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
//     });

//   if (!transferRulePage) {
//     return <div>Loading data, please wait...</div>;
//   }

//   const getElementType = (input: any): string => {
//     if (typeof input === "string") {
//       const parts = input.split("/");
//       return input.endsWith("/defaults") ? parts[0] + "-defaults" : parts[0];
//     }
//     if (Array.isArray(input)) {
//       const parts = input[0].split("/");
//       return parts[0] + "-isArray";
//     }
//     throw new Error("Invalid input type");
//   };

//   const processAssetString = (input: any): string | string[] => {
//     if (Array.isArray(input)) {
//       return input.map((item) => processAssetString(item)).join(", ");
//     }

//     // Ensure input is a string before performing string operations
//     if (typeof input !== "string") {
//       console.error("Expected a string or an array but got:", input);
//       return input;
//     }

//     const match = input.match(/assets\/([^,]+)/g);

//     return match ? match.map((asset) => asset.split("/")[1]) : input;
//   };

//   const processChainAdressString = (input: any) => {
//     // Function to format the address to 15 characters with '...'
//     const formatAddress = (address: string) => {
//       if (address.length <= 15) {
//         return address; // Return as is if already 15 characters or less
//       }
//       // Keep the first 6 characters, add '...', and the last 6 characters
//       return `${address.slice(0, 6)}...${address.slice(-6)}`;
//     };

//     // Check if the input is an array
//     if (Array.isArray(input)) {
//       // Map over each string in the array, split by '/' and get the last part, then format it
//       const formattedAddresses = input.map((address) => {
//         const parts = address.split("/");
//         const extractedAddress = parts[parts.length - 1]; // Extract the last part, which is the address
//         return formatAddress(extractedAddress); // Format the address
//       });

//       // Join the formatted addresses with a comma and a space
//       return formattedAddresses.join(", ");
//     }

//     // Log an error if the input is not an array
//     console.error("Expected an array but got:", input);
//     return input; // Return the input as is if it's not an array
//   };

//   const removeEndingAndUpperCase = (str: string) => {
//     if (str.endsWith("from")) {
//       return str.slice(0, -4);
//     } else if (str.endsWith("to")) {
//       return str.slice(0, -2);
//     }
//     return str;
//   };

//   const renderCellContent = (cell: any): JSX.Element => {
//     if (cell.info.header === "from" || cell.info.header === "to") {
//       const elementType = getElementType(cell.value);
//       if (elementType === "chains") {
//         return (
//           <TruncateText
//             text={cell.value.toString()}
//             maxLength={20}
//             isBlockChainExplorerEnabled={true}
//           />
//         );
//       }
//       if (elementType === "accounts") {
//         return (
//           <span title={cell.value}>
//             {ParseName([cell.value] ?? "", "Account").resourceId || "NA"}
//           </span>
//         );
//       }
//       if (elementType === "accounts-isArray") {
//         return (
//           <span title={cell.value}>
//             {removeEndingAndUpperCase(ParseName(cell.value ?? "", "Account").resourceId || "NA")}
//           </span>
//         );
//       }
//       if (elementType === "chains-isArray") {
//         const chainAssetName = processChainAdressString(cell.value);
//         // return <span title={cell.value}>{chainAssetName}</span>;
//         return (
//           <span title={cell.value}>
//             {chainAssetName}
//             {/* {ParseName([cell.value] ?? "", "Asset").resourceId || "NA"} */}
//           </span>
//         );
//       } else {
//         return <span title={cell.value}>{cell.value}</span>;
//       }
//     } else if (cell.info.header === "asset") {
//       const elementType = getElementType(cell.value);

//       if (elementType === "chains") {
//         return (
//           <span title={cell.value}>
//             {ParseName([cell.value] ?? "", "Asset").resourceId || "NA"}
//           </span>
//         );
//       } else {
//         console.log("elementType", elementType);
//         const chainAssetName = processAssetString(cell.value);

//         return <span title={cell.value}>{chainAssetName}</span>;
//       }
//     } else if (cell.info.header === "actions") {
//       return (
//         <div>
//           <Button
//             size="sm"
//             kind="primary"
//             onClick={(e) => {
//               e.stopPropagation();
//               setSelectedRow(cell.value);
//               navigate("/policy/creator", { state: { selectedRow: cell.value } });
//             }}
//           >
//             Edit
//           </Button>
//           <Button
//             size="sm"
//             kind="danger"
//             onClick={(e) => {
//               e.stopPropagation();
//               setSelectedRow(cell.value);
//               setIsDeleteModalOpen(true);
//             }}
//           >
//             Delete
//           </Button>
//         </div>
//       );
//     } else {
//       return <span title={cell.value}>{cell.value}</span>;
//     }
//   };

//   const handleDeleteSubmit = () => {
//     if (selectedRow?.name) {
//       deleteRule.mutate(selectedRow.name);
//     }
//     setIsDeleteModalOpen(false);
//   };

//   const handleRowClick = (rowId: string) => {
//     const selectedTransferRule = filteredRows.find((row) => row.id === rowId);
//     if (selectedTransferRule) {
//       navigate(`/policy/${selectedTransferRule.actions.name}`);
//     }
//   };

//   return (
//     <>
//       <TableContainer>
//         <TableToolbar>
//           <TableToolbarSearch
//             value={searchQuery}
//             //@ts-expect-error  exist
//             onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
//             expanded
//             placeholder="Search"
//           />
//         </TableToolbar>
//         <DataTable
//           size="md"
//           rows={filteredRows}
//           headers={headers}
//           isSortable
//           render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
//             <Table {...getTableProps()}>
//               <TableHead>
//                 <TableRow>
//                   {headers.map((header) => (
//                     //@ts-expect-error  exist
//                     <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
//                       {header.header}
//                     </TableHeader>
//                   ))}
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {rows.map((row) => (
//                   <TableRow
//                     //@ts-expect-error  exist
//                     key={row.id}
//                     {...getRowProps({ row })}
//                     onClick={() => handleRowClick(row.id)}
//                     style={{ cursor: "pointer" }}
//                   >
//                     {row.cells.map((cell) => (
//                       <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
//                     ))}
//                   </TableRow>
//                 ))}
//               </TableBody>
//             </Table>
//           )}
//         />
//       </TableContainer>
//       {isDeleteModalOpen && (
//         <Modal
//           open={isDeleteModalOpen}
//           onRequestClose={() => setIsDeleteModalOpen(false)}
//           onRequestSubmit={handleDeleteSubmit}
//           modalHeading="Delete Transfer Rule"
//           primaryButtonText="Delete"
//           secondaryButtonText="Cancel"
//         ></Modal>
//       )}
//     </>
//   );
// };

// export default PolicyTable;

import React, { ChangeEvent, useState } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Button,
  Modal,
  Pagination, // Import Pagination component
} from "@carbon/react";
import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import { TransferRule, TransferRulePage } from "@/lib/api/types";
import { formatDate } from "../../../utils/formater";
import { ParseName } from "../../../lib/api/name";
import { useDeleteRule } from "../api/deleteTransferRule";
import { useNavigate } from "react-router-dom";

interface PolicyTableProps {
  transferRulePage: TransferRulePage | null;
}

const PolicyTable: React.FC<PolicyTableProps> = ({ transferRulePage }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<TransferRule | null>(null);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const deleteRule = useDeleteRule();
  const navigate = useNavigate();

  const transferRules: TransferRule[] = transferRulePage?.["transfer-rules"] ?? [];

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "asset", header: "Asset" },
    { key: "name", header: "Name" },
    { key: "notes", header: "Description" },
    { key: "variant", header: "Variant" },
    { key: "from", header: "From" },
    { key: "to", header: "To" },
    { key: "actions", header: "Actions" },
  ];

  const extractDescription = (notes: any): string => {
    if (!notes) return "";
    if (typeof notes === "object" && notes.description) {
      return notes.description;
    }
    if (typeof notes === "string") {
      try {
        const data = JSON.parse(notes);
        return data.description || "";
      } catch (error) {
        console.error("Invalid JSON string provided:", error);
        return notes;
      }
    }
    return "";
  };

  const filteredRows = transferRules
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      name: ParseName(item.name ? [item.name] : [], "TransferRule").resourceId || "NA",
      create_time: formatDate(item.create_time ?? "") || "",
      notes: extractDescription(item.notes),
      actions: item,
      approvals: item.approvals,
    }))
    .filter((row) => {
      const searchableText = `${row.create_time} ${row.asset} ${row.name} ${row.from} ${row.to}`;
      return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
    });

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  if (!transferRulePage) {
    return <div>Loading data, please wait...</div>;
  }

  const getElementType = (input: any): string => {
    if (typeof input === "string") {
      const parts = input.split("/");
      return input.endsWith("/defaults") ? parts[0] + "-defaults" : parts[0];
    }
    if (Array.isArray(input)) {
      const parts = input[0].split("/");
      return parts[0] + "-isArray";
    }
    throw new Error("Invalid input type");
  };

  const processAssetString = (input: any): string | string[] => {
    if (Array.isArray(input)) {
      return input.map((item) => processAssetString(item)).join(", ");
    }

    if (typeof input !== "string") {
      console.error("Expected a string or an array but got:", input);
      return input;
    }

    const match = input.match(/assets\/([^,]+)/g);

    return match ? match.map((asset) => asset.split("/")[1]) : input;
  };

  const processChainAdressString = (input: any) => {
    const formatAddress = (address: string) => {
      if (address.length <= 15) {
        return address;
      }
      return `${address.slice(0, 6)}...${address.slice(-6)}`;
    };

    if (Array.isArray(input)) {
      const formattedAddresses = input.map((address) => {
        const parts = address.split("/");
        const extractedAddress = parts[parts.length - 1];
        return formatAddress(extractedAddress);
      });

      return formattedAddresses.join(", ");
    }

    console.error("Expected an array but got:", input);
    return input;
  };

  const removeEndingAndUpperCase = (str: string) => {
    if (str.endsWith("from")) {
      return str.slice(0, -4);
    } else if (str.endsWith("to")) {
      return str.slice(0, -2);
    }
    return str;
  };

  const renderCellContent = (cell: any): JSX.Element => {
    if (cell.info.header === "from" || cell.info.header === "to") {
      const elementType = getElementType(cell.value);
      if (elementType === "chains") {
        return (
          <TruncateText
            text={cell.value.toString()}
            maxLength={20}
            isBlockChainExplorerEnabled={true}
          />
        );
      }
      if (elementType === "accounts") {
        return (
          <span title={cell.value}>
            {ParseName([cell.value] ?? "", "Account").resourceId || "NA"}
          </span>
        );
      }
      if (elementType === "accounts-isArray") {
        return (
          <span title={cell.value}>
            {removeEndingAndUpperCase(ParseName(cell.value ?? "", "Account").resourceId || "NA")}
          </span>
        );
      }
      if (elementType === "chains-isArray") {
        const chainAssetName = processChainAdressString(cell.value);
        return <span title={cell.value}>{chainAssetName}</span>;
      } else {
        return <span title={cell.value}>{cell.value}</span>;
      }
    } else if (cell.info.header === "asset") {
      const elementType = getElementType(cell.value);

      if (elementType === "chains") {
        return (
          <span title={cell.value}>
            {ParseName([cell.value] ?? "", "Asset").resourceId || "NA"}
          </span>
        );
      } else {
        console.log("elementType", elementType);
        const chainAssetName = processAssetString(cell.value);

        return <span title={cell.value}>{chainAssetName}</span>;
      }
    } else if (cell.info.header === "actions") {
      return (
        <div>
          <Button
            size="sm"
            kind="primary"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRow(cell.value);
              navigate("/policy/creator", { state: { selectedRow: cell.value } });
            }}
          >
            Edit
          </Button>
          <Button
            size="sm"
            kind="danger"
            onClick={(e) => {
              e.stopPropagation();
              setSelectedRow(cell.value);
              setIsDeleteModalOpen(true);
            }}
          >
            Delete
          </Button>
        </div>
      );
    } else {
      return <span title={cell.value}>{cell.value}</span>;
    }
  };

  const handleDeleteSubmit = () => {
    if (selectedRow?.name) {
      deleteRule.mutate(selectedRow.name);
    }
    setIsDeleteModalOpen(false);
  };

  const handleRowClick = (rowId: string) => {
    const selectedTransferRule = filteredRows.find((row) => row.id === rowId);
    if (selectedTransferRule) {
      navigate(`/policy/${selectedTransferRule.actions.name}`);
    }
  };

  // Pagination handler
  const handlePageChange = ({ page, pageSize }: { page: number; pageSize: number }) => {
    setPage(page);
    setPageSize(pageSize);
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            //@ts-expect-error exist
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={paginatedRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    //@ts-expect-error exist
                    <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    //@ts-expect-error exist
                    key={row.id}
                    {...getRowProps({ row })}
                    onClick={() => handleRowClick(row.id)}
                    style={{ cursor: "pointer" }}
                  >
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
      </TableContainer>
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 20, 50]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={page}
      />
      {isDeleteModalOpen && (
        <Modal
          open={isDeleteModalOpen}
          onRequestClose={() => setIsDeleteModalOpen(false)}
          onRequestSubmit={handleDeleteSubmit}
          modalHeading="Delete Transfer Rule"
          primaryButtonText="Delete"
          secondaryButtonText="Cancel"
        ></Modal>
      )}
    </>
  );
};

export default PolicyTable;
