// import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import createRoutes from "./routes/routes";

import { Notifications } from "./components/Notifications";
import useCachedState from "./hooks/useCachedState";
import { useEffect } from "react";
import { KEY_API, KEY_USER } from "./lib/constants";
import { useSession } from "@clerk/clerk-react";
import useAccessToken from "./hooks/useAccessToken";

const queryClient = new QueryClient();

export default function App() {
  const { session } = useSession();
  useAccessToken(session);

  const [cachedApiURL, setCachedApiURL] = useCachedState<string>(KEY_API, "");
  const [cachedUser, setCachedUser] = useCachedState<string>(KEY_USER, "");
  useEffect(() => {
    // Here we provide additional behavior if the api/user query params are set.
    const queryParams = new URLSearchParams(window.location.search);
    const newApiMaybe = queryParams.get(KEY_API);
    const newUserMaybe = queryParams.get(KEY_USER);
    let refresh = false;
    if (newApiMaybe) {
      if (cachedApiURL != newApiMaybe) {
        console.log(`updating api '${cachedApiURL}' to '${newApiMaybe}'`);
        setCachedApiURL(newApiMaybe);
        refresh = true;
      }
    }
    if (newUserMaybe) {
      if (cachedUser != newUserMaybe) {
        console.log(`updating api '${cachedUser}' to '${newUserMaybe}'`);
        setCachedUser(newUserMaybe);
        refresh = true;
      }
    }
    if (refresh) {
      console.log("Refreshing due to variable change.");
      window.location.reload();
    }
  }, [cachedApiURL, setCachedApiURL, cachedUser, setCachedUser]);

  const router = createBrowserRouter(createRoutes());

  return (
    <QueryClientProvider client={queryClient}>
      <Notifications />
      <RouterProvider router={router} />
      {/* <ReactQueryDevtools /> */}
      {/* </SignedIn> */}
    </QueryClientProvider>
  );
}
