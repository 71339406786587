import React, { useEffect, useState } from "react";
import { Popover, PopoverContent, Button } from "@carbon/react";
import { useWebAuthnUser } from "../features/usersManagement/api/useUsers";
import { httpClientInstance } from "../clientInstance";
import { useCredential } from "../features/usersManagement/api/getCredential";
import { useClerk, useUser as useClerkUser } from "@clerk/clerk-react";
import { useNavigate } from "react-router-dom";
import { Credential } from "@/lib/api/types";
import { KEY_API, KEY_ORGANISATION, KEY_SESSION, KEY_TREASURY } from "lib/constants";

import useCachedState from "../hooks/useCachedState";
import useTreasury from "../features/treasuryManagement/getTreasuries";
import { ParseName } from "../lib/api/name";
import TextCopyToClipboard from "./TextCopyToClipboard";

export default function UserMenu() {
  const webauthnUser = useWebAuthnUser();
  const [open, setOpen] = useState(false);
  const { data: treasuryPage } = useTreasury();
  const [cachedApiURL] = useCachedState<string>(KEY_API, "");
  const [cachedTreasury] = useCachedState<string>(KEY_ORGANISATION, "");
  const [cachedOrganisation] = useCachedState<string>(KEY_TREASURY, "");

  const sessionData = sessionStorage.getItem("session");
  const sessionObj: Credential | undefined = sessionData ? JSON.parse(sessionData) : undefined;
  const [renderTrigger, setRenderTrigger] = useState<boolean>(false);

  useEffect(() => {}, [renderTrigger]);

  const sessionName = sessionObj?.name || "";

  const credential = useCredential(sessionName);
  const clerk = useClerk();
  const user = useClerkUser();
  const navigate = useNavigate();

  console.log("treasuries:", treasuryPage?.treasuries);

  const handleLogout = async () => {
    localStorage.clear();
    sessionStorage.removeItem(KEY_SESSION);
    await clerk.signOut();
    navigate("/sign-in");
    console.log("Logged out");
  };

  const handleSessionLogout = async () => {
    sessionStorage.removeItem(KEY_SESSION);
    httpClientInstance.closeSession();
    setRenderTrigger((prev) => !prev);
  };

  const switchOrgAndTreasury = () => {
    localStorage.clear();
    sessionStorage.removeItem(KEY_SESSION);
    navigate("/selectEndpoint");
  };

  const handleLogin = async () => {
    await httpClientInstance.registerSession();
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" || event.key === " ") {
      setOpen(!open);
    }
  };

  return (
    <Popover open={open}>
      <div
        className="circular-image-container"
        role="button"
        tabIndex={0}
        onClick={() => setOpen(!open)}
        onKeyDown={handleKeyDown}
        aria-pressed={open}
      >
        <img
          src={user?.user?.imageUrl}
          alt="User"
          className="circular-image"
          style={{ zIndex: 20 }}
        />
        <span
          className={`connection-pill ${credential?.name ? "connected" : "disconnected"}`}
        ></span>
      </div>

      <PopoverContent
        style={{
          transform: "translateY(-13px)",
          position: "absolute",
          zIndex: 1000,
        }}
      >
        <div style={{ padding: "1rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div className="circular-image-container">
              <img src={user?.user?.imageUrl} alt="User" className="circular-image" />
            </div>
            <div style={{ padding: "1rem", minWidth: "230px" }}>
              <p style={{ fontSize: "16px", fontWeight: "bold" }}>
                {`${user?.user?.firstName} ${user?.user?.lastName}`}
              </p>
              <span style={{ fontSize: "14px" }}>{webauthnUser?.username ?? "No username"}</span>
            </div>
          </div>

          <div style={{ paddingLeft: "20px", paddingBottom: "30px" }}>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Organization:</p>
            <p style={{ fontSize: "12px", marginTop: 10, marginBottom: 10 }}>
              {cachedOrganisation}
            </p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Treasury:</p>
            <p style={{ fontSize: "12px", marginTop: 10, marginBottom: 10 }}>{cachedTreasury}</p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Treasury ID:</p>

            {treasuryPage?.treasuries?.map((treasury) => (
              <ul key={treasury.name}>
                <TextCopyToClipboard
                  textToCopy={
                    (treasury.name && ParseName(treasury.name, "Treasury")?.resourceId) || ""
                  }
                />
              </ul>
            ))}
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>API URL:</p>
            <TextCopyToClipboard textToCopy={cachedApiURL} />

            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Software Version:</p>
            {treasuryPage?.treasuries?.map((treasury) => (
              <ul key={treasury.name}>
                <p style={{ fontSize: "10px", paddingTop: "10px" }}>{treasury.software || ""}</p>
              </ul>
            ))}
          </div>

          <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Button
              kind="secondary"
              size="sm"
              onClick={switchOrgAndTreasury}
              style={{ width: "190px", textAlign: "center" }}
            >
              Switch Treasury
            </Button>
            {credential?.name ? (
              <Button
                kind="secondary"
                size="sm"
                onClick={handleSessionLogout}
                style={{ width: "190px", textAlign: "center" }}
              >
                End Session
              </Button>
            ) : (
              <Button
                kind="primary"
                size="sm"
                onClick={handleLogin}
                style={{ width: "190px", textAlign: "center" }}
              >
                Begin Session
              </Button>
            )}
            <Button
              kind="secondary"
              size="sm"
              onClick={handleLogout}
              style={{ width: "190px", textAlign: "center" }}
            >
              Sign Out
            </Button>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  );
}
