import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";

import { ParseName } from "../../../lib/api/name";
import { Transfer, AddressVariant, BasicState } from "@/lib/api/types";
import { formatDate } from "../../../utils/formater";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";
import { DirectionCellRenderer } from "../../../components/itemRenderers/DirectionCellRenderer";
import TruncateTextPropWithToolTip from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import TruncateText from "../../../components/itemRenderers/TruncateText";
import { useTransfersPage } from "../api/getTransfersPage";

export interface AddressListItem {
  account?: string;
  name?: string;
  variant?: AddressVariant;
  state?: BasicState;
  create_time?: string;
}

const TransferTable: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [pageToken, setPageToken] = useState<string | undefined>(undefined);
  const navigate = useNavigate();

  const {
    data: transferPage,
    isLoading,
    error,
  } = useTransfersPage({
    pageSize,
    pageToken,
  });

  console.log("transferPage", transferPage);

  useEffect(() => {
    if (error) {
      console.error("Error fetching transfers:", error);
    }
  }, [error]);

  const addresses: Transfer[] = transferPage?.transfers ?? [];
  const nextPageToken = transferPage?.next_page_token;

  const headers = [
    { key: "state", header: "State" },
    { key: "name", header: "Id" },
    { key: "create_time", header: "Created" },
    { key: "chain", header: "Chain" },
    { key: "asset", header: "Asset" },
    { key: "direction", header: "Direction" },
    { key: "amount", header: "Amount" },
    { key: "from", header: "From" },
    { key: "to", header: "To" },
  ];

  const filteredRows = addresses
    .map((item: Transfer, index: number) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time ?? "") || "NA",
      asset: ParseName([item.asset ?? ""], "Asset").resourceId || "NA",
      name: ParseName([item.name ?? ""], "Transfer").resourceId || "NA",
    }))
    .filter((row: any) => {
      const searchableText = `${row.state} ${row.id} ${row.chain} ${row.amount} ${row.create_time} ${row.asset} ${row.name} ${row.from} ${row.to}`;
      return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .sort((a: any, b: any) => {
      if (sortDirection === "DESC") {
        return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
      } else {
        return new Date(a.create_time).getTime() - new Date(b.create_time).getTime();
      }
    });

  const handleSort = (columnName: string) => {
    if (columnName === "create_time") {
      setSortDirection((currentDirection) => (currentDirection === "ASC" ? "DESC" : "ASC"));
    }
  };

  const handlePageChange = (event: { page: number; pageSize: number }) => {
    setPage(event.page);
    setPageSize(event.pageSize);
    setPageToken(nextPageToken);
  };

  const handleRowClick = (row: any) => {
    const id = row.cells.find((cell: any) => cell.info.header === "name")?.value;
    if (id) {
      navigate(`/transfers/${id}`);
    }
  };

  if (isLoading) {
    return <div>Loading data, please wait...</div>;
  }

  const renderCellContent = (cell: any, row: any): JSX.Element => {
    console.log("cell", cell);
    console.log("row", row);
    if (cell.info.header === "from" || cell.info.header === "to") {
      return (
        <TruncateTextPropWithToolTip
          text={cell.value.toString()}
          maxLength={30}
          isBlockChainExplorerEnabled={false}
        />
      );
    } else if (cell.info.header === "asset") {
      return <TruncateText text={cell.value} maxLength={30} /> || <></>;
    } else if (cell.info.header === "direction") {
      return <DirectionCellRenderer direction={cell.value} /> || <></>;
    } else if (cell.info.header === "chain") {
      return (
        (
          <IconCellRenderer
            value={ParseName([row.cells[8].value ?? ""], "Address").parentId || ""}
          />
        ) || <></>
      );
    } else {
      return <span>{cell.value}</span>;
    }
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>
      <DataTable
        size="md"
        rows={filteredRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  //@ts-expect-error error
                  <TableHeader
                    //@ts-expect-error error
                    key={header.key}
                    {...getHeaderProps({
                      header,
                      isSortable: true,
                      onClick: () => handleSort(header.key),
                    })}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  //@ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell, row)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 50, 100]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={page}
      />
    </>
  );
};

export default TransferTable;
