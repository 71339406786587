import React, { useState, useEffect, ReactElement } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";

import { ParseName } from "../../../lib/api/name";
import { AddressVariant, BasicState } from "../../../lib/api/types";
import TruncateText from "../../../components/itemRenderers/TruncateTextPropWithTooTip";
import { formatDate } from "../../../utils/formater";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";

export interface AddressListItem {
  account?: string;
  name?: string;
  variant?: AddressVariant;
  state?: BasicState;
  create_time?: string;
  notes?: string | { description?: string };
}

interface AddressTableProps {
  addresses: AddressListItem[];
}

const AddressTable: React.FC<AddressTableProps> = ({ addresses }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalItems, setTotalItems] = useState(0);
  const navigate = useNavigate();

  const headers = [
    { key: "create_time", header: "Created" },
    { key: "account", header: "Account" },
    { key: "chain", header: "Chain" },
    { key: "name", header: "Name" },
    { key: "notes", header: "Description" },
    { key: "variant", header: "Variant" },
  ];

  const extractDescription = (jsonString: string): string | undefined => {
    try {
      const data = JSON.parse(jsonString);
      return data.description;
    } catch (error) {
      console.error("Invalid JSON string provided:", error);
      return undefined;
    }
  };

  const filteredRows = addresses
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      create_time: formatDate(item.create_time ?? "") || "",
      notes:
        item.notes && typeof item.notes === "object" && item.notes.description
          ? item.notes.description
          : extractDescription(item?.notes?.toString() ?? "") || "",
    }))
    .filter((row) => {
      return Object.values(row).some((value) =>
        value?.toString().toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }) as { [key: string]: any }[];

  useEffect(() => {
    setTotalItems(filteredRows.length);
  }, [filteredRows]);

  const paginatedRows = filteredRows.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const handlePageChange = ({
    page,
    pageSize: newPageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    if (newPageSize !== pageSize) {
      setPageSize(newPageSize);
      setCurrentPage(1);
    } else {
      setCurrentPage(page);
    }
  };

  const getFirstElement = (input: any): string => {
    if (typeof input !== "string") return "";
    const parts = input.split("/");
    return parts[0];
  };

  const renderCellContent = (cell: any, row: any): any => {
    const rendererType = getFirstElement(cell.value);
    switch (cell.info.header) {
      case "name":
        return (
          <TruncateText
            text={cell.value.toString()}
            maxLength={30}
            isBlockChainExplorerEnabled={true}
          />
        );
      case "account":
        if (rendererType === "accounts") {
          return <>{ParseName([cell.value ?? ""], "Account").resourceId || ""}</>;
        }
        return <>{cell.value}</>;
      case "chain":
        return (
          (IconCellRenderer({
            value: ParseName([row.cells[3].value ?? ""], "Address").parentId || "",
          }) as ReactElement) || <></>
        );
      default:
        return typeof cell.value === "object" ? JSON.stringify(cell.value) : cell.value;
    }
  };

  const handleRowClick = (rowId: string) => {
    navigate(`/address/${rowId}`);
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setSearchQuery(e.target.value);
            }}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>
      <DataTable
        size="md"
        // @ts-expect-error error
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  // @ts-expect-error error
                  <TableHeader key={header.key} {...getHeaderProps({ header, isSortable: true })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  // @ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row.cells[3].value)}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell, row)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={totalItems}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 50, 100]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={currentPage}
      />
    </>
  );
};

export default AddressTable;
