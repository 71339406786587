import { NameError } from "./errors";
import { GetTypeMeta, ResourceVariant } from "./types";
import { normalize } from "./util";

export type Name = string | [] | [string] | [string, string];
type NameArray = [] | [string] | [string, string];
interface ParsedName {
  name: string;
  resourceId: string;
  parentId?: string;
}

export const NormalizeName = normalize;

const trimSlashes = (name: string): string => {
  while (name.length && name[0] == "/") {
    name = name.slice(1);
  }
  while (name.length && name[name.length - 1] == "/") {
    name = name.slice(0, name.length - 1);
  }
  return name;
};

export const ensurePrefix = (name: string, prefix: string): string => {
  if (name.indexOf(prefix) == 0) {
    return name;
  }
  return prefix + name;
};

const toArray = (input: Name): NameArray => {
  let names: NameArray = [];
  if (typeof input == "string") {
    names = [input];
  } else {
    names = input as NameArray;
  }
  return names;
};

export const ParseName = (namesInput: Name, resourceType: ResourceVariant): ParsedName => {
  const meta = GetTypeMeta(resourceType);
  const hasParent = meta.parentField != undefined;
  if (namesInput.length == 0) {
    throw new NameError("Missing name input");
  }
  let names = toArray(namesInput);
  if (names.length == 0) {
    throw new NameError("Missing name elements");
  }
  if (!hasParent) {
    // simple case
    let name = trimSlashes(names[0]);
    name = ensurePrefix(name, meta.resourceField + "/");
    const parts = name.split("/");
    if (parts.length != 2) {
      throw new NameError(
        `Name "${names}" has illegal / or incorrect prefix.  Expected no prefix or ${meta.resourceField}/.`,
      );
    }

    const resourceId = normalize(parts[1]);
    return {
      resourceId: resourceId,
      name: `${meta.resourceField}/${resourceId}`,
    };
  } else {
    if (names.length > 1) {
      const resourceName = names[1]!;
      if (resourceName.split("/").length > 3) {
        // resource name is already complete, so ignore parent.
        names = [resourceName];
      } else {
        const parentName = names[0]!;
        if (parentName.split("/").length > 3) {
          names = [parentName];
        }
      }
    }

    if (names.length == 1) {
      // expect full name
      const name = trimSlashes(names[0]);
      const parts = name.split("/");
      if (parts.length != 4) {
        if (parts.length == 3) {
          // empty resourceId
          parts.push("");
        } else {
          throw new NameError(
            `With name passed as "${names}", expected the path to have 4 elements, got ${parts.length}.`,
          );
        }
      }
      if (parts[0] != meta.parentField) {
        throw new NameError(
          `With name passed as "${names}", expected the parent element to be ${meta.parentField}`,
        );
      }
      if (parts[2] != meta.resourceField) {
        throw new NameError(
          `With name passed as "${names}", expected the resource element to be ${meta.resourceField}`,
        );
      }
      const parentId = normalize(parts[1]);
      const resourceId = normalize(parts[3]);
      return {
        name: `${meta.parentField}/${parentId}/${meta.resourceField}/${resourceId}`,
        parentId: parentId,
        resourceId: resourceId,
      };
    } else {
      let parent = trimSlashes(names[0]);
      let resource = trimSlashes(names[1]);
      parent = ensurePrefix(parent, meta.parentField + "/");
      resource = ensurePrefix(resource, meta.resourceField + "/");
      const parentParts = parent.split("/");
      const resourceParts = resource.split("/");

      if (parentParts.length != 2 || parentParts[0] != meta.parentField) {
        throw new NameError(
          `Expected parent in "${names}" to have ${meta.parentField}/ prefix, no prefix, or full path`,
        );
      }
      if (resourceParts.length != 2 || resourceParts[0] != meta.resourceField) {
        throw new NameError(
          `Expected child in "${names}" to have ${meta.resourceField}/ prefix, no prefix, or full path`,
        );
      }

      const parentField = parentParts[0];
      const parentId = normalize(parentParts[1]);
      const resourceField = resourceParts[0];
      const resourceId = normalize(resourceParts[1]);

      return {
        name: `${parentField}/${parentId}/${resourceField}/${resourceId}`,
        parentId: parentId,
        resourceId: resourceId,
      };
    }
  }
};

// Drop the resourceId in the name if it exists.  Used for POST requests.
export const ParseNameDropResourceId = (input: Name, resourceType: ResourceVariant): ParsedName => {
  const typeMeta = GetTypeMeta(resourceType);
  let names = toArray(input);
  // special case for POST to root resources
  if (names.length == 0) {
    if (typeMeta.parentField != undefined) {
      // e.g. POST /users/x/credentials
      return {
        name: typeMeta.parentField,
        resourceId: "",
        parentId: typeMeta.parentField,
      };
    } else {
      // e.g. POST /transfer-rules
      return {
        name: typeMeta.resourceField,
        resourceId: "",
      };
    }
  }

  names = names.slice() as NameArray;
  if (names.length == 1 && typeMeta.parentField != undefined) {
    // special case resources with parent
    let parent = trimSlashes(names[0]);
    if (!parent.endsWith("/" + typeMeta.resourceField)) {
      if (!parent.startsWith(typeMeta.parentField)) {
        parent = typeMeta.parentField + "/" + parent;
      }
      parent = parent + "/" + typeMeta.resourceField;
      names[0] = parent;
    }
  }

  const parsedName = ParseName(names, resourceType);
  if (parsedName.resourceId.length > 0) {
    const parts = parsedName.name.split("/");
    parsedName.name = parts.slice(0, parts.length - 1).join("/");
  }
  parsedName.name = trimSlashes(parsedName.name);
  return parsedName;
};
