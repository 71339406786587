import { Modal } from "@carbon/react";
import { createContext, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ModalRefContextType {
  ref: React.MutableRefObject<HTMLButtonElement | null>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  notif: { success: string; error: string };
  setNotif: React.Dispatch<React.SetStateAction<{ success: string; error: string }>>;
}

const ModalRefContext = createContext<ModalRefContextType | null>(null);

export function useModalCtx() {
  const context = useContext(ModalRefContext);
  if (!context) {
    throw new Error("useModalCtx must be used within a ModalRefContext.Provider");
  }
  return context;
}

export function useModalSetters() {
  const { setLoading, setNotif } = useModalCtx();
  return { setLoading, setNotif };
}

interface CModalProps {
  title: string;
  primaryButtonText?: string;
  returnUrl?: string;
  passiveModal?: boolean;
  children: React.ReactNode;
  onClose?: () => void;
}

export default function CModal({
  title,
  primaryButtonText = "Add",
  returnUrl = "",
  passiveModal = false,
  children,
  onClose,
}: CModalProps) {
  const navigate = useNavigate();
  const modalRef = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState(false);
  const [notif, setNotif] = useState({ success: "", error: "" });

  return (
    <Modal
      modalHeading={title}
      primaryButtonText={primaryButtonText}
      primaryButtonDisabled={loading}
      onRequestSubmit={() => {
        modalRef.current?.click();
      }}
      secondaryButtonText="Cancel"
      open
      size="lg"
      onRequestClose={() => (onClose ? onClose() : navigate(returnUrl))}
      passiveModal={passiveModal}
    >
      <ModalRefContext.Provider value={{ ref: modalRef, loading, setLoading, notif, setNotif }}>
        {children}
      </ModalRefContext.Provider>
    </Modal>
  );
}
