import React from "react";
import { Link } from "react-router-dom";
import { Column, Grid } from "@carbon/react";

import CButton from "./CButton";
import { Add } from "../lib/icons";

type ButtonAction = {
  title: string;
  url: string;
};

export default function CPage({
  title,
  primaryAction,
  secondaryActions,
  children,
}: {
  title: string;
  primaryAction?: ButtonAction;
  secondaryActions?: ButtonAction[];
  children?: React.ReactNode;
}) {
  return (
    <>
      <Grid fullWidth>
        <Column sm={4} md={2} lg={4} padding={0}>
          <h3>{title}</h3>
        </Column>
        <Column sm={4} md={6} lg={12} style={{ textAlign: "right" }}>
          {(secondaryActions || []).map((action, i) => (
            <CButton key={i} kind="ghost" as={Link} to={action.url} size="sm">
              {action.title}
            </CButton>
          ))}
          {primaryAction ? (
            <CButton key="p" renderIcon={Add} as={Link} to={primaryAction.url} size="sm">
              {primaryAction.title}
            </CButton>
          ) : null}
        </Column>
        <p>&nbsp;</p>
        <Column sm={4} md={8} lg={16}>
          {children}
        </Column>
      </Grid>
      <p>&nbsp;</p>
    </>
  );
}
