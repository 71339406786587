import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";

export interface UpdateTransferRuleFormData {
  policyName: string;
  policyDescription: string;
  asset: string;
  amountMin: string;
  amountMax: string;
  from: string;
  to: string;
  selectedIds?: string[];
  permissionType: string;
  selectedAssetIds: string[];
  selectedFromIds: string[];
  selectedUserIds: string[];
  selectedToIds: string[];
  initialObject: any;
  approvals: number;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: UpdateTransferRuleFormData): Promise<void> {
  console.log("form data rule", formData);
  const isAssetAny = formData.selectedAssetIds.includes("allAssets");
  const isFromAny = formData.selectedFromIds.includes("allAccountsAndAddressesFrom");
  const isToAny = formData.selectedToIds.includes("allAccountsAndAddressesTo");
  //const isUserAny = formData.selectedUserIds.includes("allRoles");

  await httpClientInstance.updateTransferRule(formData.policyName, {
    ...formData.initialObject,
    asset: isAssetAny ? "any/asset" : formData.selectedAssetIds || [],
    from: isFromAny ? "any/address" : formData.selectedFromIds,
    to: isToAny ? "any/address" : formData.selectedToIds,
    variant: formData.permissionType as "allow" | "require" | "deny" | undefined,
    initiate:
      formData.selectedUserIds == null || formData.selectedUserIds.length === 0
        ? undefined
        : formData.selectedUserIds,
    approvals:
      formData.selectedUserIds == null || formData.selectedUserIds.length === 0
        ? 0
        : formData.approvals,
    notes: { description: formData.policyDescription },
  });
}

export function useUpdateTransferRule(): UseMutationResult<
  void,
  SubmitError,
  UpdateTransferRuleFormData
> {
  return useMutation<void, SubmitError, UpdateTransferRuleFormData>({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Transfer Rule Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting Transfer Rule form:", error.message);
    },
  });
}
