import React from "react";
import { Button } from "@carbon/react";
import { Copy } from "@carbon/react/icons";
import useDarkMode from "use-dark-mode";

interface TextCopyToClipboardProps {
  textToCopy: string;
}

const TextCopyToClipboard: React.FC<TextCopyToClipboardProps> = ({ textToCopy }) => {
  const darkMode = useDarkMode(false, {
    classNameDark: "cds--g90",
    classNameLight: "cds--g10",
  });

  const iconColor = darkMode.value ? "#000000" : "#FFFFFF";

  const handleCopy = () => {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => console.log("Text copied to clipboard"))
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        margin: "0px",
        padding: 0,
        flexWrap: "wrap",
      }}
    >
      <p
        style={{
          fontSize: "10px",
          maxWidth: "150px",
          margin: 0,
          padding: 0,
          wordWrap: "break-word",
        }}
      >
        {textToCopy}
      </p>
      <Button
        hasIconOnly
        renderIcon={() => <Copy size={12} style={{ fill: iconColor, margin: 0, padding: 0 }} />}
        iconDescription="Copy"
        onClick={handleCopy}
        kind="ghost"
        size="sm"
      />
    </div>
  );
};

export default TextCopyToClipboard;
