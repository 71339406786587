import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Button,
  StructuredListWrapper,
  StructuredListHead,
  StructuredListRow,
  StructuredListCell,
  StructuredListBody,
  Modal,
} from "@carbon/react";
import AccountDetailAddressTable from "./components/AccountDetailAddressTable";
import { useAccountPage } from "./api/getAccounts";
import { useDeleteAccount } from "./api/deleteAccount";
import { useNotificationStore } from "../../stores/notifications";
import AccountEditForm from "./components/AccountEditForm";
import CPage from "../../components/CPage";
import "./AccountDetail.css";
import { formatDate } from "../../utils/formater";

const AccountDetail: React.FC = () => {
  const { accountId } = useParams<{ accountId: string }>() ?? { accountId: "" };
  const { addNotification } = useNotificationStore();
  const navigate = useNavigate();

  const {
    data: accountPage,
    isLoading: isLoadingAccounts,
    error: errorAccounts,
  } = useAccountPage({ filter: `name="accounts/${accountId}"` });

  const { mutate: deleteAccount, isPaused: isDeleting } = useDeleteAccount();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const handleDelete = () => {
    deleteAccount(
      { name: `accounts/${accountId}` },
      {
        onSuccess: () => {
          navigate("/accounts");
          addNotification({
            type: "success",
            title: "Account deleted successfully",
          });
        },
        onError: (error) => {
          addNotification({
            type: "error",
            title: "Error deleting account",
            message: error.message,
          });
          console.error("Error deleting account:", error.message);
        },
      },
    );
  };

  return (
    <CPage title="Account Details">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Button size="sm" onClick={() => navigate("/accounts")}>
          Back to Accounts
        </Button>
        <div style={{ display: "flex", gap: "1rem" }}>
          <Button size="sm" onClick={() => setIsEditModalOpen(true)}>
            Edit
          </Button>
          <Button size="sm" kind="danger" onClick={() => setIsModalOpen(true)}>
            Delete
          </Button>
        </div>
      </div>

      <div>{isLoadingAccounts ? "Loading..." : ""}</div>
      <div>{errorAccounts ? "Error loading accounts" : ""}</div>

      {!isLoadingAccounts && !errorAccounts && (
        <StructuredListWrapper>
          <StructuredListHead></StructuredListHead>
          <StructuredListBody>
            <StructuredListRow>
              <StructuredListCell head>Account Name</StructuredListCell>
              <StructuredListCell>{accountId}</StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell head>Account Create Time</StructuredListCell>
              <StructuredListCell>
                {formatDate(accountPage?.accounts?.[0]?.create_time ?? "")}
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell head>Account Update Time</StructuredListCell>
              <StructuredListCell>
                {formatDate(accountPage?.accounts?.[0]?.update_time ?? "")}
              </StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell head>Account Type</StructuredListCell>
              <StructuredListCell>{accountPage?.accounts?.[0]?.variant || ""}</StructuredListCell>
            </StructuredListRow>
            <StructuredListRow>
              <StructuredListCell head>Account Description</StructuredListCell>
              <StructuredListCell>
                {accountPage?.accounts?.[0].notes?.description || ""}
              </StructuredListCell>
            </StructuredListRow>
          </StructuredListBody>
        </StructuredListWrapper>
      )}

      <div>
        <h4 style={{ paddingTop: "40px", paddingBottom: "10px" }}>Account Addresses</h4>
        <AccountDetailAddressTable />
      </div>

      <Modal
        open={isModalOpen}
        modalHeading="Confirm Delete"
        primaryButtonText={isDeleting ? "Deleting..." : "Delete"}
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsModalOpen(false)}
        onRequestSubmit={handleDelete}
        danger
        primaryButtonDisabled={isDeleting}
      >
        <p>Are you sure you want to delete: {accountId}?</p>
      </Modal>

      <Modal
        open={isEditModalOpen}
        modalHeading="Edit Account"
        primaryButtonText="Save"
        secondaryButtonText="Cancel"
        onRequestClose={() => setIsEditModalOpen(false)}
        onRequestSubmit={() => {
          // Trigger form submission programmatically
          document
            .getElementById("account-edit-form")
            ?.dispatchEvent(new Event("submit", { cancelable: true, bubbles: true }));
        }}
      >
        <AccountEditForm
          accountId={accountPage?.accounts?.[0].name}
          onClose={() => setIsEditModalOpen(false)}
        />
      </Modal>
    </CPage>
  );
};

export default AccountDetail;
