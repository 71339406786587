import React from "react";
import useAvailableUsers from "../../../features/usersManagement/hooks/useAvailableUsers";
import { useUsers } from "../../../features/usersManagement/api/useUsers";
import { TreasuryUser } from "../../../features/organizationManagement/types/treasuryUserType";

interface UserRendererProps {
  userName: string;
}

const UserDisplayNameRenderer: React.FC<UserRendererProps> = ({ userName }) => {
  const availableUsers = useAvailableUsers();

  const userPage = useUsers(1, 1, `name = "${userName}"`);

  const ssoUser = userPage?.users?.[0]?.labels?.["sso-user"];

  const getSSOUser = (userId: string): TreasuryUser | undefined => {
    const user = availableUsers.find((user) => user.name === userId);
    return user ? user : undefined;
  };

  const user = getSSOUser(ssoUser || "");

  return <>{`${user?.display_name ?? userName}`}</>;
};

export default UserDisplayNameRenderer;
