import React, { ChangeEvent, useState, useEffect } from "react";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";
import useAvailableUsers from "../hooks/useAvailableUsers";
import { TreasuryUser } from "../../../features/organizationManagement/types/treasuryUserType";

import { UserPage } from "../../../lib/api/types";
import { useUsers } from "../api/useUsers";

interface UsersTableProps {
  initialUserPage: UserPage;
}

const UsersTable: React.FC<UsersTableProps> = ({ initialUserPage }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);
  const [filter, setFilter] = useState("");
  const [totalItems, setTotalItems] = useState(initialUserPage.total_size);

  const userPage = useUsers(currentPage, pageSize, filter);
  const availableUsers = useAvailableUsers();

  useEffect(() => {
    if (userPage) {
      setTotalItems(userPage.total_size);
    }
  }, [userPage]);

  const headers = [
    { key: "name", header: "Name" },
    { key: "roles", header: "Roles" },
    { key: "variant", header: "Variant" },
    { key: "clerkEmail", header: "SSO ID" },
    { key: "ssoUser", header: "SSO email" },
  ];

  const getSSOUser = (userId: string): TreasuryUser | undefined => {
    const user = availableUsers.find((user) => user.name === userId);
    return user ? user : undefined;
  };

  const filteredRows =
    userPage?.users
      ?.map((user, index) => ({
        id: `row-${index}`,
        name: ParseName([user.name ?? ""], "User").resourceId || "NA",
        roles:
          user.roles?.map((role) => ParseName([role ?? ""], "Role").resourceId).join(" ") || "NA",
        clerkEmail: user.labels ? user.labels["sso-user"] : "NA",
        ssoUser: getSSOUser(user.labels ? user.labels["sso-user"] : "")?.emails[0],
        variant: user.variant || "NA",
      }))
      .filter((row) => {
        const searchableText = `${row.name} ${row.variant} ${row.roles} ${row.clerkEmail}`;
        return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
      }) || [];

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    setFilter(e.target.value);
  };

  const handlePageChange = ({
    page,
    pageSize: newPageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  const renderCellContent = (cell: any) => {
    switch (cell.info.header) {
      case "name":
        return cell.value;
      default:
        return cell.value;
    }
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            // @ts-expect-error error
            onChange={handleSearchChange}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
        <DataTable
          size="md"
          rows={filteredRows}
          headers={headers}
          isSortable
          render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
            <Table {...getTableProps()}>
              <TableHead>
                <TableRow>
                  {headers.map((header) => (
                    // @ts-expect-error error
                    <TableHeader key={header.key} {...getHeaderProps({ header })}>
                      {header.header}
                    </TableHeader>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  // @ts-expect-error error
                  <TableRow key={row.id} {...getRowProps({ row })}>
                    {row.cells.map((cell) => (
                      <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        />
        <Pagination
          totalItems={totalItems}
          backwardText="Previous"
          forwardText="Next"
          pageSize={pageSize}
          pageSizes={[10, 20, 30, 40, 50]}
          itemsPerPageText="Items per page:"
          onChange={handlePageChange}
          page={currentPage}
        />
      </TableContainer>
    </>
  );
};

export default UsersTable;
