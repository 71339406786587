import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { TreasuryUserData } from "../types/treasuryUserType";

async function fetchTreasuryUsers(): Promise<TreasuryUserData> {
  const response = await fetch("https://admin.cordialapis.com/v1/users", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export function useTreasuryUsers() {
  const queryKey = useMemo(() => ["treasuriesUsers"], []);
  const { data, error, isLoading, isError } = useQuery<TreasuryUserData, Error>({
    queryKey,
    queryFn: fetchTreasuryUsers,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
