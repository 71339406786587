import { Outlet } from "react-router-dom";
import CPage from "../../components/CPage";

import AccountTable from "./components/AccountTable";

export default function AccountsPage() {
  return (
    <CPage
      title="Accounts"
      primaryAction={{ title: "Create account", url: "./create" }}
      // secondaryActions={[{ title: "Link addresses to account", url: "./link-addresses" }]}
    >
      <AccountTable />
      <Outlet />
    </CPage>
  );
}
