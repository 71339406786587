import React from "react";
import { ComboBox } from "@carbon/react";
import { User, UserPage } from "../../../../lib/api/types";
import { ParseName } from "../../../../lib/api/name";

interface UserDropdownProps {
  users: UserPage;
  formikProps: {
    values: {
      selectedUser: string;
      user: User;
    };
    setFieldValue: (field: string, value: any) => void;
  };
}

export const UserDropdown: React.FC<UserDropdownProps> = ({ users, formikProps }) => {
  const userList = users?.users ?? [];

  const handleChange = (
    selectedItem:
      | { id: string | undefined; label: string | undefined; userObject?: User }
      | null
      | undefined,
  ) => {
    formikProps.setFieldValue("selectedUser", selectedItem?.id ?? "");
    formikProps.setFieldValue("user", selectedItem?.userObject ?? null);
  };

  const selectedItem = userList.find((user) => user.name === formikProps.values.selectedUser);

  return (
    <ComboBox
      id="userSelect"
      style={{ width: "500px" }}
      items={userList.map((user) => ({
        id: user.name,
        label: ParseName([user.name || ""], "User").resourceId,
        userObject: user, // Attaching user object directly to avoid redundant searches
      }))}
      onChange={({ selectedItem }) => handleChange(selectedItem)}
      selectedItem={
        selectedItem
          ? {
              id: selectedItem.name,
              label: selectedItem.name,
              userObject: selectedItem,
            }
          : null
      }
      placeholder="Select a user to invite"
    />
  );
};
