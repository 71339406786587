interface ExplorerUrls {
  tx?: string;
  address?: string;
  token?: string;
}
interface Chain {
  name?: string;
  explorer_urls?: ExplorerUrls;
}

export function SetChains(newChains: Chain[]) {
  // const chains = newChains as any;
  // localStorage.setItem("chains", JSON.stringify(chains));
  chains.chains = newChains as any;
}
export function GetChains(): Chain[] {
  // const chainsMaybe = localStorage.getItem("chains");
  // if (chainsMaybe == null) {
  //   // chains should be set from the connector /chains api endpoint
  //   console.warn("no chain metadata has been set");
  //   return chains.chains;
  // }
  // return JSON.parse(chainsMaybe);
  return chains.chains;
}

export function substitute(template: string, value: string): string {
  if (template.indexOf("{:base64}") != -1) {
    value = btoa(value);
    return template.replace("{:base64}", value);
  }
  return template.replace("{}", value);
}

export function GetChainInfo(chainNameOrId: string): Chain | undefined {
  if (!chainNameOrId.startsWith("chains/")) {
    chainNameOrId = `chains/${chainNameOrId}`;
  }
  for (let i = 0; i < chains.chains.length; i++) {
    if (chains.chains[i].name == chainNameOrId) {
      return chains.chains[i];
    }
  }
  return undefined;
}

// Copied from https://connector.cordialapis.com/v1/chains
const chains = {
  chains: [
    {
      name: "chains/_UNKNOWN",
      explorer_urls: undefined,
    },
    {
      name: "chains/ACA",
      network: "mainnet",
      symbol: "ACA",
      description: "Acala",
      chain_id: "787",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://blockscout.acala.network/tx/{}",
        address: "https://blockscout.acala.network/address/{}",
        token: "https://blockscout.acala.network/address/{}",
      },
    },
    {
      name: "chains/APTOS",
      network: "mainnet",
      symbol: "APTOS",
      description: "Aptos",
      chain_id: "1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://aptoscan.com/transaction/{}",
        address: "https://aptoscan.com/account/{}",
        token: "https://aptoscan.com/coin/{}",
      },
    },
    {
      name: "chains/ATOM",
      network: "mainnet",
      symbol: "ATOM",
      description: "Cosmos",
      chain_id: "cosmoshub-4",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://www.mintscan.io/cosmos/tx/{}",
        address: "https://www.mintscan.io/cosmos/address/{}",
        token: "https://www.mintscan.io/cosmos/assets/{:base64}",
      },
    },
    {
      name: "chains/AVAX",
      network: "mainnet",
      symbol: "AVAX",
      description: "Avalanche C-Chain",
      chain_id: "43114",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://snowtrace.io/tx/{}",
        address: "https://snowtrace.io/address/{}",
        token: "https://snowtrace.io/token/{}",
      },
    },
    {
      name: "chains/ArbETH",
      network: "mainnet",
      symbol: "ArbETH",
      description: "Arbitrum",
      chain_id: "42161",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://arbiscan.io/tx/{}",
        address: "https://arbiscan.io/address/{}",
        token: "https://arbiscan.io/token/{}",
      },
    },
    {
      name: "chains/AurETH",
      network: "mainnet",
      symbol: "AurETH",
      description: "Aurora",
      chain_id: "1313161554",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.mainnet.aurora.dev/tx/{}",
        address: "https://explorer.mainnet.aurora.dev/address/{}",
        token: "https://explorer.mainnet.aurora.dev/token/{}",
      },
    },
    {
      name: "chains/BCH",
      network: "mainnet",
      symbol: "BCH",
      description: "Bitcoin Cash",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://blockchair.com/bitcoin-cash/transaction/{}",
        address: "https://blockchair.com/bitcoin-cash/address/{}",
      },
    },
    {
      name: "chains/BNB",
      network: "mainnet",
      symbol: "BNB",
      description: "Binance Smart Chain",
      chain_id: "56",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://bscscan.com/tx/{}",
        address: "https://bscscan.com/address/{}",
        token: "https://bscscan.com/token/{}",
      },
    },
    {
      name: "chains/BTC",
      network: "mainnet",
      symbol: "BTC",
      description: "Bitcoin",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://mempool.space/tx/{}",
        address: "https://mempool.space/address/{}",
      },
    },
    {
      name: "chains/CELO",
      network: "mainnet",
      symbol: "CELO",
      description: "Celo",
      chain_id: "42220",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://celoscan.io/tx/{}",
        address: "https://celoscan.io/address/{}",
        token: "https://celoscan.io/token/{}",
      },
    },
    {
      name: "chains/CHZ2",
      network: "mainnet",
      symbol: "CHZ2",
      description: "Chiliz 2.0",
      chain_id: "88888",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://chiliscan.com/tx/{}",
        address: "https://chiliscan.com/address/{}",
        token: "https://chiliscan.com/token/{}",
      },
    },
    {
      name: "chains/DOGE",
      network: "mainnet",
      symbol: "DOGE",
      description: "Dogecoin",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://blockchair.com/dogecoin/transaction/{}",
        address: "https://blockchair.com/dogecoin/address/{}",
      },
    },
    {
      name: "chains/ETC",
      network: "mainnet",
      symbol: "ETC",
      description: "Ethereum Classic",
      chain_id: "61",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://etc.blockscout.com/tx/{}",
        address: "https://etc.blockscout.com/address/{}",
        token: "https://etc.blockscout.com/token/{}",
      },
    },
    {
      name: "chains/ETH",
      network: "mainnet",
      symbol: "ETH",
      description: "Ethereum",
      chain_id: "1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://etherscan.com/tx/{}",
        address: "https://etherscan.com/address/{}",
        token: "https://etherscan.com/token/{}",
      },
    },
    {
      name: "chains/ETHW",
      network: "mainnet",
      symbol: "ETHW",
      description: "EthereumPOW",
      chain_id: "10001",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://www.oklink.com/ethw/tx/{}",
        address: "https://www.oklink.com/ethw/address/{}",
        token: "https://www.oklink.com/ethw/token/{}",
      },
    },
    {
      name: "chains/EmROSE",
      network: "mainnet",
      symbol: "EmROSE",
      description: "Oasis",
      chain_id: "42262",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.oasis.io/mainnet/emerald/tx/{}",
        address: "https://explorer.oasis.io/mainnet/emerald/address/{}",
        token: "https://explorer.oasis.io/mainnet/emerald/token/{}",
      },
    },
    {
      name: "chains/FTM",
      network: "mainnet",
      symbol: "FTM",
      description: "Fantom",
      chain_id: "250",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://ftmscan.com/tx/{}",
        address: "https://ftmscan.com/address/{}",
        token: "https://ftmscan.com/token/{}",
      },
    },
    {
      name: "chains/HASH",
      network: "mainnet",
      symbol: "HASH",
      description: "Provenance",
      chain_id: "pio-mainnet-1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.provenance.io/tx/{}",
        address: "https://explorer.provenance.io/accounts/{}",
      },
    },
    {
      name: "chains/INJ",
      network: "mainnet",
      symbol: "INJ",
      description: "Injective",
      chain_id: "injective-1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.injective.network/transaction/{}",
        address: "https://explorer.injective.network/account/{}",
        token: "https://explorer.injective.network/asset/?denom={}",
      },
    },
    {
      name: "chains/KAR",
      network: "mainnet",
      symbol: "KAR",
      description: "Karura",
      chain_id: "686",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://blockscout.karura.network/tx/{}",
        address: "https://blockscout.karura.network/address/{}",
        token: "https://blockscout.karura.network/address/{}",
      },
    },
    {
      name: "chains/KLAY",
      network: "mainnet",
      symbol: "KLAY",
      description: "Klaytn",
      chain_id: "8217",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://klaytnscope.com/tx/{}",
        address: "https://klaytnscope.com/account/{}",
        token: "https://klaytnscope.com/token/{}",
      },
    },
    {
      name: "chains/LTC",
      network: "mainnet",
      symbol: "LTC",
      description: "Litecoin",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://blockchair.com/litecoin/transaction/{}",
        address: "https://blockchair.com/litecoin/address/{}",
      },
    },
    {
      name: "chains/LUNA",
      network: "mainnet",
      symbol: "LUNA",
      description: "Terra",
      chain_id: "phoenix-1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://finder.terra.money/mainnet/tx/{}",
        address: "https://finder.terra.money/mainnet/address/{}",
        token: "https://finder.terra.money/mainnet/address/{}",
      },
    },
    {
      name: "chains/LUNC",
      network: "mainnet",
      symbol: "LUNC",
      description: "Terra Classic",
      chain_id: "columbus-5",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://finder.terra.money/classic/tx/{}",
        address: "https://finder.terra.money/classic/address/{}",
        token: "https://finder.terra.money/classic/address/{}",
      },
    },
    {
      name: "chains/MATIC",
      network: "mainnet",
      symbol: "MATIC",
      description: "Polygon",
      chain_id: "137",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://polygonscan.com/tx/{}",
        address: "https://polygonscan.com/address/{}",
        token: "https://polygonscan.com/token/{}",
      },
    },
    {
      name: "chains/OAS",
      network: "mainnet",
      symbol: "OAS",
      description: "Oasys",
      chain_id: "248",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.oasys.games/tx/{}",
        address: "https://explorer.oasys.games/address/{}",
        token: "https://explorer.oasys.games/token/{}",
      },
    },
    {
      name: "chains/OptETH",
      network: "mainnet",
      symbol: "OptETH",
      description: "Optimism",
      chain_id: "10",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://optimistic.etherscan.io/tx/{}",
        address: "https://optimistic.etherscan.io/address/{}",
        token: "https://optimistic.etherscan.io/token/{}",
      },
    },
    {
      name: "chains/SOL",
      network: "mainnet",
      symbol: "SOL",
      description: "Solana",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://solscan.io/tx/{}",
        address: "https://solscan.io/account/{}",
        token: "https://solscan.io/account/{}",
      },
    },
    {
      name: "chains/SUI",
      network: "mainnet",
      symbol: "SUI",
      description: "Sui",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://suiscan.xyz/mainnet/tx/{}",
        address: "https://suiscan.xyz/mainnet/account/{}",
        token: "https://suiscan.xyz/mainnet/coin/{}",
      },
    },
    {
      name: "chains/TRX",
      network: "mainnet",
      symbol: "TRX",
      description: "Tron",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://tronscan.org/#/transaction/{}",
        address: "https://tronscan.org/#/address/{}",
        token: "https://tronscan.org/#/contract/{}",
      },
    },
    {
      name: "chains/XDC",
      network: "mainnet",
      symbol: "XDC",
      description: "XinFin",
      chain_id: "50",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.xinfin.network/txs/{}",
        address: "https://explorer.xinfin.network/address/{}",
        token: "https://explorer.xinfin.network/tokens/{}",
      },
    },
    {
      name: "chains/XPLA",
      network: "mainnet",
      symbol: "XPLA",
      description: "XPLA Chain",
      chain_id: "dimension_37-1",
      confirmations_final: 6,
      explorer_urls: {
        tx: "https://explorer.xpla.io/mainnet/tx/{}",
        address: "https://explorer.xpla.io/mainnet/address/{}",
        token: "https://explorer.xpla.io/mainnet/token/{}",
      },
    },
  ],
};
