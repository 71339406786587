// import React, { useState } from "react";
// import {
//   DataTable,
//   Table,
//   TableBody,
//   TableCell,
//   TableHead,
//   TableHeader,
//   TableRow,
//   TableToolbar,
//   TableToolbarSearch,
// } from "@carbon/react";
// import { ParseName } from "../../../lib/api/name";
// import { RolePage } from "../../../lib/api/types";

// interface RoleTableProps {
//   rolePage: RolePage;
// }

// const RoleTable: React.FC<RoleTableProps> = ({ rolePage }) => {
//   const [searchQuery, setSearchQuery] = useState("");

//   const headers = [{ key: "name", header: "Name" }];

//   const filteredRows = rolePage.roles
//     ? rolePage.roles
//         .map((role, index) => ({
//           id: index.toString(),
//           name: role.name,
//           state: role.state,
//         }))
//         .filter((row) => {
//           const searchableText = `${row.name} ${row.state}`;
//           return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
//         })
//     : [];

//   const renderCellContent = (cell: any) => {
//     switch (cell.info.header) {
//       case "name":
//         return <div>{ParseName([cell.value], "Role").resourceId || "NA"}</div>;
//       default:
//         return <div>{cell.value}</div>;
//     }
//   };

//   return (
//     <>
//       <TableToolbar>
//         <TableToolbarSearch
//           value={searchQuery}
//           // @ts-expect-error error
//           onChange={(e) => setSearchQuery(e.target.value)}
//           expanded
//           placeholder="Search"
//         />
//       </TableToolbar>
//       <DataTable
//         rows={filteredRows}
//         headers={headers}
//         isSortable
//         render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
//           <Table {...getTableProps()}>
//             <TableHead>
//               <TableRow>
//                 {headers.map((header) => (
//                   //@ts-expect-error  error
//                   <TableHeader key={header.key} {...getHeaderProps({ header })}>
//                     {header.header}
//                   </TableHeader>
//                 ))}
//               </TableRow>
//             </TableHead>
//             <TableBody>
//               {rows.map((row) => (
//                 //@ts-expect-error  error
//                 <TableRow key={row.id} {...getRowProps({ row })}>
//                   {row.cells.map((cell) => (
//                     <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
//                   ))}
//                 </TableRow>
//               ))}
//             </TableBody>
//           </Table>
//         )}
//       />
//     </>
//   );
// };

// export default RoleTable;

import React, { useState } from "react";
import {
  DataTable,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";
import { ParseName } from "../../../lib/api/name";
import { RolePage } from "../../../lib/api/types";

interface RoleTableProps {
  rolePage: RolePage;
}

const RoleTable: React.FC<RoleTableProps> = ({ rolePage }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const headers = [{ key: "name", header: "Name" }];

  // Filter and map roles, then paginate the rows
  const filteredRows = rolePage.roles
    ? rolePage.roles
        .map((role, index) => ({
          id: index.toString(),
          name: role.name,
          state: role.state,
        }))
        .filter((row) => {
          const searchableText = `${row.name} ${row.state}`;
          return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
        })
    : [];

  // Paginate the filtered rows
  const paginatedRows = filteredRows.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  const renderCellContent = (cell: any) => {
    switch (cell.info.header) {
      case "name":
        return <div>{ParseName([cell.value], "Role").resourceId || "NA"}</div>;
      default:
        return <div>{cell.value}</div>;
    }
  };

  // Handle page and page size changes
  const handlePageChange = ({
    page,
    pageSize: newPageSize,
  }: {
    page: number;
    pageSize: number;
  }) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
  };

  return (
    <>
      <TableToolbar>
        <TableToolbarSearch
          value={searchQuery}
          // @ts-expect-error error
          onChange={(e) => setSearchQuery(e.target.value)}
          expanded
          placeholder="Search"
        />
      </TableToolbar>
      <DataTable
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  //@ts-expect-error  error
                  <TableHeader key={header.key} {...getHeaderProps({ header })}>
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                //@ts-expect-error  error
                <TableRow key={row.id} {...getRowProps({ row })}>
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 20, 30, 40, 50]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={currentPage}
      />
    </>
  );
};

export default RoleTable;
