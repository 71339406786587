import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import {
  Content,
  Header,
  HeaderContainer,
  HeaderName,
  HeaderNavigation,
  HeaderMenuButton,
  HeaderMenuItem,
  HeaderGlobalBar,
  HeaderGlobalAction,
  SkipToContent,
  SideNav,
  SideNavItems,
  SideNavLink,
  InlineNotification,
} from "@carbon/react";
import { Moon, Sun } from "@carbon/react/icons";
import UserMenu from "../UserMenu";
import { useUser } from "@clerk/clerk-react";

function CHeaderMenuItem({
  side,
  to,
  children,
}: {
  side: boolean;
  to: string;
  children: React.ReactNode;
}) {
  const Component = side ? SideNavLink : HeaderMenuItem;
  return (
    <Component
      as={NavLink}
      to={to}
      onClick={(e: { currentTarget: { blur: () => void } }) => {
        e.currentTarget.blur();
      }}
    >
      {children}
    </Component>
  );
}

function Menu({ side }: { side: boolean }) {
  return (
    <>
      <CHeaderMenuItem side={side} to="/transfers">
        <span style={{ fontSize: "16px" }}>Transfers</span>
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/approvals">
        Approvals
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/policy">
        Policy
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/accounts">
        Accounts
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/addresses">
        Addresses
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/assets">
        Assets
      </CHeaderMenuItem>
      <CHeaderMenuItem side={side} to="/users">
        Users
      </CHeaderMenuItem>
    </>
  );
}

function CHeader() {
  const ariaLabel = "Cordial Treasury";
  const darkMode = useDarkMode(false, {
    classNameDark: "cds--g90",
    classNameLight: "cds--g10",
  });

  const { isSignedIn } = useUser();

  return (
    <HeaderContainer
      render={({ isSideNavExpanded, onClickSideNavExpand }) => (
        <Header aria-label={ariaLabel}>
          <SkipToContent />
          <HeaderMenuButton
            aria-label={isSideNavExpanded ? "Close menu" : "Open menu"}
            onClick={onClickSideNavExpand}
            isActive={isSideNavExpanded}
          />
          <HeaderName as={Link} to="/" prefix="">
            <img
              src={darkMode.value ? "/cordial_dark.svg" : "/cordial.svg"}
              width="36"
              alt={ariaLabel}
            />
          </HeaderName>
          <HeaderNavigation aria-label={ariaLabel}>
            <Menu side={false} />
          </HeaderNavigation>

          <HeaderGlobalBar>
            {isSignedIn && (
              <div style={{ display: "flex", alignItems: "center", paddingTop: "10px" }}>
                <HeaderGlobalAction aria-label="">
                  <UserMenu />
                </HeaderGlobalAction>
              </div>
            )}

            {darkMode.value ? (
              <HeaderGlobalAction
                aria-label="Light mode"
                tooltipAlignment="end"
                onClick={darkMode.disable}
              >
                <Sun size={20} />
              </HeaderGlobalAction>
            ) : (
              <HeaderGlobalAction
                aria-label="Dark mode"
                tooltipAlignment="end"
                onClick={darkMode.enable}
              >
                <Moon size={20} />
              </HeaderGlobalAction>
            )}
          </HeaderGlobalBar>
          <SideNav
            aria-label="Side navigation"
            isPersistent={false}
            expanded={isSideNavExpanded}
            onOverlayClick={onClickSideNavExpand}
            onSideNavBlur={onClickSideNavExpand}
          >
            <SideNavItems>
              <Menu side />
            </SideNavItems>
          </SideNav>
        </Header>
      )}
    />
  );
}

export default function CLayout({ error = "" }) {
  return error ? (
    <>
      <br />
      <InlineNotification style={{ margin: "0 auto" }} hideCloseButton subtitle={error} title="" />
    </>
  ) : (
    <>
      <CHeader />
      <Content style={{ paddingLeft: 0, paddingRight: 0 }}>
        <Outlet />
      </Content>
    </>
  );
}
