import React from "react";
import { Field, Formik, FormikHelpers, FieldProps } from "formik";
import { ModalSubmitButton, Form, TextInput, MultiColumnRow } from "../../../components/form";
import { useModalSetters } from "../../../components/CModal";
import { useGenerateAccountMutation, AccountFormData } from "../api/createAccount";
import { Select, SelectItem } from "@carbon/react";

const initialValues: AccountFormData = {
  name: "",
  description: "",
  variant: "internal",
};

const validate = (values: AccountFormData) => {
  const errors: Partial<AccountFormData> = {};
  if (!values.name) {
    errors.name = "Required";
  }
  return errors;
};

const TransferCreateForm: React.FC = () => {
  const { mutate } = useGenerateAccountMutation();
  const { setLoading, setNotif } = useModalSetters();

  const onSubmit = async (
    values: AccountFormData,
    { setSubmitting }: FormikHelpers<AccountFormData>,
  ) => {
    setLoading(true);

    try {
      await mutate(values, {
        onError: (err: any) => {
          setNotif({ success: "", error: `Error: ${err.message}` });
        },
        onSuccess: () => {
          setNotif({ success: "Account successfully created!", error: "" });
        },
      });
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Formik initialValues={initialValues} validate={validate} validateOnMount onSubmit={onSubmit}>
      <Form style={{ minHeight: 500 }}>
        <MultiColumnRow>
          <Field className="cds--form-item" labelText="Account Name" name="name" as={TextInput} />
        </MultiColumnRow>
        <MultiColumnRow>
          <Field
            className="cds--form-item"
            labelText="Description"
            name="description"
            as={TextInput}
          />
        </MultiColumnRow>
        <MultiColumnRow>
          <Field name="variant">
            {({ field }: FieldProps) => (
              <Select id="variant" labelText="Variant" {...field}>
                <SelectItem value="internal" text="Internal" />
                <SelectItem value="external" text="External" />
              </Select>
            )}
          </Field>
        </MultiColumnRow>
        <ModalSubmitButton />
      </Form>
    </Formik>
  );
};

export default TransferCreateForm;
