import { useQuery } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { OperationPage } from "../../../lib/api/types";

async function fetchApprovals(pageSize: number): Promise<OperationPage> {
  return httpClientInstance.listOperation({ page_size: pageSize, filter: `state="authorizing"` });
}

export function useApprovals(pageSize: number = 100) {
  const queryKey = ["approvals", pageSize];

  const { data, error, isLoading } = useQuery<OperationPage, Error>({
    queryKey,
    queryFn: () => fetchApprovals(pageSize),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}
