import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { useQueryClient } from "@tanstack/react-query";

export interface DeleteAddressFormData {
  name: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: DeleteAddressFormData): Promise<void> {
  await httpClientInstance.deleteAddress([formData.name]);
}

export function useDeleteAddress(): UseMutationResult<void, SubmitError, DeleteAddressFormData> {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<void, SubmitError, DeleteAddressFormData> = {
    mutationFn: submitFormData,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["address"] });
      console.log("Address deleted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error deleting Address:", error.message);
    },
  };

  return useMutation(mutationOptions);
}
