import React from "react";
import { SignIn } from "@clerk/clerk-react";
import { QUERY_KEY_REDIRECT_TO } from "lib/constants";

const SignInPage: React.FC = () => {
  const params = new URLSearchParams(window.location.search);
  let redirect_to = params.get(QUERY_KEY_REDIRECT_TO);
  if (!redirect_to) {
    redirect_to = "/";
  }
  console.log("SHOULD REDIRECT TO", redirect_to);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <SignIn signUpFallbackRedirectUrl={redirect_to} />
    </div>
  );
};

export default SignInPage;
