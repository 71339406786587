import React from "react";

import OrgTreasuryUserSelector from "./OrgTreasuryUserSelector";

export default function OrgTreasuryUserSelectorModal() {
  return (
    <OrgTreasuryUserSelector
      handleToggleChange={function (): void {
        throw new Error("Function not implemented.");
      }}
    />
  );
}
