import React from "react";
import CModal from "../../../components/CModal";
import RegisterUserForm from "./form/RegisterUserForm";

const UserCreateModal: React.FC = () => {
  return (
    <CModal title="Add User" returnUrl="/users">
      <RegisterUserForm />
    </CModal>
  );
};

export default UserCreateModal;
