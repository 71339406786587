import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { TransactionPage } from "../../../lib/api/types";
import { Name } from "../../../lib/api/name";

async function fetchTransferTransactions(
  name: Name,
  pageSize: number,
  pageToken?: string,
  filter?: string,
): Promise<TransactionPage> {
  return httpClientInstance.listTransferTransactions(
    {
      page_size: pageSize,
      page_token: pageToken,
      filter,
    },
    name,
  );
}

interface UseTransferTransactionsOptions {
  name: Name;
  pageSize?: number;
  pageToken?: string;
  filter?: string;
}

export function useTransferTransactions({
  name,
  pageSize = 100,
  pageToken,
  filter,
}: UseTransferTransactionsOptions) {
  const queryKey = useMemo(
    () => ["transferTransactionPage", pageSize, pageToken, filter],
    [pageSize, pageToken, filter],
  );

  const { data, error, isLoading } = useQuery<TransactionPage, Error>({
    queryKey,
    queryFn: () => fetchTransferTransactions(name, pageSize, pageToken, filter),
    refetchInterval: 10000,
  });

  return { data, isLoading, error };
}
