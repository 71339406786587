import { ParseName } from "../../lib/api/name";
import { getAddressUrl } from "../../utils/BlockchainExplorerManager";
import { Autoscaling, Copy } from "@carbon/react/icons";
import React from "react";

interface TruncateTextPropWithToolTipProps {
  text: string;
  maxLength: number;
  isBlockChainExplorerEnabled?: boolean;
}

const TruncateTextPropWithToolTip: React.FC<TruncateTextPropWithToolTipProps> = ({
  text,
  maxLength,
  isBlockChainExplorerEnabled = false,
}) => {
  const isTextTruncated = text.length > maxLength;

  const truncateString = (str: string, num: number): string => {
    const uri = ParseName([str], "Address").resourceId;

    return isTextTruncated ? uri.slice(0, num) + "..." : uri;
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(ParseName([text], "Address").resourceId)
      .then(() => console.log("Text copied to clipboard"))
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  const navigateToBlockChainExplorer = () => {
    const uri = ParseName([text], "Address").resourceId;
    const chain = ParseName([text], "Address").parentId;
    const url = getAddressUrl(chain || "", uri);

    window.open(url, "_blank", "noopener,noreferrer");
  };

  // Style object for the text span
  const textStyle: React.CSSProperties = {
    display: "inline-block",
    maxWidth: "220px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div style={{ display: "flex", alignItems: "left", gap: "10px" }}>
      <span title={text} style={textStyle}>
        {truncateString(text, maxLength)}
      </span>
      {isTextTruncated && (
        <span
          onClick={handleCopyToClipboard}
          onKeyDown={handleCopyToClipboard}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          title="Click to copy"
        >
          <Copy />
        </span>
      )}
      {isBlockChainExplorerEnabled && (
        <span
          onClick={navigateToBlockChainExplorer}
          onKeyDown={navigateToBlockChainExplorer}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
          title="Navigate to explorer"
        >
          <Autoscaling />
        </span>
      )}
    </div>
  );
};

export default TruncateTextPropWithToolTip;
