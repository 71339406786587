import { useMutation } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Transfer } from "../../../lib/api/types";

async function cancelTransfer(id: string): Promise<Transfer> {
  console.log("Transfer cancelled called");
  const result = await httpClientInstance.cancelTransfer(id);
  if (result.success()) {
    return result.getResource();
  } else {
    throw new Error("Cancel transfer failed");
  }
}

export function useCancelTransfer() {
  const mutation = useMutation<Transfer, Error, string>({
    mutationFn: cancelTransfer,
    onSuccess: () => {
      console.log("Transfer cancelled");
    },
  });

  return mutation;
}
