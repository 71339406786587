import React from "react";
import { ParseName } from "../../../../lib/api/name";

interface SelectItem {
  label: string;
  value: string;
}

interface PreviewSummaryProps {
  values: {
    policyDescription: string;
    permissionType: string;
    numberOfApprovers: number;
  };
  accountAndAddressModeFrom: string;
  selectedAccountsFrom: SelectItem[];
  selectedAddressesFrom: SelectItem[];
  accountAndAddressModeTo: string;
  selectedAccountsTo: SelectItem[];
  selectedAddressesTo: SelectItem[];
  mode: string;
  selectedChains: SelectItem[];
  selectedAssets: SelectItem[];
  viewMode: string;
  selectedGroups: SelectItem[];
  filteredUsers: SelectItem[];
}

const PreviewSummary: React.FC<PreviewSummaryProps> = ({
  values,
  accountAndAddressModeFrom,
  selectedAccountsFrom,
  selectedAddressesFrom,
  accountAndAddressModeTo,
  selectedAccountsTo,
  selectedAddressesTo,
  mode,
  selectedChains,
  selectedAssets,
  viewMode,
  selectedGroups,
  filteredUsers,
}) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "40px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "400px",
      }}
    >
      <div>
        <p style={{ lineHeight: "2.5" }}>
          Your Policy {values.policyDescription} will{" "}
          <span
            style={{
              border: "1px solid #ccc",
              borderRadius: "25px",
              padding: "5px 10px",
              backgroundColor:
                values.permissionType === "allow"
                  ? "#ADD8E6" // Light Blue
                  : values.permissionType === "deny"
                    ? "#FFCCCB" // Light Red
                    : "#FFD580", // Light Orange
              color: "black",
              margin: "0 10px",
            }}
          >
            {values.permissionType}
          </span>{" "}
          transactions from:{" "}
          {accountAndAddressModeFrom === "all" ? (
            <span
              style={{
                border: "1px solid #ccc",
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: "#D3D3D3", // Light Gray
                color: "black",
                margin: "0 10px",
              }}
            >
              all accounts and addresses
            </span>
          ) : accountAndAddressModeFrom === "account" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {selectedAccountsFrom.map((account, index) => (
                <span
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    backgroundColor: "#FFB6C1", // Light Pink
                    color: "black",
                    margin: "10px 0",
                    lineHeight: "1.5", // Adjust line height to match text
                  }}
                >
                  {account.label}
                </span>
              ))}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {selectedAddressesFrom.map((address, index) => (
                <span
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    backgroundColor: "#E6E6FA", // Lavender
                    color: "black",
                    margin: "10px 0",
                    lineHeight: "1.5", // Adjust line height to match text
                  }}
                >
                  {ParseName(address.label, "Address").resourceId}
                </span>
              ))}
            </div>
          )}{" "}
          to:{" "}
          {accountAndAddressModeTo === "all" ? (
            <span
              style={{
                border: "1px solid #ccc",
                borderRadius: "25px",
                padding: "5px 10px",
                backgroundColor: "#D3D3D3", // Light Gray
                color: "black",
                margin: "0 10px",
              }}
            >
              all accounts and addresses
            </span>
          ) : accountAndAddressModeTo === "account" ? (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {selectedAccountsTo.map((account, index) => (
                <span
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    backgroundColor: "#FFB6C1", // Light Pink
                    color: "black",
                    margin: "10px 0",
                    lineHeight: "1.5",
                  }}
                >
                  {account.label}
                </span>
              ))}
            </div>
          ) : (
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {selectedAddressesTo.map((address, index) => (
                <span
                  key={index}
                  style={{
                    border: "1px solid #ccc",
                    borderRadius: "25px",
                    padding: "5px 10px",
                    backgroundColor: "#FFE4B5", // Moccasin
                    color: "black",
                    margin: "10px 0",
                    lineHeight: "1.5",
                  }}
                >
                  {ParseName(address.label, "Address").resourceId}
                </span>
              ))}
            </div>
          )}{" "}
          for{" "}
          <span
            style={{
              border: "1px solid #ccc",
              borderRadius: "25px",
              padding: "5px 10px",
              backgroundColor:
                mode === "all"
                  ? "#D3D3D3" // Light Gray
                  : mode === "chain"
                    ? "#E6E6FA" // Lavender
                    : "#FFE4B5", // Moccasin
              color: "black",
              margin: "0 10px",
            }}
          >
            {mode === "all"
              ? "all chains and assets"
              : mode === "chain"
                ? selectedChains.map((chain) => chain.label).join(", ")
                : selectedAssets
                    .map((asset) => (asset.value.startsWith("chains/") ? asset.label : asset.value))
                    .join(", ")}
          </span>{" "}
          for{" "}
          <span
            style={{
              border: "1px solid #ccc",
              borderRadius: "25px",
              padding: "5px 10px",
              backgroundColor:
                viewMode === "none" || viewMode === "all"
                  ? "#D3D3D3" // Light Gray
                  : viewMode === "group"
                    ? "#FAFAD2" // Light Goldenrod Yellow
                    : "#DCDCDC", // Gainsboro
              color: "black",
              margin: "0 10px",
            }}
          >
            {viewMode === "none"
              ? "no users or groups"
              : viewMode === "group"
                ? selectedGroups.map((group) => group.label).join(", ")
                : filteredUsers.map((user) => user.label).join(", ")}
          </span>
          {values.numberOfApprovers > 0 && (
            <>
              {" "}
              with{" "}
              <span
                style={{
                  border: "1px solid #ccc",
                  borderRadius: "25px",
                  padding: "5px 10px",
                  backgroundColor: "#FFFACD", // Lemon Chiffon
                  color: "black",
                  margin: "0 10px",
                }}
              >
                {values.numberOfApprovers}
              </span>{" "}
              approver
              {values.numberOfApprovers > 1 ? "s" : ""}.
            </>
          )}
        </p>
      </div>
    </div>
  );
};

export default PreviewSummary;
