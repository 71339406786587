import { sha256 } from "@noble/hashes/sha256";
import { base64, hex } from "@scure/base";

class ContentDigest {
  body: string;
  digest: Uint8Array;
  constructor(body?: string) {
    this.body = body || "";
    this.digest = sha256(body || "");
  }

  asBase64(): string {
    const digestB64 = base64.encode(this.digest);
    return digestB64;
  }

  // Return the value that should be in the header
  Header(): [string, string] {
    return ["content-digest", `sha-256=:${this.asBase64()}:`];
  }
}

interface sigParamsTemplateArgs {
  created: number;
  keyId: string;
  nonce: number;
  algorithm: string;
  approve?: string;
  cancel?: string;
}
// Signature input header should look like:
// signature-input: iam=("@authority" "@method" "@path" "@query" "content-digest");created=1705522969;keyid="admin-2treasury";nonce="2477052534660";tag=""
class SigParams {
  args: sigParamsTemplateArgs;
  constructor(args: sigParamsTemplateArgs) {
    this.args = args;
  }

  Serialize(): string {
    const args = this.args;
    const created = args.created | 0;
    // const treasuryId = args.treasuryId || "";
    let tag = ``;
    if (args.approve) {
      tag = `approve:${args.approve}`;
    } else if (args.cancel) {
      tag = `cancel:${args.cancel}`;
    }

    const params = `alg="${args.algorithm}";created=${created};keyid="${args.keyId}";nonce="${args.nonce}";tag="${tag}"`;
    const template = `("@method" "@path" "@query" "content-digest" "treasury");${params}`;
    return template;
  }

  // Return the value that should be in the header
  Header(): [string, string] {
    return ["signature-input", `iam=${this.Serialize()}`];
  }
}

interface sigBaseTemplateArgs {
  // scheme: string;
  // authority: string;
  method: string;
  path: string;
  query: string;
  contentDigest: string;
  params: SigParams;
  // instance Id, may leave blank for now
  treasuryId: string;
}

class SignatureBase {
  args: sigBaseTemplateArgs;
  constructor(args: sigBaseTemplateArgs) {
    this.args = args;
  }

  template(): string {
    const args = this.args;
    let query = args.query;
    if (query.indexOf("?") != 0) {
      query = "?" + query;
    }
    let path = args.path;
    if (path.indexOf("/") != 0) {
      // should have leading slash
      path = "/" + path;
    }

    const template = `"@method": ${args.method}
"@path": ${path}
"@query": ${query}
content-digest: sha-256=:${args.contentDigest}:
treasury: ${args.treasuryId}
"@signature-params": ${args.params.Serialize()}
`;
    return template;
  }

  asUint8Array(): Uint8Array {
    const enc = new TextEncoder();
    const buffer = enc.encode(this.template());
    return buffer;
  }
  asHex(): string {
    return hex.encode(this.asUint8Array());
  }
}

// Signature header should look like:
// signature: iam=:9jHRS7kaLi3m/CaqWNo/ptptD5dVmghS7/2a2T1iZdFCSdj648iyXg4V40yNAjF4Cr9kzEk/1Sp8f8JgvZXnAA==:
class Signature {
  signature: Uint8Array;
  constructor(signature: Uint8Array) {
    this.signature = signature;
  }
  asBase64(): string {
    const digestB64 = base64.encode(this.signature);
    return digestB64;
  }
  Header(): [string, string] {
    return ["signature", `iam=:${this.asBase64()}:`];
  }
}

class TreasuryHeader {
  treasuryId: string;
  constructor(treasuryId: string) {
    this.treasuryId = treasuryId;
  }

  Header(): [string, string] {
    return ["treasury", this.treasuryId];
  }
}

interface Header {
  Header(): [string, string];
}

function SerializeHeaders(headers: Header[]): Record<string, string> {
  const map: Record<string, string> = {};
  for (const i in headers) {
    const header = headers[i].Header();
    map[header[0]] = header[1];
  }
  return map;
}

export { ContentDigest, SigParams, Signature, SignatureBase, TreasuryHeader, SerializeHeaders };
