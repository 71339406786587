import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";

import { AssetPage } from "../../../lib/api/types";

export const fetchAssetsPage = (pageSize: number): Promise<AssetPage> => {
  return httpClientInstance.listAssets({ page_size: pageSize });
};

type UseAssetsPageOptions = {
  pageSize: number;
};

export const useGetAssetPage = ({ pageSize }: UseAssetsPageOptions) => {
  const queryKey = useMemo(() => ["AssetsPage", pageSize], [pageSize]);

  const { data, error, isError, isLoading } = useQuery({
    queryKey,
    queryFn: () => fetchAssetsPage(pageSize),
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching AssetsPage:", error);
    }
  }, [error]);
  return { data: data || null, error: isError ? error : null, isLoading };
};
