import React from "react";

import CModal from "../../../components/CModal";
import AccountAddAddressForm from "./AccountAddAddressForm";

export default function CreateModal() {
  return (
    <CModal title="Add address" returnUrl="/addresses">
      <AccountAddAddressForm />
    </CModal>
  );
}
