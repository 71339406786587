import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";

import { TransferRulePage } from "../../../lib/api/types";

function fetchTransferRulePage(pageSize: number): Promise<TransferRulePage> {
  return httpClientInstance.listTransferRules({ page_size: pageSize });
}

export function useTransferRulePage(): TransferRulePage | null {
  const queryKey = useMemo(() => ["transferRulePage"], []);

  const { data, error } = useQuery({
    queryKey,
    queryFn: () => fetchTransferRulePage(100),
    refetchInterval: 10000,
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching Accounts:", error);
    }
  }, [error]);

  return data || null;
}
