import React from "react";

import CModal from "../../../components/CModal";
import AssetCreateForm from "./AssetCreateForm";

export default function AssetCreateModal() {
  return (
    <CModal title="Add asset" returnUrl="/assets">
      <AssetCreateForm />
    </CModal>
  );
}
