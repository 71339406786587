import { User } from "@/lib/api/types";
import { httpClientInstance } from "../../../clientInstance";

import { useMutation, UseMutationResult } from "@tanstack/react-query";

export interface UpdateUserFormData {
  user: User;
  clerkUser: string;
}

interface SubmitError {
  message: string;
}

async function submitFormData(data: UpdateUserFormData): Promise<void> {
  await httpClientInstance.updateUser(data.user.name ?? "", {
    ...data.user,
    labels: { "sso-user": data.clerkUser },
  });
}

export function useUpdateUserMutation(): UseMutationResult<void, SubmitError, UpdateUserFormData> {
  return useMutation<void, SubmitError, UpdateUserFormData>({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("User form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting user form:", error.message);
    },
  });
}
