import { ed25519 } from "@noble/curves/ed25519";
import { randomBytes } from "@noble/hashes/utils";
import { hex } from "@scure/base";

export interface InviteCode {
  // what gets shared with people
  inviteCode: string;
  // what gets submitted to treasury
  publicKey: string;
}

export const GenerateUserInviteCode = (): InviteCode => {
  const random = randomBytes(32);
  const publicKey = ed25519.getPublicKey(random);
  return {
    inviteCode: hex.encode(random),
    publicKey: hex.encode(publicKey),
  };
};
