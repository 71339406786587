import React from "react";

interface TruncateTextProps {
  text: string;
  maxLength: number;
}

const TruncateText: React.FC<TruncateTextProps> = ({ text, maxLength }) => {
  const truncateString = (str: string, num: number): string => {
    return str.length > num ? str.slice(0, num) + "..." : str;
  };

  // Style object for the text span
  const textStyle: React.CSSProperties = {
    display: "inline-block",
    width: "150px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
      <span title={text} style={textStyle}>
        {truncateString(text, maxLength)}
      </span>
    </div>
  );
};

export default TruncateText;
