import React, { ChangeEvent, ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  DataTable,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  TableContainer,
  TableToolbar,
  TableToolbarSearch,
  Pagination,
} from "@carbon/react";
import { AssetPage } from "@/lib/api/types";
import { ParseName } from "../../../lib/api/name";
import { IconCellRenderer } from "../../../components/itemRenderers/IconCellRenderer";

interface AssetTableProps {
  assetdata: AssetPage | null;
}

const AssetTable: React.FC<AssetTableProps> = ({ assetdata }) => {
  console.log("assetdata", assetdata);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortDirection, setSortDirection] = useState<"ASC" | "DESC">("DESC");
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const navigate = useNavigate();

  const headers = [
    {
      key: "chain",
      header: "Chain",
    },
    {
      key: "symbol",
      header: "Asset Symbol",
    },
    { key: "notes", header: "Description" },
    {
      key: "decimals",
      header: "Decimal",
    },
    {
      key: "variant",
      header: "Variant",
    },
  ];

  if (!assetdata || !assetdata.assets) {
    return <div>No asset data available.</div>;
  }

  const extractDescription = (jsonString: string): string | undefined => {
    try {
      const data = JSON.parse(jsonString);
      return data.description;
    } catch (error) {
      console.error("Invalid JSON string provided:", error);
      return undefined;
    }
  };

  const filteredRows = assetdata.assets
    .map((item, index) => ({
      id: `row-${index}`,
      ...item,
      symbol: ParseName([item.name ?? ""], "Asset").resourceId || "NA",
      chain: ParseName([item.name ?? ""], "Asset").parentId || "NA",
      notes:
        item.notes && typeof item.notes === "object" && item.notes.description
          ? item.notes.description
          : extractDescription(item?.notes?.toString() ?? "") || "",
    }))
    .filter((row) => {
      const searchableText = `${row.state} ${row.decimals} ${row.variant} ${row.symbol}`;
      return searchableText.toLowerCase().includes(searchQuery.toLowerCase());
    })
    .sort((a, b) => {
      if (sortDirection === "DESC") {
        return a.symbol.localeCompare(b.symbol);
      } else {
        return b.symbol.localeCompare(a.symbol);
      }
    });

  const handleSort = (columnName: string) => {
    if (columnName === "symbol") {
      setSortDirection((currentDirection) => (currentDirection === "ASC" ? "DESC" : "ASC"));
    }
  };

  const handlePageChange = (event: { page: number; pageSize: number }) => {
    setPage(event.page);
    setPageSize(event.pageSize);
  };

  const handleRowClick = (row: any) => {
    const symbol = row.cells.find((cell: any) => cell.info.header === "symbol")?.value;
    const chain = row.cells.find((cell: any) => cell.info.header === "chain")?.value;
    if (symbol && chain) {
      navigate(`/assets/chain/${chain}/assets/${symbol}`);
    }
  };

  const paginatedRows = filteredRows.slice((page - 1) * pageSize, page * pageSize);

  const renderCellContent = (cell: any): JSX.Element => {
    if (cell.info.header === "chain") {
      return (IconCellRenderer({ value: cell.value }) as ReactElement) || <></>;
    }
    return <span>{cell.value}</span>;
  };

  return (
    <>
      <TableContainer>
        <TableToolbar>
          <TableToolbarSearch
            value={searchQuery}
            //@ts-expect-error error
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchQuery(e.target.value)}
            expanded
            placeholder="Search"
          />
        </TableToolbar>
      </TableContainer>

      <DataTable
        size="md"
        rows={paginatedRows}
        headers={headers}
        isSortable
        render={({ rows, headers, getTableProps, getHeaderProps, getRowProps }) => (
          <Table {...getTableProps()}>
            <TableHead>
              <TableRow>
                {headers.map((header) => (
                  //@ts-expect-error error
                  <TableHeader
                    //@ts-expect-error error
                    key={header.key}
                    {...getHeaderProps({
                      header,
                      isSortable: true,
                      onClick: () => handleSort(header.key),
                    })}
                  >
                    {header.header}
                  </TableHeader>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  //@ts-expect-error error
                  key={row.id}
                  {...getRowProps({ row })}
                  onClick={() => handleRowClick(row)}
                  style={{ cursor: "pointer" }}
                >
                  {row.cells.map((cell) => (
                    <TableCell key={cell.id}>{renderCellContent(cell)}</TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      />
      <Pagination
        totalItems={filteredRows.length}
        backwardText="Previous"
        forwardText="Next"
        pageSize={pageSize}
        pageSizes={[5, 10, 50, 100]}
        itemsPerPageText="Items per page:"
        onChange={handlePageChange}
        page={page}
      />
    </>
  );
};

export default AssetTable;
