import React from "react";
// export const formatDate = (dateString: string) => {
//   return new Date(dateString).toLocaleTimeString("en-US", {
//     // year: "numeric",
//     // month: "long",
//     // day: "numeric",
//     hour: "2-digit",
//     minute: "2-digit",
//   });
// };

export const formatDate = (isoDateString: string): string => {
  if (!isoDateString) {
    return "";
  }
  const date = new Date(isoDateString);
  return `${date.toLocaleDateString("en-US", {
    month: "long",
    day: "numeric",
  })} ${date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  })}`;
};

export const formatElapsedTime = (isoDateString: string): string => {
  if (!isoDateString) {
    return "";
  }

  const now = new Date();
  const date = new Date(isoDateString);
  const elapsedMilliseconds = now.getTime() - date.getTime();

  const seconds = Math.floor(elapsedMilliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  if (years > 0) {
    return years === 1 ? "1 year ago" : `${years} years ago`;
  }
  if (months > 0) {
    return months === 1 ? "1 month ago" : `${months} months ago`;
  }
  if (weeks > 0) {
    return weeks === 1 ? "1 week ago" : `${weeks} weeks ago`;
  }
  if (days > 0) {
    return days === 1 ? "1 day ago" : `${days} days ago`;
  }
  if (hours > 0) {
    return hours === 1 ? "1 hour ago" : `${hours} hours ago`;
  }
  if (minutes > 0) {
    return minutes === 1 ? "1 minute ago" : `${minutes} minutes ago`;
  }
  return seconds === 1 ? "1 second ago" : `${seconds} seconds ago`;
};

export const truncateString = (str: string, num: number): string => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

// export const parseVariant = (jsonString: string) => {
//   const parsedObj = JSON.parse(jsonString);
//   return parsedObj.variant;
// };

export function parseVariant(input: string): string {
  if (!input) return "";
  try {
    const parsed = JSON.parse(input);
    return parsed.variant || "";
  } catch (error) {
    console.error("Failed to parse variant:", error);
    return "NA";
  }
}

export const parseBody = (jsonString: string): JSX.Element => {
  const jsonObject = JSON.parse(jsonString);
  const keyValuePairs: JSX.Element[] = [];

  for (const key in jsonObject) {
    if (Object.prototype.hasOwnProperty.call(jsonObject, key)) {
      keyValuePairs.push(
        <div key={key}>
          <strong>{key}</strong>: {jsonObject[key]}
        </div>,
      );
    }
  }

  return <div>{keyValuePairs}</div>;
};
