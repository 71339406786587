import React from "react";
import CModal from "../../../components/CModal";
import GenerateUserInviteCodeFrom from "./form/GenerateUserInviteCodeForm";

const UserGenerateInviteModal: React.FC = () => {
  return (
    <CModal title="Invite User" returnUrl="/users">
      <GenerateUserInviteCodeFrom />
    </CModal>
  );
};

export default UserGenerateInviteModal;
