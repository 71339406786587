import { useEffect, useState } from "react";

const useCachedState = <T extends string>(
  key: string,
  initialValue: T,
): [T, React.Dispatch<React.SetStateAction<T>>] => {
  const [value, setValue] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? (storedValue as T) : initialValue;
  });

  useEffect(() => {
    if (value === initialValue) {
      localStorage.removeItem(key);
    } else {
      localStorage.setItem(key, value);
    }
  }, [key, value, initialValue]);

  return [value, setValue];
};

export default useCachedState;
