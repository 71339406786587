import { httpClientInstance } from "../../../clientInstance";

export async function registerUser(newUserName: string, roles: string[], ssoUser: string) {
  console.log("Registering user", newUserName, roles, ssoUser);
  console.log("Creating user with roles", ssoUser);
  const response = await httpClientInstance.create("User", [newUserName], {
    roles: roles,
    variant: "human",
    labels: { "sso-user": ssoUser },
  });

  if (!response) {
    throw new Error("Error submitting Register User form");
  }

  return response;
}
