import { UseMutationResult, useMutation, UseMutationOptions } from "@tanstack/react-query";
import { httpClientInstance } from "../../../clientInstance";
import { Address } from "../../../lib/api/types";

export interface UpdateAddressFormData {
  name: string;
  addressData: Address;
}

interface SubmitError {
  message: string;
}

async function submitModifyFormData(formData: UpdateAddressFormData): Promise<void> {
  console.log("form data", formData);
  await httpClientInstance.updateAddress(formData.name, {
    ...formData.addressData,
  });
}

export function useModifyAddressMutation(
  options?: UseMutationOptions<void, SubmitError, UpdateAddressFormData>,
): UseMutationResult<void, SubmitError, UpdateAddressFormData> {
  return useMutation<void, SubmitError, UpdateAddressFormData>({
    mutationFn: submitModifyFormData,
    onSuccess: (data: void, variables: UpdateAddressFormData, context: unknown) => {
      console.log("Update address Form modified successfully");

      if (options?.onSuccess) {
        options.onSuccess(data, variables, context);
      }
    },
    onError: (error: SubmitError, variables: UpdateAddressFormData, context: unknown) => {
      console.error("Error submitting address modified form:", error.message);
      if (options?.onError) {
        options.onError(error, variables, context);
      }
    },
    ...options,
  });
}
