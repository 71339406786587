import React from "react";
import { Field, FieldProps } from "formik";
import { TextInput } from "@carbon/react";

interface PolicyDescriptionInputProps {
  name: string;
}

const PolicyDescriptionInput: React.FC<PolicyDescriptionInputProps> = ({ name }) => {
  return (
    <div style={{ marginBottom: "20px", width: "100%" }}>
      <Field name={name}>
        {({ field }: FieldProps) => (
          <TextInput
            id={name}
            labelText="Please Give a Policy Description"
            {...field}
            style={{ width: "100%" }}
          />
        )}
      </Field>
    </div>
  );
};

export default PolicyDescriptionInput;
