import { ArrowLeft, ArrowRight } from "@carbon/react/icons";
import React from "react";

interface DirectionCellRendererProps {
  direction: "incoming" | "outgoing";
}

export const DirectionCellRenderer: React.FC<DirectionCellRendererProps> = ({ direction }) => {
  const IconComponent = direction === "incoming" ? ArrowLeft : ArrowRight;

  return (
    <div>
      <IconComponent
        title={direction === "incoming" ? "Incoming" : "Outgoing"}
        style={{ fontSize: "22px" }}
      />
    </div>
  );
};
