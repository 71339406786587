//// local storage keys
export const KEY_API = "api";
export const KEY_USER = "user";
export const KEY_SESSION = "session";
export const KEY_ORGANISATION = "organisation";
export const KEY_TREASURY = "treasury";

// export const ALL_KEYS = [KEY_API, KEY_USER];

//// query parameter keys
export const QUERY_KEY_API = "api";
export const QUERY_KEY_USER = "user";
export const QUERY_KEY_REDIRECT_TO = "redirect_to";

//// Get Clerk key depending if clerk is disabled or not.
export const GetClerkPublishableKey = (): string => {
  if (window.CLERK_DEV) {
    return window.CLERK_PUBLISHABLE_KEY;
  }
  return window.CLERK_PUBLISHABLE_KEY;
};
