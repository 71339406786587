import { useCallback } from "react";

import { checkUrl } from "../services/urlChecker";
import useCachedState from "./useCachedState";
import { KEY_API } from "lib/constants";

type UseValidatedApiUrlReturnType = [string, (url: string) => Promise<void>];

const useValidatedApiUrl = (initialValue: string = ""): UseValidatedApiUrlReturnType => {
  const [apiURL, setApiURL] = useCachedState<string>(KEY_API, initialValue);

  const validateAndSetApiUrl = useCallback(
    async (newUrl: string) => {
      const isValid = await checkUrl(newUrl);
      if (isValid) {
        setApiURL(newUrl);
      } else {
        console.error("Provided API URL is invalid or down. Please check the URL and try again.");
      }
    },
    [setApiURL],
  );

  return [apiURL, validateAndSetApiUrl];
};

export default useValidatedApiUrl;
