import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { registerUser } from "../services/registerUser";

interface FormData {
  newUserName: string;
  ssoUser: string;
  roles: string[];
}

interface SubmitError {
  message: string;
}

async function submitFormData(formData: FormData): Promise<void> {
  console.log("Form data submitted:", formData);
  await registerUser(formData.newUserName, formData.roles, formData.ssoUser);
}

function useRegisterUser(): UseMutationResult<void, SubmitError, FormData> {
  return useMutation<void, SubmitError, FormData>({
    mutationFn: submitFormData,
    onSuccess: () => {
      console.log("Form submitted successfully");
    },
    onError: (error: SubmitError) => {
      console.error("Error submitting form:", error.message);
    },
  });
}

export default useRegisterUser;
