import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { httpClientInstance } from "../../../clientInstance";
import { TransferRule } from "../../../lib/api/types";

function fetchTransferRule(addressId: string, pageSize: number): Promise<TransferRule> {
  return httpClientInstance.getTransferRule(addressId, {
    page_size: pageSize,
  });
}

interface UseTransferOptions {
  transferId: string;
  pageSize?: number;
}

export function useTransferRule({ transferId, pageSize = 100 }: UseTransferOptions) {
  const queryKey = useMemo(
    () => ["transferRulePage", transferId, pageSize],
    [transferId, pageSize],
  );

  const { data, error, isLoading, isError } = useQuery<TransferRule, Error>({
    queryKey,
    queryFn: () => fetchTransferRule(transferId, pageSize),
    refetchInterval: 10000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}
