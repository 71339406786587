import { ToastNotification } from "@carbon/react";
import { useNotificationStore } from "../../stores/notifications";

export const Notifications = () => {
  const { notifications, dismissNotification } = useNotificationStore();

  return (
    <div className="notifications-container">
      {notifications.map((notification) => (
        <ToastNotification
          key={notification.id}
          kind={notification.type}
          title={notification.type}
          subtitle={notification.title}
          caption={notification.message}
          onCloseButtonClick={() => dismissNotification(notification.id)}
          className="notification-slide-in"
          style={{ marginBottom: ".5rem" }}
        />
      ))}
    </div>
  );
};
