import { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { TreasuriesData } from "../types/treasuryTypes";

async function fetchTreasuries(): Promise<TreasuriesData> {
  const response = await fetch("https://admin.cordialapis.com/v1/treasuries", {
    credentials: "include",
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
}

export function useTreasuries() {
  const queryKey = useMemo(() => ["treasuries"], []);
  const { data, error, isLoading, isError } = useQuery<TreasuriesData, Error>({
    queryKey,
    queryFn: fetchTreasuries,
    refetchInterval: 2000,
  });

  return {
    data,
    isLoading,
    error,
    isError,
  };
}

// import { useState, useMemo } from "react";
// import { useQuery } from "@tanstack/react-query";
// import { TreasuriesData } from "../types/treasuryTypes";

// async function fetchTreasuries(): Promise<TreasuriesData> {
//   const response = await fetch("https://admin.cordialapis.com/v1/treasuries", {
//     credentials: "include",
//   });
//   if (!response.ok) {
//     throw new Error("Network response was not ok");
//   }
//   return response.json();
// }

// export function useTreasuries() {
//   const [shouldRefetch, setShouldRefetch] = useState(true);

//   const queryKey = useMemo(() => ["treasuries"], []);
//   const { data, error, isLoading, isError } = useQuery<TreasuriesData, Error>({
//     queryKey,
//     queryFn: fetchTreasuries,
//     refetchInterval: shouldRefetch ? 2000 : false, // 2000 milliseconds = 2 seconds
//     onSuccess: () => {
//       setShouldRefetch(false); // Stop refetching once data is successfully fetched
//     },
//     onError: () => {
//       setShouldRefetch(true); // Continue refetching if there's an error
//     },
//   });

//   return {
//     data,
//     isLoading,
//     error,
//     isError,
//   };
// }
